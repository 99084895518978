import { Apps, ExportData } from '../Common/Interface';
import { BE_URL, SelectAppsUrls } from '../Common/Constant';
import { client } from '../Client/Client';
import { AxiosResponse } from 'axios';


export async function getAppsService(token: object): Promise<AxiosResponse | undefined> {
    let appData: Apps;
    try {
        let response: AxiosResponse | undefined = await client({
            method: 'post',
            
            url: BE_URL + SelectAppsUrls.getAppsUrl,
        //   url : 'http://localhost:8080/selectapps//getApps',
            data: token
        });
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getSelectedAppsForAssessmentService(token: object, taskId: number): Promise<Apps | any> {
    let appData: Apps;
    try {
        let response: AxiosResponse | undefined = await client({
            method: 'post',
           //  url: "http://localhost:8080/SelectApps/getSelectedAppsForAssessment",
            url: BE_URL + SelectAppsUrls.getSelectedAppsUrl,
            data: { token, taskId }
        });
        return response;
    } catch (error) {
        throw error;
    }
}

// export async function handleExport(cToken: string, taskId: string, e: any): Promise<ExportData> {
//     let exportData: ExportData;
//     try {
//         let response: Apps = await client({
//             method : 'post',
//             url : BE_URL + SelectAppsUrls.
//         })
//         return response;
//     } catch (error) {
//         throw error;
//     }
// }

export async function customExportAndImportService(data: { token: object, taskId: number, type: string, users?: any }): Promise<Apps | any> {
    try {
        let response: any = await client({
            method: 'post',
             url: BE_URL + SelectAppsUrls.customExportAndImportUrl,
          //  url:'http://localhost:8080/selectapps/customExportANdImport',
            data: data
        });
        return response;
    } catch (error) {
        throw error;
    }
}

// export async function handleImport(cToken: string, taskId: string, e: any, importData: any): Promise<apps> {
//     try {
//         let response: Apps = await this.customExportAndImport(cToken, taskId, 'import', importData);
//         return response;
//     } catch (error) {
//         throw error;
//     }q
// }

export async function startAssessmentService(data: { token: object, taskId: number, apps: any[],environment : string,importData:any}): Promise<Apps | any> {
    let startAssessmentResponse: Apps;
    try {
        let response: any = await client({
            method: 'post',
          url: BE_URL + SelectAppsUrls.startAssessmentUrl,
         // url:'http://localhost:8080/SelectApps/startAssessment',
            data: data
        });
        return response;
    } catch (error) {
        throw error;
    }
}

