// //PS_03 PS_04 PS_05 PS_06 PS_07 PS_08 PS_09 PS_10 PS_11 PS_12 PS_13 PS_14 
// // adminClient.tsx
import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { getAdminClient, handleClient } from '../../Services/manageClientAPI';
import { getClientData } from '../../Services/profileService';
import { ClientData, ErrorMessages, MyState, OptionType, sessionResponse } from '../../Common/Interface';
import objCryptoJS from "crypto-js";
import { decodeSessionDetails } from '../../Services/CommonServices';
import { Country } from 'country-state-city';
import { countries, TCountries } from 'countries-list';
import Select from "react-select"
import Loader from '../Common/Loader';
const accountInfoObj = {
  name: "",
  mail: "",
  company: "",
  registrationType: "",
  phone: "",
  existPWD: "",
  phonecode: "",
};
interface EditClientData {
  userName: string;
  email: string;
  companyName: string;
  // ... other properties
}
// import { Country, State, City } from 'country-state-city';
// import Select from 'react-select';
// import Tippy, { followCursor } from '@tippyjs/react';
// import 'tippy.js/dist/tippy.css';
// import { ClientResponseType, AdminResponseType } from './interface';
// import Loader from './LoaderComponent';
// import CryptoJS from 'crypto-js';
// import { client as clientFunction } from './client';
// import { adminEndpoints as admin, BEURL } from './constants';



const ClientProfile: React.FC = () => {

  //PS_16 PS_17 PS_18 PS_19 PS_20 PS_21 PS_22 PS_23 PS_24 PS_25 PS_26 PS_27 PS_28 PS_29 
  //PS_30 PS_31 PS_32 PS_33 PS_34 PS_35 PS_36 PS_37 	PS38 	PS39 	PS40 

  const history = useHistory();
  const location: any = useLocation()?.state
  const [ctoken, setCToken] = useState<null | string>(null);
  const [clientName, setClientName] = useState("");
  const [mailId, setMailId] = useState("");
  const [role, setRole] = useState("");
  const [tokenExpiration, setTokenExpiration] = useState(new Date());
  const [action, setAction] = useState("");
  let [AccountInfo, setAccountInfoData] = useState(accountInfoObj);
  const [atoken, setAToken] = useState<null | string>(null);
  const [adminName, setAdminName] = useState("");
  const [adminMailId, setAdminMailId] = useState("");
  const [adminRole, setAdminRole] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})
  const [successPopupType, setSuccessPopupType] = useState('')

  let ClientObj = {
    userName: '',
    companyName: '',
    phone: '',
    registrationType: 'SSO',
    email: '',
    authenticationType: 'Tenant',
    status: 'InActive',
    phonecode: "+91"
  }

  let errClientObj: ErrorMessages = {
    errUsername: '',
    errCompanyName: '',
    errPhone: '',
    errRegistrationType: '',
    errEmail: '',
    errAuthenticationType: '',
    status: ''

  };
  let erreditClientObj: ErrorMessages = {
    errUsername: '',
    errCompanyName: '',
    errPhone: '',
    errRegistrationType: '',
    errEmail: '',
    errAuthenticationType: '',
    status: ''

  };


  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';

  const [clientData, setClientData] = useState(ClientObj);
  const [editClientData, setEditClientData] = useState(ClientObj);
  const [erroreditMessage, seterroreditMessage] = useState(erreditClientObj)
  const [basicCountryCodes, setBasicCountryCodes] = useState<string[]>([]);
  const [companyName, setCompanyName] = useState('')
  const [errorMessages, setErrorMessages] = useState<ErrorMessages>(errClientObj);
  const [popupmessage, setpopupmessage] = useState("");
  const [sortValue, setSortValue] = useState("timeStamp");
  const [sortHide, setSortHide] = useState("");
  const [sortType, setSortType] = useState("desc");

  const [Invoice, setInvoice] = useState([]);
  const [auditLogs, setAuditLogs] = useState([]);
  const [invSearchValue, setInvSearchValue] = useState('');
  const [auditSearchValue, setAuditSearchValue] = useState('');
  const [enable, setEnablebutton] = useState(false)
  const [loader, setLoader] = useState(false)
  const [alertPopup, setAlertPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [AccountInfodata, setAccountInfo] = useState(accountInfoObj);
  const [Deletepopup, setDeletePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false)
  const [isEditing, setIsEditing] = useState(false);
  const [displayClientData, setDisplayClientData] = useState<ClientData | null>(null);
  //PS_41 PS_42
  useEffect(() => {
    console.log("the state has been passed", location)
    // Assume resetSessionTimer() and addEventListener() are defined in CommonServices.ts
    // window.addEventListener('event-name', resetSessionTimer);
    // return () => window.removeEventListener('event-name', resetSessionTimer);

    fetchBasicCountryCodes()
    if (window.location.pathname.toString().toLowerCase().includes("admin")) {
      // resetSessionTimer();
      sessionTokenProperties()
      getClient()
      //   return () => {
      // window.removeEventListener('click', resetSessionTimer);
      // window.removeEventListener('keydown', resetSessionTimer);
      // };
    }
    else {
      sessionTokenProperties()
      getClient();

      //   return () => {
      //     window.removeEventListener('click', resetSessionTimer);
      //     window.removeEventListener('keydown', resetSessionTimer);
      // };
    }
  }, []); 
  // Empty dependency array means it only runs once on component mount

// useEffect(()=>{

// },[clientData])
  let clientId = location?.state.id
  console.log("thee state passing ",location?.state);
  

  //CommonFunctionS:
  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: sessionResponse = await decodeSessionDetails();
      debugger
      if (window.location.pathname.toString().toLowerCase().includes("admin")) {
        if (tokenResponse.adminDetails) {
          setObjAdmin(tokenResponse.adminDetails)
          setAdminName(tokenResponse.adminDetails.Name);
          setAdminMailId(tokenResponse.adminDetails.Email);
          setAdminRole(tokenResponse.adminDetails.Role);
          setRole(tokenResponse.adminDetails.Role)
          setAdminMailId(tokenResponse.adminDetails.Email)

        }
        else {
          if (window.location.pathname.toString().toLowerCase().includes("admin")) {
            history.push("/Admin/Login")
          }
        }
      }
      else {
        if (tokenResponse.clientDetails) {
          setObjClient(tokenResponse.clientDetails)
          setClientName(tokenResponse.clientDetails.Name);
          setCompanyName(tokenResponse.clientDetails.CompanyName)
          setRole(tokenResponse.clientDetails.Role);
          setMailId(tokenResponse.clientDetails.Email)
        }
        else {
          history.push("/Login")
        }
      }
      //#endregion
    } catch (error) {
      console.error('Failed in main method', error);
    }
  };
  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    // debugger
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          //console.log(clientToken, 'ddddddddddddddddddddddddddd');
          setCToken(clientToken)
          token = {
            clientToken: clientToken
          }
        }


      }
      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          //console.log(adminToken, 'sssssssssssssssssss');
          setAToken(adminToken)
          token = {
            adminToken: adminToken
          }
        }
      }
      return token
    } catch (error) {
      throw error
    }
  }
  const fetchBasicCountryCodes = async () => {
    const allCountries = await Country.getAllCountries();
    const basicCodes: any = allCountries.filter(country => {
      // Define the criteria for basic country codes.
      // For example, you might consider countries with phone codes less than 100.
      return parseInt(country.phonecode) < 100;
    });
    // Sort the basic country codes alphabetically
    basicCodes.sort((a: any, b: any) => a.name.localeCompare(b.name));
    setBasicCountryCodes(basicCodes);
  };
  const countryOptions: { value: string, label: string }[] = Object.keys(countries).map((key) => ({
    value: '+' + countries[key as keyof TCountries].phone,
    label: `${countries[key as keyof TCountries].name} (+${countries[key as keyof TCountries].phone})`,
  }));
 
 

  //PS_95 PS_96 PS_97 PS_98 PS_99 PS_100 PS_101 PS_102 PS_103 PS_104 PS_105 PS_106 
  //	PS107 	PS108 	PS109 	PS110 	PS111 	PS112 	PS113 	PS114 	PS115 	PS116  
  const getClient = async () => {
    try {
      setLoader(true)
      let payload = {
        clientId: location?.state?.id,
        token: await getToken()
      };
      const response: any = await getClientData(payload);
      
      setpopupmessage(response?.data?.statusMessage);
      if (response?.data?.statusCode !== 200) {
        setLoader(false)
        setAlertPopup(true)
      } else {
        setLoader(false)
        // setClientData(response?.data?.data?.ClientData);
        setEditClientData((prevState) => ({
          ...prevState,
          userName: response?.data?.data?.ClientData[0]?.userName,
          email: response?.data?.data?.ClientData[0]?.mailId,
          companyName: response?.data?.data?.ClientData[0]?.companyName || "Avasoft",
          registrationType:
            response?.data?.data?.ClientData[0].isSSOAuthentication === true
              ? "SSO"
              : "Email",
          phone: response?.data?.data?.ClientData[0]?.phoneNumber

        }));
        setDisplayClientData((prevState: any) => ({
          ...prevState,
          userName: response?.data?.data?.ClientData[0]?.userName,
          email: response?.data?.data?.ClientData[0]?.mailId,
          companyName: response?.data?.data?.ClientData[0]?.companyName || "Avasoft",
          registrationType:
           response?.data?.data?.ClientData[0].isSSOAuthentication === true
              ? "SSO"
              : "Email",
          phone: response?.data?.data?.ClientData[0]?.phoneNumber

        }));
      }
console.log();



    } catch (error: any) {
      console.error(error);
    }
  };


  //PS_64 PS_65 PS_66 PS_67 PS_68 PS_69 PS_70 PS_71 PS_72 PS_73 PS_74 PS_75 PS_76 PS_77 PS_78 	PS79 	PS80 	PS81 	
  //PS82 	PS83 	PS84 	PS85 	PS86 	PS87 	PS88 	PS89 	PS90  	PS91  	PS92  	PS93  	PS94 
  const handleFieldValidation = (id: string, value: string, userAction: string) => {
    debugger
    
    if (userAction === 'new') {
      try{
      let result = true
      if (id === 'user-name') {
        clientData.userName = value
        setClientData(prevState => ({ ...prevState, userName: value }));
         if (value.length > 80) {
          setErrorMessages(errorMessages=>({ ...errorMessages, errUsername: 'Maximum value count exceeded' }));
          result = false
          
        }
       
       else if (!value) {
          setErrorMessages((errorMessages)=>({ ...errorMessages, errUsername: 'Please enter the value' }));
          result = false
          return 'Please enter the value';
        }
        else if (!/^[a-zA-Z0-9_%+-]+$/.test(value)) {
          setErrorMessages((prevState) => ({
            ...prevState,
            errUsername: "Username cannot contain special characters.",
          }));
          result = false;
          return 'Username cannot contain special characters';
        }
        else {
          setErrorMessages({ ...errorMessages, errUsername: '' });

        }
      } else if (id === 'company-name') {
        clientData.companyName =value
       
        if (!value) {
          setErrorMessages({ ...errorMessages, errCompanyName: 'Please enter the value' });
          result = false
          return 'Please enter the value';
        } else if (value.length > 80) {
          setErrorMessages({ ...errorMessages, errCompanyName: 'Maximum value count exceeded' });
          result = false
          return 'Maximum value count exceeded';
        }
        else {
          setErrorMessages({ ...errorMessages, errCompanyName: '' });

        }
      } else if (id === 'ph-no') {
       // 
       clientData.phone = value;
       setClientData(prevState => ({ ...prevState, phone: value }));

        if (!value) {
          setErrorMessages(errorMessages=>({ ...errorMessages, errPhone: 'Please enter the value' }));
          result = false
          return 'Please enter the value';
        } else if (value.length > 15) {
          setErrorMessages(errorMessages=>({ ...errorMessages, errPhone: 'Maximum value count exceeded' }));
          result = false
          return 'Maximum value count exceeded';
        } else if (!/^[0-9]{5,15}$/.test(value)) {
          setErrorMessages(errorMessages=>({ ...errorMessages, errPhone: 'Provide valid phone number' }));
          result = false
          return 'Provide valid phone number';
        }
        else {
          setErrorMessages(errorMessages=>({ ...errorMessages, errPhone: '' }));

        }
      } else if (id === 'registrationType') {
        clientData.registrationType = value
        setClientData((prevState) => ({ ...prevState, registrationType: value }));
       
      } else if (id === 'email-address') {
        clientData.email = value
        setClientData((prevState) => ({ ...prevState, email: value }));
        if (!value) {
          setErrorMessages(errorMessages=>({ ...errorMessages, errEmail: 'Please enter the value' }));
          result = false
          return 'Please enter the value';
        } else if (value.length > 320) {
          setErrorMessages(errorMessages=>({ ...errorMessages, errEmail: 'Maximum value count exceeded' }));
          result = false
          return 'Maximum value count exceeded';
        } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(value)) {
          setErrorMessages(errorMessages=>({ ...errorMessages, errEmail: 'Provide valid email' }));
          result = false
          return 'Provide valid email';
        }
        else {
          setErrorMessages(errorMessages=>({ ...errorMessages, errEmail: '' }));

        }
      } else if (id === "environmenttype") {
        
        setClientData((prevState) => ({ ...prevState, authenticationType: value }));
        if (!value) {
          setErrorMessages(errorMessages=>({ ...errorMessages, errAuthenticationType: 'Please enter the value' }));
          result = false
          return 'Please enter the value';
        }
        else {
          setErrorMessages(errorMessages=>({ ...errorMessages, errAuthenticationType: '' }));

        }
      }
      else if (id === "status") {
        clientData.status = value
        setClientData((prevState) => ({ ...prevState, status: value }));
        if (!value) {
          setErrorMessages(errorMessages=>({ ...errorMessages, errAuthenticationType: 'Please enter the value' }));
          result = false
          return 'Please enter the value';
        }
        else {
          setErrorMessages(errorMessages=>({ ...errorMessages, errAuthenticationType: '' }));

        }
        
      }
    }finally{
      if(clientData.userName && (/^[a-zA-Z0-9_%+-]+$/.test(clientData.userName))  &&  
        clientData.phone && (/^[0-9]{5,15}$/.test(clientData.phone.toString().trim()))  && 
        clientData.companyName && 
        clientData.authenticationType && clientData.email && 
        (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(clientData.email)) && clientData.status){
          setEnablebutton(true)
        }else{
          setEnablebutton(false)
        }
      }

      

    } else if (userAction === 'edit') {
      let result = true
      if (id === 'user-name') {
        setEditClientData((prevState) => ({ ...prevState, userName: value }));
        if (!value) {
          seterroreditMessage(errorMessages=>({ ...errorMessages, errUsername: 'Please enter the value' }));
          result = false
        } else if (value.length > 80) {
          seterroreditMessage(errorMessages=>({ ...errorMessages, errUsername: 'Maximum value count exceeded' }));
          result = false
        }
        else if (!/^[a-zA-Z0-9_]+$/.test(value)) {
          seterroreditMessage((prevState) => ({
            ...prevState,
            erruserName: "Username cannot contain special characters.",
          }));
          result = false;
        }
        else {
          seterroreditMessage(errorMessages=>({ ...errorMessages, errUsername: '' }));
        }
      } else if (id === 'userComp') {
        setEditClientData((prevState) => ({ ...prevState, companyName: value }));
        if (!value) {
          seterroreditMessage(errorMessages=>({ ...errorMessages, errCompanyName: 'Please enter the value' }));
          result = false
        } else if (value.length > 80) {
          seterroreditMessage(errorMessages=>({ ...errorMessages, errCompanyName: 'Maximum value count exceeded' }));
          result = false
        }
        else {
          seterroreditMessage(errorMessages=>({ ...errorMessages, errCompanyName: '' }));
        }
      }
      else if (id === 'phone') {
        setEditClientData((prevState) => ({ ...prevState, phone: value }));
        if (!value) {
          seterroreditMessage(errorMessages=>({ ...errorMessages, errPhone: 'Please enter the value' }));
          result = false
          return 'Please enter the value';
        } else if (value.length > 15) {
          seterroreditMessage(errorMessages=>({ ...errorMessages, errPhone: 'Maximum value count exceeded' }));
          result = false
          return 'Maximum value count exceeded';
        } else if (!/^[0-9]{5,15}$/.test(value)) {
          debugger
          seterroreditMessage(errorMessages=>({ ...errorMessages, errPhone: 'Provide valid phone number' }));
          result = false
          return 'Provide valid phone number';
        }
        else {
          seterroreditMessage(errorMessages=>({ ...errorMessages, errPhone: '' }));

        }
      }
      
       setEnablebutton(result)

    }
  };

  const handleCountryChange = (selectedOption: any) => {
    debugger
    const countryCode = selectedOption.value;
    setClientData((prevDetails) => ({
      ...prevDetails,
      phonecode: countryCode,
    }));
    
    
    setEditClientData((prevDetails) => ({
      ...prevDetails,
      phonecode: countryCode,
    }));
  };
  const handleOnClickValidation =  (useraction:string) =>{
    let valid = true;
    if(useraction === "new"){
    if (!clientData.userName) {
     setErrorMessages(errorMessages=>({ ...errorMessages, errUsername: 'Please enter the value' }));
     valid = false
    
     
    }
      else {
        setErrorMessages(errorMessages=>({ ...errorMessages, errUsername: '' }));

      }
     
      if(clientData.email==="") {
        setErrorMessages(errorMessages=>({ ...errorMessages, errEmail: 'Please enter the Email' }));
        valid = false
       }
         else {
           setErrorMessages(errorMessages=>({ ...errorMessages, errEmail: '' }));
   
         }
         if(!clientData.authenticationType) {
          setErrorMessages(errorMessages=>({ ...errorMessages, errAuthenticationType: 'Please select the Authentication Type' }));
          valid = false
         }
           else {
             setErrorMessages(errorMessages=>({ ...errorMessages, errAuthenticationType: '' }));
     
           }
           if(!clientData.companyName) {
            setErrorMessages(errorMessages=>({ ...errorMessages, errCompanyName: 'Please enter the  Company Name' }));
            valid = false
           }
             else {
               setErrorMessages(errorMessages=>({ ...errorMessages, errCompanyName: '' }));
       
             }
             if(!clientData.registrationType) {
              setErrorMessages(errorMessages=>({ ...errorMessages, errRegistrationType: 'Please enter the  Registration Type' }));
              valid = false
             }
               else {
                 setErrorMessages(errorMessages=>({ ...errorMessages, errRegistrationType: '' }));
         
               }
               if(!clientData.phone) {
                setErrorMessages(errorMessages=>({ ...errorMessages, errPhone: 'Please enter the  Phone Number ' }));
                valid = false
               }
                 else {
                   setErrorMessages(errorMessages=>({ ...errorMessages, errPhone: '' }));
           
                 }
                
    } 
    if(useraction === "edit"){
      if (!editClientData.userName) {
       seterroreditMessage(errorMessages=>({ ...errorMessages, errUsername: 'Please enter the value' }));
       valid = false
      
       
      }
        else {
          seterroreditMessage(errorMessages=>({ ...errorMessages, errUsername: '' }));
  
        }
       
        if(editClientData.email==="") {
          seterroreditMessage(errorMessages=>({ ...errorMessages, errEmail: 'Please enter the Email' }));
          valid = false
         }
           else {
             seterroreditMessage(errorMessages=>({ ...errorMessages, errEmail: '' }));
     
           }
           if(!editClientData.authenticationType) {
            seterroreditMessage(errorMessages=>({ ...errorMessages, errAuthenticationType: 'Please select the Authentication Type' }));
            valid = false
           }
             else {
               seterroreditMessage(errorMessages=>({ ...errorMessages, errAuthenticationType: '' }));
       
             }
             if(!editClientData.companyName) {
              seterroreditMessage(errorMessages=>({ ...errorMessages, errCompanyName: 'Please enter the  Company Name' }));
              valid = false
             }
               else {
                 seterroreditMessage(errorMessages=>({ ...errorMessages, errCompanyName: '' }));
         
               }
               if(!editClientData.registrationType) {
                seterroreditMessage(errorMessages=>({ ...errorMessages, errRegistrationType: 'Please enter the  Registration Type' }));
                valid = false
               }
                 else {
                   seterroreditMessage(errorMessages=>({ ...errorMessages, errRegistrationType: '' }));
           
                 }
                 if(editClientData.phone.length===0) {
                  seterroreditMessage(errorMessages=>({ ...errorMessages, errPhone: 'Please enter the  Phone Number ' }));
                  valid = false
                 }
                   else {
                     seterroreditMessage(errorMessages=>({ ...errorMessages, errPhone: '' }));
             
                   }
                  
      } 
    return valid
  }

  //PS_95 PS_96 PS_97 PS_98 PS_99 PS_100 PS_101 PS_102 PS_103 PS_104 PS_105 PS_106 	PS107 	PS108 	PS109 	PS110 	PS111 	PS112 	PS113 	PS114 	PS115 	PS116  	PS117  	
  //PS118  	PS119  	PS120  	PS121  	PS122  	PS123  	PS124  	PS125   	
  const handleClientdata = async ( userAction: string) => {
    //e.preventDefault();
    console.log("the clientData",clientData);
    
    setLoader(true)
    let isValid:any =false
    isValid = handleOnClickValidation(userAction)
    console.log("the clientData",clientData);
    console.log("the client details",isValid)
    debugger
    try {
      setEditPopup(true)
      let payload: object;
      
      // Depending on the action, we prepare payload and use different endpoints
      if (isValid) {
        if (userAction === 'new') {
         
          try {
            debugger;

            payload = {
              token: await getToken(),
              userName: clientData?.userName,
              companyName: clientData?.companyName,
              email: clientData?.email,
              phonenumber: clientData?.phone,
              phonecode: clientData?.phonecode,
              status: clientData?.status,
              registrationType: clientData?.registrationType,
              authenticationType: clientData?.authenticationType,
              userAction: userAction
            };
            setLoader(true)
            const response: any = await handleClient(payload);
            console.log("the output of", response)
            setpopupmessage(response?.data?.statusMessage);
            if(response?.data?.data.statusMessage ==="Success"){
              setLoader(false)
              setSuccessPopup(true)
              setClientData({ userName: "",
                 companyName: "", registrationType: "",
                  authenticationType: "", 
                  status: "", phone: "",
                   phonecode: "",
                   email:"" })
              
             
             // setClientData(response.data.data.clientData);
            }
            
            //console.log(response);
            else if (response.data.data[0].ErrorMessage==='Error: Email already exists in one of the tables.') {
              setLoader(false)
              setEditPopup(false)
              setAlertPopup(true)
                setErrorMessages((prevState) => ({
                  ...prevState,
                  errEmail: "Email already Exist",
                }));
            }
            if (response?.data?.data.statusCode !== 200) {
              setLoader(false)
              setEditPopup(false)
              setAlertPopup(true)
            }
        
          } catch (error: any) {
            console.error(error);
            setLoader(false)
            setAlertPopup(true)
          }
        } if (userAction === 'edit') {
          try {
            let payload;

            payload = {
              token: await getToken(),
              manageClientId: location?.state?.id,
              userName: editClientData?.userName,
              companyName: editClientData?.companyName,
              email: editClientData?.email,
              phone:editClientData?.phone,
              userAction: userAction
            };
            setLoader(true)
        

            const response: any = await handleClient(payload);
            setpopupmessage(response?.data?.statusMessage)
            if (response.status === 200) {
              setLoader(false)
              setEditPopup(false)
              seterroreditMessage((prevState) => ({
                ...prevState,
                errmail: "Email already Exist",
              }));
            }
            if (response?.data?.statusCode !== 200) {
              setLoader(false)
              setEditPopup(false)
              setAlertPopup(true)
            } else {
              setDisplayClientData(response.data.data.clientData);
              getClient()
              setLoader(false)
              setEditPopup(false)
              setSuccessPopup(true)
            }
          } catch (error: any) {
            console.log(error);
            setLoader(false)
            setAlertPopup(true)
          }
        }
      }
      else {
        setLoader(false)
       
      }
    }
    catch (error: any) {
      setLoader(false)
      setAlertPopup(true)
    }
  }


  //   return (
  //     <>

  //       <div className="container-fluid container-height">
  //         {location.state.action == 'NewClient' ?
  //           <div className="row">
  //             <div className="col-md-12">
  //               <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
  //                 {/* bread crumb starts here */}
  //                 <ul className="breadcrumb-item mb-4 ps-0">
  //                   <li>
  //                     <a onClick={() => history.push('/manageClient')} className="pe-0">
  //                       Manage Clients
  //                     </a>
  //                   </li>
  //                   <li className="pe-2">New Client</li>
  //                 </ul>
  //                 {/* bread crumb ends here */}
  //                 <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap gap-3 ">
  //                   <h5 className="primary-header font-20 font-bold mb-0 d-flex align-items-center">
  //                     <a onClick={() => history.push('/manageClient')}>
  //                       <img
  //                         src="/images/head-back-arrow.svg"
  //                         alt="back-arrow"
  //                         width={19}
  //                         className="me-2"
  //                       />
  //                     </a>
  //                     New Client
  //                   </h5>
  //                 </div>
  //                 {/*forms starts here*/}
  //                 <div className="row mb-3">
  //                   <div className="col-md-12 mt-3 mb-2">
  //                     <h5 className="font-bold font-20">Account Information</h5>
  //                   </div>
  //                   <div className="col-md-4 mb-4">
  //                     <label
  //                       className="form-label font-14 font-semibold label-color label-color"
  //                       htmlFor="user-name"
  //                     >
  //                       User Name <span className="mandatory-color">*</span>
  //                     </label>
  //                     <input
  //                       type="text"
  //                       className="form-control custom-form font-14 font-regular py-2"
  //                       id="user-name"
  //                       name='userName'
  //                       placeholder="Enter User Name"
  //                       value={clientData?.userName}
  //                       onChange={(e) =>
  //                         handleFieldValidation(
  //                           e.target.id,
  //                           e.target.value,
  //                           "new"
  //                         )
  //                       }
  //                     />
  //                     {errorMessages.errUsername && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 "> {errorMessages.errUsername}</span>}

  //                   </div>
  //                   <div className="col-md-4 mb-4">
  //                     <label
  //                       className="form-label font-14 font-semibold label-color label-color"
  //                       htmlFor="company-name"
  //                     >
  //                       Company Name <span className="mandatory-color">*</span>
  //                     </label>
  //                     <input
  //                       type="text"
  //                       className="form-control custom-form font-14 font-regular py-2"
  //                       id="company-name"
  //                       name='companyName'
  //                       placeholder="Enter Company Name"
  //                       value={clientData?.companyName}
  //                       onChange={(e) =>
  //                         handleFieldValidation(
  //                           e.target.id,
  //                           e.target.value,
  //                           "new"
  //                         )
  //                       }
  //                     />
  //                     {errorMessages.errCompanyName && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{errorMessages.errCompanyName}</span>}

  //                   </div>
  //                   <div className="col-md-4 mb-4">
  //                     <label
  //                       className="form-label font-14 font-semibold label-color label-color"
  //                       htmlFor="email-address"
  //                     >
  //                       Email Address <span className="mandatory-color">*</span>
  //                     </label>
  //                     <input
  //                       type="text"
  //                       className="form-control custom-form font-14 font-regular py-2"
  //                       id="email-address"
  //                       placeholder="Enter Email Address"
  //                       value={clientData?.email}
  //                       name='email'
  //                       onChange={(e) =>
  //                         handleFieldValidation(
  //                           e.target.id,
  //                           e.target.value,
  //                           "new"
  //                         )
  //                       }
  //                     />
  //                     {errorMessages.errEmail && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{errorMessages.errEmail}</span>}

  //                   </div>
  //                   <div className="col-md-4 mb-4">
  //                     <label
  //                       className="form-label font-14 font-semibold label-color label-color"
  //                       htmlFor="ph-no"
  //                     >
  //                       Phone Number <span className="mandatory-color">*</span>
  //                     </label>
  //                     <div className="input-group mb-3 input-group mb-3 form-control custom-form rounded p-0 phone-group">
  //                       <Select
  //                         options={countryOptions}
  //                         onChange={handleCountryChange}
  //                         value={countryOptions.find((option: OptionType) => option.value === clientData.phonecode)}
  //                       />
  //                       <input
  //                         type="text"
  //                         className="form-control custom-form font-14 font-regular py-2"
  //                         id="ph-no"
  //                         onChange={(e) =>
  //                           handleFieldValidation(
  //                             e.target.id,
  //                             e.target.value,
  //                             "new"
  //                           )
  //                         }
  //                         value={clientData?.phone}
  //                       />
  //                       {errorMessages.errPhone && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{errorMessages.errPhone}</span>}

  //                     </div>
  //                   </div>
  //                   <div className="col-md-4 mb-4">
  //                     <label className="form-label font-14 font-semibold label-color label-color w-100">
  //                       Status <span className="mandatory-color">*</span>
  //                     </label>
  //                     <div className="form-check form-check-inline mt-2">
  //                       <input
  //                         className="form-check-input custom-radio-input"
  //                         type="radio"
  //                         name="status"
  //                         id="Active"
  //                         defaultChecked
  //                         checked={clientData.status === 'Active'}
  //                         onChange={(e) => handleFieldValidation("status", e.target.value, "new")}
  //                         value='Active'
  //                       />
  //                       <label className="form-check-label font-14 font-regular" htmlFor="Active">
  //                         Active
  //                       </label>
  //                     </div>
  //                     <div className="form-check form-check-inline mt-2">
  //                       <input
  //                         className="form-check-input custom-radio-input"
  //                         type="radio"
  //                         name="status"
  //                         id="InActive"
  //                         checked={clientData.status === 'InActive'}
  //                         onChange={(e) => handleFieldValidation('status', e.target.value, "new")}
  //                         value='InActive'
  //                       />
  //                       <label className="form-check-label font-14 font-regular" htmlFor="InActive">
  //                         Inactive
  //                       </label>
  //                     </div>
  //                     {errorMessages.status && <span className="font-12 font-semibold mandatory-color mt-2 mb-0">{errorMessages.status}</span>}
  //                   </div>

  //                   <div className="col-md-4 mb-4">
  //                     <label className="form-label font-14 font-semibold label-color label-color w-100">
  //                       Registration Type <span className="mandatory-color">*</span>
  //                     </label>
  //                     <div className="form-check form-check-inline mt-2">
  //                       <input
  //                         className="form-check-input custom-radio-input"
  //                         type="radio"
  //                         name="regtype"
  //                         id="SSO"
  //                         defaultChecked
  //                         checked={clientData?.registrationType.toLowerCase().trim() === 'sso'}
  //                         onChange={(e) => handleFieldValidation('registrationType', e.target.value, "new")}
  //                         value='SSO'
  //                       />
  //                       <label className="form-check-label font-14 font-regular" htmlFor="SSO">
  //                         SSO
  //                       </label>
  //                     </div>
  //                     <div className="form-check form-check-inline mt-2">
  //                       <input
  //                         className="form-check-input custom-radio-input"
  //                         type="radio"
  //                         name="regtype"
  //                         id="Email"
  //                         checked={clientData?.registrationType.toLowerCase().trim() === 'email'}
  //                         onChange={(e) => handleFieldValidation('registrationType', e.target.value, "new")}
  //                         value='Email'
  //                       />
  //                       <label className="form-check-label font-14 font-regular" htmlFor="Email">
  //                         Email
  //                       </label>
  //                     </div>
  //                     {errorMessages.errRegistrationType && <span className="font-12 font-semibold mandatory-color mt-2 mb-0">{errorMessages.errRegistrationType}</span>}
  //                   </div>

  //                   <div className="col-md-4 mb-4">
  //                     <label className="form-label font-14 font-semibold label-color label-color w-100">
  //                       Environment Type <span className="mandatory-color">*</span>
  //                     </label>
  //                     <div className="form-check form-check-inline mt-2">
  //                       <input
  //                         className="form-check-input custom-radio-input"
  //                         type="radio"
  //                         name="envtype"
  //                         id="Tenant"
  //                         checked={clientData?.authenticationType === 'Tenant'}
  //                         onChange={(e) => handleFieldValidation('environmenttype', e.target.value, "new")}
  //                         value='Tenant'
  //                       />
  //                       <label className="form-check-label font-14 font-regular" htmlFor="Tenant">
  //                         Tenant
  //                       </label>
  //                     </div>
  //                     <div className="form-check form-check-inline mt-2">
  //                       <input
  //                         className="form-check-input custom-radio-input"
  //                         type="radio"
  //                         name="envtype"
  //                         id="g-suite"
  //                         disabled={true}
  //                         checked={clientData?.authenticationType === 'g-suite'}
  //                         onChange={(e) => handleFieldValidation("environmenttype", e.target.value, "new")}
  //                         value='g-suite'
  //                       />
  //                       <label className="form-check-label font-14 font-regular" htmlFor="g-suite">
  //                         Gsuite
  //                       </label>
  //                     </div>
  //                     {errorMessages.errAuthenticationType && <span className="font-12 font-semibold mandatory-color mt-2 mb-0">{errorMessages.errAuthenticationType}</span>}
  //                   </div>

  //                   <div className="col-md-12">
  //                     <div className="d-flex justify-content-end align-items-center py-4">
  //                       <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4" onClick={() => history.push('/Admin/ManageClients')}>
  //                         Cancel
  //                       </button>
  //                       <button className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4" onClick={(e) => handleClientdata(clientData, 'new')} disabled={!enable}>
  //                         Submit
  //                       </button>
  //                     </div>
  //                   </div>
  //                 </div>
  //                 {/*forms ends here*/}
  //               </div>
  //             </div>
  //           </div> :
  //           <div className="row">
  //             <div className="col-md-12">
  //               <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
  //                 {/* bread crumb starts here */}
  //                 <ul className="breadcrumb-item mb-4 ps-0">
  //                   <li>
  //                     <a onClick={() => history.push('/manageClient')} className="pe-0">
  //                       Manage Clients
  //                     </a>
  //                   </li>
  //                   <li className="pe-2">Zeb</li>
  //                 </ul>
  //                 {/* bread crumb ends here */}
  //                 <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap gap-3 ">
  //                   <h5 className="primary-header font-20 font-20 mb-0 d-flex align-items-center">
  //                     <a onClick={() => history.push('/manageClient')}>
  //                       <img
  //                         src="/images/head-back-arrow.svg"
  //                         alt="back-arrow"
  //                         width={19}
  //                         className="me-2"
  //                       />
  //                     </a>
  //                     {editClientData.companyName}
  //                   </h5>
  //                 </div>
  //                 <div className="row">
  //                   <div className="col-md-12 mb-2">
  //                     <h5 className="font-bold font-18 primary-color">
  //                       Available License
  //                     </h5>
  //                   </div>
  //                   <div className="d-md-flex align-items-center mb-3">
  //                     <p className="font-14 font-regular me-4 color-black">
  //                       User Count
  //                       <span className="font-16 font-bold primary-color ms-2">
  //                         07/30
  //                       </span>
  //                     </p>
  //                     <p className="font-14 font-regular me-4 color-black">
  //                       Tenant Size
  //                       <span className="font-16 font-bold primary-color ms-2">
  //                         2.15 GB/4 GB
  //                       </span>
  //                     </p>
  //                     <p className="font-14 font-regular me-4 color-black">
  //                       Parallel Instance Count
  //                       <span className="font-16 font-bold primary-color ms-2">
  //                         3/5
  //                       </span>
  //                     </p>
  //                     <p className="font-14 font-regular me-4 color-black">
  //                       Assessment Count
  //                       <span className="font-16 font-bold primary-color ms-2">
  //                         3/5
  //                       </span>
  //                     </p>
  //                     <p className="font-14 font-regular me-4 color-black">
  //                       Plan Expires On
  //                       <span className="font-16 font-bold primary-color ms-2">
  //                         May 15, 2019
  //                       </span>
  //                     </p>
  //                   </div>
  //                 </div>
  //                 {/*Tabs Starts here*/}
  //                 <div className="tab-scroll d-flex">
  //                   <ul
  //                     className="nav nav-pills mb-4 pb-md-2 d-flex custom-mobile-tab-list"
  //                     id="pills-tab"
  //                     role="tablist"
  //                   >
  //                     <li
  //                       className="nav-item custom-bg rounded-start"
  //                       role="presentation"
  //                     >
  //                       <button
  //                         className="nav-link theme-tab active font-16"
  //                         data-bs-toggle="pill"
  //                         data-bs-target="#accountInformation"
  //                         type="button"
  //                         role="tab"
  //                         aria-selected="true"
  //                       >
  //                         Account Information
  //                       </button>
  //                     </li>
  // <li className="nav-item custom-bg" role="presentation">
  //   <button
  //     className="nav-link theme-tab font-16"
  //     data-bs-toggle="pill"
  //     data-bs-target="#license-assignment"
  //     type="button"
  //     role="tab"
  //     aria-selected="false"
  //   >
  //     License Assignment
  //   </button>
  // </li>
  //                     <li className="nav-item custom-bg" role="presentation">
  //                       <button
  //                         className="nav-link theme-tab font-16"
  //                         data-bs-toggle="pill"
  //                         data-bs-target="#invoice-details"
  //                         type="button"
  //                         role="tab"
  //                         aria-selected="false"
  //                       >
  //                         Invoice Details
  //                       </button>
  //                     </li>
  //                     <li
  //                       className="nav-item custom-bg rounded-end"
  //                       role="presentation"
  //                     >
  //                       <button
  //                         className="nav-link theme-tab font-16"
  //                         data-bs-toggle="pill"
  //                         data-bs-target="#audit-history"
  //                         type="button"
  //                         role="tab"
  //                         aria-selected="false"
  //                       >
  //                         Audit History
  //                       </button>
  //                     </li>
  //                   </ul>
  //                 </div>
  //                 {/*Tabs ends here*/}
  //                 {/*Tab Inner starts here*/}
  //                 <div className="tab-content" id="pills-tabContent">
  //                   {/*Account Information Tab starts here*/}
  //                   <div
  //                     className="tab-pane fade show active"
  //                     id="accountInformation"
  //                     role="tabpanel"
  //                   >
  //                     <div className="row mb-3">
  //                       <div className="col-md-12 mt-3 mb-2">
  //                         <h5 className="font-bold font-20">Account Information</h5>
  //                       </div>
  //                       <div className="col-md-4 mb-4">
  //                         <label
  //                           className="form-label font-14 font-semibold label-color label-color"
  //                           htmlFor="user-name"
  //                         >
  //                           User Name <span className="mandatory-color">*</span>
  //                         </label>
  //                         <input
  //                           type="text"
  //                           className="form-control custom-form font-14 font-regular py-2"
  //                           id="user-name"
  //                           name='userName'
  //                           placeholder="Enter User Name"
  //                           value={editClientData?.userName}
  //                           onChange={(e) =>
  //                             handleFieldValidation(
  //                               e.target.id,
  //                               e.target.value,
  //                               "edit"
  //                             )
  //                           }
  //                         />
  //                         {erroreditMessage.errUsername && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 "> {erroreditMessage.errUsername}</span>}

  //                       </div>
  //                       <div className="col-md-4 mb-4">
  //                         <label
  //                           className="form-label font-14 font-semibold label-color label-color"
  //                           htmlFor="company-name"
  //                         >
  //                           Company Name <span className="mandatory-color">*</span>
  //                         </label>
  //                         <input
  //                           type="text"
  //                           className="form-control custom-form font-14 font-regular py-2"
  //                           id="company-name"
  //                           name='companyName'
  //                           placeholder="Enter Company Name"
  //                           value={editClientData?.companyName}
  //                           onChange={(e) =>
  //                             handleFieldValidation(
  //                               e.target.id,
  //                               e.target.value,
  //                               "edit"
  //                             )
  //                           }
  //                         />
  //                         {erroreditMessage.errCompanyName && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{erroreditMessage.errCompanyName}</span>}

  //                       </div>
  //                       <div className="col-md-4 mb-4">
  //                         <label
  //                           className="form-label font-14 font-semibold label-color label-color"
  //                           htmlFor="email-address"
  //                         >
  //                           Email Address <span className="mandatory-color">*</span>
  //                         </label>
  //                         <input
  //                           type="text"
  //                           className="form-control custom-form font-14 font-regular py-2"
  //                           id="email-address"
  //                           placeholder="Enter Email Address"
  //                           value={editClientData?.email}
  //                           name='email'
  //                           onChange={(e) =>
  //                             handleFieldValidation(
  //                               e.target.id,
  //                               e.target.value,
  //                               "edit"
  //                             )
  //                           }
  //                         />
  //                         {erroreditMessage.errEmail && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{erroreditMessage.errEmail}</span>}

  //                       </div>
  //                       <div className="col-md-4 mb-4">
  //                         <label
  //                           className="form-label font-14 font-semibold label-color label-color"
  //                           htmlFor="ph-no"
  //                         >
  //                           Phone Number <span className="mandatory-color">*</span>
  //                         </label>
  //                         <div className="input-group mb-3 input-group mb-3 form-control custom-form rounded p-0 phone-group">
  //                           <Select
  //                             options={countryOptions}
  //                             onChange={handleCountryChange}
  //                             value={countryOptions.find((option: OptionType) => option.value === clientData.phonecode)}
  //                           />
  //                           <input
  //                             type="text"
  //                             className="form-control custom-form font-14 font-regular py-2"
  //                             id="ph-no"
  //                             onChange={(e) =>
  //                               handleFieldValidation(
  //                                 e.target.id,
  //                                 e.target.value,
  //                                 "edit"
  //                               )
  //                             }
  //                             value={editClientData?.phone}
  //                           />
  //                           {erroreditMessage.errPhone && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{erroreditMessage.errPhone}</span>}

  //                         </div>
  //                       </div>
  //                       <div className="col-md-4 mb-4">
  //                         <label className="form-label font-14 font-semibold label-color label-color w-100">
  //                           Status <span className="mandatory-color">*</span>
  //                         </label>
  //                         <div className="form-check form-check-inline mt-2">
  //                           <input
  //                             className="form-check-input custom-radio-input"
  //                             type="radio"
  //                             name="status"
  //                             id="Active"
  //                             defaultChecked
  //                             checked={editClientData.status == 'Active'}
  //                             onChange={(e) =>
  //                               handleFieldValidation(
  //                                 "status",
  //                                 e.target.value,
  //                                 "edit"
  //                               )
  //                             }
  //                             value='Active'
  //                           />
  //                           <label
  //                             className="form-check-label font-14 font-regular"
  //                             htmlFor="Active"
  //                           >
  //                             Active
  //                           </label>

  //                         </div>
  //                         <div className="form-check form-check-inline mt-2">
  //                           <input
  //                             className="form-check-input custom-radio-input"
  //                             type="radio"
  //                             name="status"
  //                             id="InActive"
  //                             checked={editClientData.status === 'InActive'}
  //                             onChange={(e) =>
  //                               handleFieldValidation(
  //                                 "status",
  //                                 e.target.value,
  //                                 "edit"
  //                               )
  //                             }
  //                             value='InActive'

  //                           />
  //                           <label
  //                             className="form-check-label font-14 font-regular"
  //                             htmlFor="InActive"
  //                           >
  //                             Inactive
  //                           </label>
  //                         </div>
  //                         {erroreditMessage.status && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{erroreditMessage.status}</span>}
  //                       </div>
  //                       <div className="col-md-4 mb-4">
  //                         <label className="form-label font-14 font-semibold label-color label-color w-100">
  //                           Registration Type <span className="mandatory-color">*</span>
  //                         </label>
  //                         <div className="form-check form-check-inline mt-2">
  //                           <input
  //                             className="form-check-input custom-radio-input"
  //                             type="radio"
  //                             name="regtype"
  //                             id="SSO"
  //                             checked={editClientData.registrationType == 'SSO'}
  //                             onChange={(e) =>
  //                               handleFieldValidation(
  //                                 'registrationType',
  //                                 e.target.value,
  //                                 "edit"
  //                               )
  //                             }
  //                             value='SSO'
  //                           />
  //                           <label
  //                             className="form-check-label font-14 font-regular"
  //                             htmlFor="SSO"
  //                           >
  //                             SSO
  //                           </label>
  //                         </div>
  //                         <div className="form-check form-check-inline mt-2">
  //                           <input
  //                             className="form-check-input custom-radio-input"
  //                             type="radio"
  //                             name="regtype"
  //                             id="Email"
  //                             defaultValue="option3"
  //                             checked={editClientData.registrationType == 'Email'}
  //                             onChange={(e) =>
  //                               handleFieldValidation(
  //                                 'registrationType',
  //                                 e.target.value,
  //                                 "edit"
  //                               )
  //                             }
  //                             value='Email'
  //                           />
  //                           <label
  //                             className="form-check-label font-14 font-regular"
  //                             htmlFor="Email"
  //                           >
  //                             Email
  //                           </label>
  //                         </div>
  //                         {erroreditMessage.errRegistrationType && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{erroreditMessage.errRegistrationType}</span>}

  //                       </div>
  //                       {/* <div className="col-md-4 mb-4">
  //                         <label className="form-label font-14 font-semibold label-color label-color w-100">
  //                           Environment Type <span className="mandatory-color">*</span>
  //                         </label>
  //                         <div className="form-check form-check-inline mt-2">
  //                           <input
  //                             className="form-check-input custom-radio-input"
  //                             type="radio"
  //                             name="envtype"
  //                             id="Tenant"
  //                             checked={editClientData.authenticationType == 'Tenant'}
  //                             onChange={(e) =>
  //                               handleFieldValidation(
  //                                 'environmenttype',
  //                                 e.target.value,
  //                                 "edit"
  //                               )
  //                             }
  //                             value='Tenant'
  //                           />
  //                           <label
  //                             className="form-check-label font-14 font-regular"
  //                             htmlFor="Tenant"

  //                           >
  //                             Tenant
  //                           </label>
  //                         </div>
  //                         <div className="form-check form-check-inline mt-2">
  //                           <input
  //                             className="form-check-input custom-radio-input"
  //                             type="radio"
  //                             name="envtype"
  //                             id="g-suite"
  //                             checked={editClientData.authenticationType == 'g-suite'}
  //                             onChange={(e) =>
  //                               handleFieldValidation(
  //                                 "environmenttype",
  //                                 e.target.value,
  //                                 "edit"
  //                               )
  //                             }
  //                             disabled={true}
  //                             value='g-suite'
  //                           />
  //                           <label
  //                             className="form-check-label font-14 font-regular"
  //                             htmlFor="g-suite"
  //                           >
  //                             Gsuite
  //                           </label>
  //                         </div>
  //                         {erroreditMessage.errAuthenticationType && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{erroreditMessage.errAuthenticationType}</span>}

  //                       </div> */}
  //                       <div className="col-md-12">
  //                         <div className="d-flex justify-content-end align-items-center py-4">
  //                           <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4" onClick={() => history.push('/Admin/ManageClients')}>
  //                             Cancel
  //                           </button>
  //                           <button className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4" onClick={(e) => handleClientdata(e, 'edit')} disabled={!enable}>
  //                             Submit
  //                           </button>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                   {/*Account Information Tab ends here*/}
  //                   {/*License Assignment Tab starts here*/}
  <div
    className="tab-pane fade"
    id="license-assignment"
    role="tabpanel"
  >
    <div className="row">
      <div className="col-md-12 mt-3 mb-2">
        <h5 className="font-bold font-18 color-black">
          License Assignment
        </h5>
      </div>
    </div>

    {/* //                       <div className="col-md-4 mb-4">
//                         <label */}
    {/* //                           className="form-label font-14 font-semibold label-color label-color"
//                           htmlFor="user-count"
//                         >
//                           User Count <span className="mandatory-color">*</span>
//                         </label> */}
    {/* //                         <input
//                           type="text"
//                           className="form-control custom-form font-14 font-regular py-2"
//                           id="user-count"
//                           placeholder="Enter User Count"
//                         /> */}
//                       </div>
  //                       <div className="col-md-4 mb-4">
  //                         <label
  //                           className="form-label font-14 font-semibold label-color label-color"
  //                           htmlFor="tenant-size"
  //                         >
  //                           Tenant Size <span className="mandatory-color">*</span>
  //                         </label>
  //                         <input
  //                           type="text"
  //                           className="form-control custom-form font-14 font-regular py-2"
  //                           id="tenant-size"
  //                           placeholder="Enter Tenant Size"
  //                         />
  //                       </div>
  //                       <div className="col-md-4 mb-4">
  //                         <label
  //                           className="form-label font-14 font-semibold label-color label-color"
  //                           htmlFor="instance-count"
  //                         >
  //                           Parallel Instance Count{" "}
  //                           <span className="mandatory-color">*</span>
  //                         </label>
  //                         <input
  //                           type="text"
  //                           className="form-control custom-form font-14 font-regular py-2"
  //                           id="instance-count"
  //                           placeholder="Enter Parallel Instance Count"
  //                         />
  //                       </div>
  //                       <div className="col-md-4 mb-4">
  //                         <label
  //                           className="form-label font-14 font-semibold label-color label-color"
  //                           htmlFor="assessment-count"
  //                         >
  //                           Assessment Count <span className="mandatory-color">*</span>
  //                         </label>
  //                         <input
  //                           type="text"
  //                           className="form-control custom-form font-14 font-regular py-2"
  //                           id="assessment-count"
  //                           placeholder="Enter Assessment Count"
  //                         />
  //                       </div>
  //                       <div className="col-md-12 mt-3">
  //                         <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 py-4">
  //                           <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center py-2 px-4">
  //                             Cancel
  //                           </button>
  //                           <button className="btn primary-outline-btn w-md-100 font-14 font-semibold text-nowrap">
  //                             Revoke License
  //                           </button>
  //                           <button className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4">
  //                             Assign License
  //                           </button>
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                   {/*License Assignment Tab ends here*/}
  //                   {/*Invoice Details Tab starts here*/}
  //                   <div className="tab-pane fade" id="invoice-details" role="tabpanel">
  //                     <div className="row">
  //                       <div className="col-md-12 mt-3 mb-2">
  //                         <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
  //                           <h5 className="font-bold font-18 color-black">
  //                             Invoice History (5)
  //                           </h5>
  //                           <div className="d-md-flex d-block gap-3 flex-wrap align-items-center w-md-100">
  //                             <div className="d-flex gap-3 mb-md-0 mb-3 align-items-center">
  //                               <div className="input-group input-search flex-nowrap">
  //                                 <input
  //                                   type="search"
  //                                   className="form-control border-end-0 font-14 font-regular w-auto"
  //                                   placeholder="Search"
  //                                 />
  //                                 <button
  //                                   className="btn border-start-0 d-flex align-items-center"
  //                                   type="button"
  //                                 >
  //                                   <img
  //                                     src="/images/search.svg"
  //                                     alt="search"
  //                                     className="d-block "
  //                                   />
  //                                 </button>
  //                               </div>
  //                             </div>
  //                             <button className="btn mb-md-0 mb-3 primary-outline-btn w-md-100 font-14 font-semibold text-nowrap">
  //                               Generate Payment Link
  //                             </button>
  //                             <button className="btn mb-md-0 mb-3 primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center">
  //                               <img
  //                                 src="/images/add-plus-icon.svg"
  //                                 alt="add-plus-icon"
  //                               />
  //                               New Invoice
  //                             </button>
  //                           </div>
  //                           {/*Grid starts here*/}
  //                           <div className="table-responsive theme-table bg-white d-inline-block w-100">
  //                             <table className="table table-borderless table-shadow mb-0">
  //                               <thead>
  //                                 <tr>
  //                                   <th className="text-center pe-1" />
  //                                   <th className="text-nowrap">
  //                                     Invoice #
  //                                     <span className="ms-1 cursor-pointer">
  //                                       <img
  //                                         src="/images/sort-arrow-down.svg"
  //                                         alt="sort-arrow-down"
  //                                       />
  //                                     </span>
  //                                   </th>
  //                                   <th className="text-nowrap">
  //                                     Payment Status
  //                                     <span className="ms-1 cursor-pointer">
  //                                       <img
  //                                         src="/images/sort-arrow-down.svg"
  //                                         alt="sort-arrow-down"
  //                                       />
  //                                     </span>
  //                                   </th>
  //                                   <th className="text-nowrap">
  //                                     Environment Type
  //                                     <span className="ms-1 cursor-pointer">
  //                                       <img
  //                                         src="/images/sort-arrow-up.svg"
  //                                         alt="sort-arrow-up"
  //                                       />
  //                                     </span>
  //                                   </th>
  //                                   <th className="text-nowrap">
  //                                     Plan Type
  //                                     <span className="ms-1 cursor-pointer">
  //                                       <img
  //                                         src="/images/sort-arrow-down.svg"
  //                                         alt="sort-arrow-down"
  //                                       />
  //                                     </span>
  //                                   </th>
  //                                   <th className="text-nowrap">
  //                                     Purchase Type
  //                                     <span className="ms-1 cursor-pointer">
  //                                       <img
  //                                         src="/images/sort-arrow-down.svg"
  //                                         alt="sort-arrow-down"
  //                                       />
  //                                     </span>
  //                                   </th>
  //                                   <th className="text-nowrap">
  //                                     Payment Date
  //                                     <span className="ms-1 cursor-pointer">
  //                                       <img
  //                                         src="/images/sort-arrow-down.svg"
  //                                         alt="sort-arrow-down"
  //                                       />
  //                                     </span>
  //                                   </th>
  //                                   <th className="text-nowrap text-end">
  //                                     Amount
  //                                     <span className="ms-1 cursor-pointer">
  //                                       <img
  //                                         src="/images/sort-arrow-down.svg"
  //                                         alt="sort-arrow-down"
  //                                       />
  //                                     </span>
  //                                   </th>
  //                                   <th className="text-nowrap text-center">
  //                                     Actions
  //                                     <span className="ms-1 cursor-pointer">
  //                                       <img
  //                                         src="/images/sort-arrow-down.svg"
  //                                         alt="sort-arrow-down"
  //                                       />
  //                                     </span>
  //                                   </th>
  //                                 </tr>
  //                               </thead>
  //                               <tbody>
  //                                 <tr>
  //                                   <td className="text-center pe-1">
  //                                     <input
  //                                       className="form-check-input cus-check-input"
  //                                       type="checkbox"
  //                                     />
  //                                   </td>
  //                                   <td className="text-nowrap">20220810-023-024</td>
  //                                   <td>
  //                                     <span className="text-nowrap">
  //                                       <span className="table-status success d-inline-block me-2 " />
  //                                       Success
  //                                     </span>
  //                                   </td>
  //                                   <td>Tenant</td>
  //                                   <td>Premium</td>
  //                                   <td>User Based</td>
  //                                   <td>July 01, 2021</td>
  //                                   <td className="text-end">$2,700</td>
  //                                   <td className="text-center">
  //                                     <span className="action-drop-down ">
  //                                       <img
  //                                         src="/images/keb-menu-icon.svg"
  //                                         alt="keb-menu-icon"
  //                                         data-bs-toggle="dropdown"
  //                                         className="p-2 action-icon rounded-3"
  //                                       />
  //                                       <span className="dropdown-menu font-regular font-14 p-1">
  //                                         <span className="d-flex gap-2 px-3 py-2 list">
  //                                           <img src="/images/edit-icon.svg" alt="edi" />
  //                                           Edit
  //                                         </span>
  //                                         <span className="d-flex gap-2 px-3 py-2 list">
  //                                           <img
  //                                             src="/images/download-icon.svg"
  //                                             alt="dowload"
  //                                           />
  //                                           Download
  //                                         </span>
  //                                         <span className="d-flex gap-2 px-3 py-2 list">
  //                                           <img
  //                                             src="/images/red-delete-icon.svg"
  //                                             alt="Delete"
  //                                           />
  //                                           Delete
  //                                         </span>
  //                                       </span>
  //                                     </span>
  //                                   </td>
  //                                 </tr>
  //                                 <tr>
  //                                   <td className="text-center pe-1">
  //                                     <input
  //                                       className="form-check-input cus-check-input"
  //                                       type="checkbox"
  //                                     />
  //                                   </td>
  //                                   <td className="text-nowrap">20220810-023-024</td>
  //                                   <td>
  //                                     <span className="text-nowrap">
  //                                       <span className="table-status danger d-inline-block me-2 " />
  //                                       Failure
  //                                     </span>
  //                                   </td>
  //                                   <td>Tenant</td>
  //                                   <td>Pro</td>
  //                                   <td>Resource Utilization &amp; User License</td>
  //                                   <td>Nov 16, 2020</td>
  //                                   <td className="text-end">$10,040</td>
  //                                   <td className="text-center">
  //                                     <span className="action-drop-down ">
  //                                       <img
  //                                         src="/images/keb-menu-icon.svg"
  //                                         alt="keb-menu-icon"
  //                                         data-bs-toggle="dropdown"
  //                                         className="p-2 action-icon rounded-3"
  //                                       />
  //                                       <span className="dropdown-menu font-regular font-14 p-1">
  //                                         <span className="d-flex gap-2 px-3 py-2 list">
  //                                           <img src="/images/edit-icon.svg" alt="edi" />
  //                                           Edit
  //                                         </span>
  //                                         <span className="d-flex gap-2 px-3 py-2 list">
  //                                           <img
  //                                             src="/images/download-icon.svg"
  //                                             alt="dowload"
  //                                           />
  //                                           Download
  //                                         </span>
  //                                         <span className="d-flex gap-2 px-3 py-2 list">
  //                                           <img
  //                                             src="/images/red-delete-icon.svg"
  //                                             alt="Delete"
  //                                           />
  //                                           Delete
  //                                         </span>
  //                                       </span>
  //                                     </span>
  //                                   </td>
  //                                 </tr>
  //                                 <tr>
  //                                   <td className="text-center pe-1">
  //                                     <input
  //                                       className="form-check-input cus-check-input"
  //                                       type="checkbox"
  //                                     />
  //                                   </td>
  //                                   <td className="text-nowrap">20220810-023-024</td>
  //                                   <td>
  //                                     <span className="text-nowrap">
  //                                       <span className="table-status draft d-inline-block me-2 " />
  //                                       Queue
  //                                     </span>
  //                                   </td>
  //                                   <td>Tenant</td>
  //                                   <td>Elite</td>
  //                                   <td>User Based</td>
  //                                   <td>Jan 05, 2020</td>
  //                                   <td className="text-end">$4,500</td>
  //                                   <td className="text-center">
  //                                     <span className="action-drop-down ">
  //                                       <img
  //                                         src="/images/keb-menu-icon.svg"
  //                                         alt="keb-menu-icon"
  //                                         data-bs-toggle="dropdown"
  //                                         className="p-2 action-icon rounded-3"
  //                                       />
  //                                       <span className="dropdown-menu font-regular font-14 p-1">
  //                                         <span className="d-flex gap-2 px-3 py-2 list">
  //                                           <img src="/images/edit-icon.svg" alt="edi" />
  //                                           Edit
  //                                         </span>
  //                                         <span className="d-flex gap-2 px-3 py-2 list">
  //                                           <img
  //                                             src="/images/download-icon.svg"
  //                                             alt="dowload"
  //                                           />
  //                                           Download
  //                                         </span>
  //                                         <span className="d-flex gap-2 px-3 py-2 list">
  //                                           <img
  //                                             src="/images/red-delete-icon.svg"
  //                                             alt="Delete"
  //                                           />
  //                                           Delete
  //                                         </span>
  //                                       </span>
  //                                     </span>
  //                                   </td>
  //                                 </tr>
  //                                 <tr>
  //                                   <td className="text-center pe-1">
  //                                     <input
  //                                       className="form-check-input cus-check-input"
  //                                       type="checkbox"
  //                                     />
  //                                   </td>
  //                                   <td className="text-nowrap">20220810-023-024</td>
  //                                   <td>
  //                                     <span className="text-nowrap">
  //                                       <span className="table-status success d-inline-block me-2 " />
  //                                       Success
  //                                     </span>
  //                                   </td>
  //                                   <td>Tenant</td>
  //                                   <td>Premium</td>
  //                                   <td>User Based</td>
  //                                   <td>July 01, 2021</td>
  //                                   <td className="text-end">$2,700</td>
  //                                   <td className="text-center">
  //                                     <span className="action-drop-down ">
  //                                       <img
  //                                         src="/images/keb-menu-icon.svg"
  //                                         alt="keb-menu-icon"
  //                                         data-bs-toggle="dropdown"
  //                                         className="p-2 action-icon rounded-3"
  //                                       />
  //                                       <span className="dropdown-menu font-regular font-14 p-1">
  //                                         <span className="d-flex gap-2 px-3 py-2 list">
  //                                           <img src="/images/edit-icon.svg" alt="edi" />
  //                                           Edit
  //                                         </span>
  //                                         <span className="d-flex gap-2 px-3 py-2 list">
  //                                           <img
  //                                             src="/images/download-icon.svg"
  //                                             alt="dowload"
  //                                           />
  //                                           Download
  //                                         </span>
  //                                         <span className="d-flex gap-2 px-3 py-2 list">
  //                                           <img
  //                                             src="/images/red-delete-icon.svg"
  //                                             alt="Delete"
  //                                           />
  //                                           Delete
  //                                         </span>
  //                                       </span>
  //                                     </span>
  //                                   </td>
  //                                 </tr>
  //                               </tbody>
  //                             </table>
  //                           </div>
  //                           {/*Grid ends here*/}
  //                         </div>
  //                       </div>
  //                     </div>
  //                   </div>
  //                   {/*Invoice Details Tab ends here*/}
  //                   {/*Audit History Tab starts here*/}
  //                   <div className="tab-pane fade" id="audit-history" role="tabpanel">
  //                     <div className="row">
  //                       <div className="col-md-12 mt-3 mb-2">
  //                         <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
  //                           <h5 className="font-bold font-18 color-black">
  //                             Audit History (4)
  //                           </h5>
  //                           <div className="d-md-flex d-block gap-3 flex-wrap align-items-center w-md-100">
  //                             <div className="d-flex gap-3 mb-md-0 mb-2 align-items-center">
  //                               <div className="input-group input-search flex-nowrap">
  //                                 <input
  //                                   type="search"
  //                                   className="form-control border-end-0 font-14 font-regular w-auto"
  //                                   placeholder="Search"
  //                                 />
  //                                 <button
  //                                   className="btn border-start-0 d-flex align-items-center"
  //                                   type="button"
  //                                 >
  //                                   <img
  //                                     src="/images/search.svg"
  //                                     alt="search"
  //                                     className="d-block "
  //                                   />
  //                                 </button>
  //                               </div>
  //                             </div>
  //                           </div>
  //                         </div>
  //                         {/*Grid starts here*/}
  //                         <div className="table-responsive theme-table bg-white ">
  //                           <table className="table table-borderless mb-0">
  //                             <thead>
  //                               <tr>
  //                                 <th className="text-nowrap">
  //                                   Name
  //                                   <span className="ms-1 cursor-pointer">
  //                                     <img
  //                                       src="/images/sort-arrow-down.svg"
  //                                       alt="sort-arrow-down"
  //                                     />
  //                                   </span>
  //                                 </th>
  //                                 <th className="text-nowrap">
  //                                   Time Stamp
  //                                   <span className="ms-1 cursor-pointer">
  //                                     <img
  //                                       src="/images/sort-arrow-up.svg"
  //                                       alt="sort-arrow-up"
  //                                     />
  //                                   </span>
  //                                 </th>
  //                                 <th className="text-nowrap">
  //                                   Log
  //                                   <span className="ms-1 cursor-pointer">
  //                                     <img
  //                                       src="/images/sort-arrow-down.svg"
  //                                       alt="sort-arrow-down"
  //                                     />
  //                                   </span>
  //                                 </th>
  //                               </tr>
  //                             </thead>
  //                             <tbody>
  //                               <tr>
  //                                 <td className="text-nowrap">Luke Domnic</td>
  //                                 <td className="text-nowrap">06/03/2023 7:30AM</td>
  //                                 <td className="text-nowrap">Visited User Mapping</td>
  //                               </tr>
  //                               <tr>
  //                                 <td className="text-nowrap">Dwyane Wade</td>
  //                                 <td className="text-nowrap">06/01/2023 9:45AM</td>
  //                                 <td className="text-nowrap">Done Delta Migration</td>
  //                               </tr>
  //                               <tr>
  //                                 <td className="text-nowrap">James Junior</td>
  //                                 <td className="text-nowrap">05/27/2023 4:20PM</td>
  //                                 <td className="text-nowrap">
  //                                   Purchased Resource Utilization
  //                                 </td>
  //                               </tr>
  //                               <tr>
  //                                 <td className="text-nowrap">Robert Brown</td>
  //                                 <td className="text-nowrap">05/23/2023 12:55AM</td>
  //                                 <td className="text-nowrap">Done Delta Migration</td>
  //                               </tr>
  //                             </tbody>
  //                           </table>
  //                         </div>
  //                         {/*Grid ends here*/}
  //                       </div>
  //                     </div>
  //                   </div>
  //                   {/*Audit History Tab ends here*/}
  //                 </div>
  //                 {/*Tab Inner ends here*/}
  //               </div>
  //             </div>
  //           </div>
  //         }
  //       </div>
  //       {alertPopup === true ? (
  //         <div
  //           className="modal fade show"
  //           id="Alert-popup"
  //           data-bs-backdrop="static"
  //           data-bs-keyboard="false"
  //           tabIndex={-1}
  //           aria-labelledby="Alert-popupLabel"
  //           aria-hidden="true"
  //           style={{
  //             display: "block",
  //             backgroundColor: "rgba(0,0,0,0.6)",
  //           }}
  //         >
  //           <div className="modal-dialog modal-dialog-centered">
  //             <div className="modal-content popup-brd-radius">
  //               <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
  //                 <span className="position-absolute">
  //                   <img
  //                     src="/images/failure-popup-icon.svg"
  //                     alt="Alert-popup-icon"
  //                   />
  //                 </span>
  //               </div>
  //               <div className="modal-body border-0 text-center">
  //                 <h5
  //                   className="modal-title mb-3 primary-header"
  //                   id="Alert-popupLabel"
  //                 >
  //                   Alert
  //                 </h5>
  //                 <p className="primary-data font-16">{popupmessage || 'Something went wrong , Please try again later !'}</p>
  //               </div>
  //               <div className="modal-footer border-0 pb-4 justify-content-center">
  //                         <button
  //                           type="button"
  //                           className="btn primary-btn font-14 px-4 font-semibold"
  //                           data-bs-dismiss="modal"
  //                           onClick={ () => setAlertPopup(false)}
  //                         >
  //                           Ok
  //                         </button>
  //                       </div>
  //             </div>
  //           </div>
  //         </div>
  //       ) : null}

  //       {successPopup === true ? (
  //         <div className="modal fade show " id="Success-popup"
  //           aria-labelledby="Success-popupLabel" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}>
  //           <div className="modal-dialog modal-dialog-centered">
  //             <div className="modal-content popup-brd-radius">
  //               <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
  //                 <span className="position-absolute">
  //                   <img src="images/success-popup-icon.svg" alt="success-popup-icon" />
  //                 </span>
  //               </div>
  //               <div className="modal-body border-0 text-center">
  //                 <h5 className="modal-title mb-3 primary-header" id="Success-popupLabel">
  //                   Success !
  //                 </h5>
  //                 <p className="primary-data">{successPopupType == 'passwordUpdate' ? 'Password Updated Successfully' : successPopupType == 'insertuser' ? 'User Added Successfully' : 'Account Information Updated Successfully'}</p>
  //               </div>
  //               <div className="modal-footer border-0 justify-content-center pb-4">
  //                 <button
  //                   type="button"
  //                   className="btn primary-btn font-14 px-4 font-semibold"
  //                   data-bs-dismiss="modal"
  //                   onClick={() => {
  //                     if (successPopupType == 'passwordUpdate') {
  //                       sessionStorage.clear();
  //                       setSuccessPopup(false);

  //                     }
  //                     setSuccessPopup(false);
  //                   }}                            >
  //                   Ok
  //                 </button>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       ) : null}
  //       {loader && <Loader />}

  //     </>

  //   );

  // };

  // export default AdminClient;

  //PS_01, PS_02, PS_03, PS_04, PS_05, PS_06, PS_07, PS_08, PS_09, PS_10, PS_11, PS_12



  //Return Statement:
  return (
    <> 
      <div className="container-fluid container-height">
      {loader && <Loader />}
        {location?.state?.action == 'NewClient' ?
          <div className="row">
            <div className="col-md-12">
              <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
                {/* bread crumb starts here */}
                <ul className="breadcrumb-item mb-4 ps-0">
                  <li>
                    <a onClick={() => history.push('/manageClient')} className="pe-0">
                      Manage Clients
                    </a>
                  </li>
                  <li className="pe-2">New Client</li>
                </ul>
                {/* bread crumb ends here */}
                <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap gap-3 ">
                  <h5 className="primary-header font-20 font-bold mb-0 d-flex align-items-center">                     <a onClick={() => history.push('/manageClient')}>
                    <img
                      src="/images/head-back-arrow.svg"
                      alt="back-arrow"
                      width={19}
                      className="me-2"
                    />
                  </a>
                    New Client
                  </h5>
                </div>
                {/*forms starts here*/}
                <div className="row mb-3">
                  <div className="col-md-12 mt-3 mb-2">
                    <h5 className="font-bold font-20">Account Information</h5>
                  </div>
                  <div className="col-md-4 mb-4">
                    <label
                      className="form-label font-14 font-semibold label-color label-color"
                      htmlFor="user-name"
                    >
                      User Name <span className="mandatory-color">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control custom-form font-14 font-regular py-2"
                      id="user-name"
                      name='user-name'
                      placeholder="Enter User Name"
                      value={clientData.userName}
                      onChange={(e) =>
                        handleFieldValidation(
                          e.target.id,
                          e.target.value,
                          "new"
                        )
                      }
                    />
                    {errorMessages.errUsername && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 "> {errorMessages.errUsername}</span>}

                  </div>
                  <div className="col-md-4 mb-4">
                    <label
                      className="form-label font-14 font-semibold label-color label-color"
                      htmlFor="company-name"
                    >
                      Company Name <span className="mandatory-color">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control custom-form font-14 font-regular py-2"
                      id="company-name"
                      name='companyName'
                      placeholder="Enter Company Name"
                      value={clientData?.companyName}
                      onChange={(e) =>
                        handleFieldValidation(
                          e.target.id,
                          e.target.value,
                          "new"
                        )
                      }
                    />
                    {errorMessages.errCompanyName && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{errorMessages.errCompanyName}</span>}

                  </div>
                  <div className="col-md-4 mb-4">
                    <label
                      className="form-label font-14 font-semibold label-color label-color"
                      htmlFor="email-address"
                    >
                      Email Address <span className="mandatory-color">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control custom-form font-14 font-regular py-2"
                      id="email-address"
                      placeholder="Enter Email Address"
                      value={clientData?.email}
                      name='email'
                      onChange={(e) =>
                        handleFieldValidation(
                          e.target.id,
                          e.target.value,
                          "new"
                        )
                      }
                    />
                    {errorMessages.errEmail && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{errorMessages.errEmail}</span>}

                  </div>
                  <div className="col-md-4 mb-4">
                    <label
                      className="form-label font-14 font-semibold label-color label-color"
                      htmlFor="ph-no"
                    >
                      Phone Number <span className="mandatory-color">*</span>
                    </label>
                    <div className="input-group mb-3 input-group mb-3 form-control custom-form rounded p-0 phone-group">
                      <Select
                        options={countryOptions}
                        onChange={handleCountryChange}
                        value={countryOptions.find((option: OptionType) => option.value === clientData.phonecode)}
                      />
                      <input
                        type="text"
                        className="form-control custom-form font-14 font-regular py-2"
                        id="ph-no"
                        onChange={(e) =>
                          handleFieldValidation(
                            e.target.id,
                            e.target.value,
                            "new"
                          )
                        }
                        value={clientData?.phone}
                      />
                      {errorMessages.errPhone && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{errorMessages.errPhone}</span>}

                    </div>
                  </div>
                  <div className="col-md-4 mb-4">
                    <label className="form-label font-14 font-semibold label-color label-color w-100">
                      Status <span className="mandatory-color">*</span>
                    </label>
                    <div className="form-check form-check-inline mt-2">
                      <input
                        className="form-check-input custom-radio-input"
                        type="radio"
                        name="status"
                        id="Active"
                        defaultChecked
                        checked={clientData.status === 'Active'}
                        onChange={(e) => handleFieldValidation("status", e.target.value, "new")}
                        value='Active'
                      />
                      <label className="form-check-label font-14 font-regular" htmlFor="Active">
                        Active
                      </label>
                    </div>
                    <div className="form-check form-check-inline mt-2">
                      <input
                        className="form-check-input custom-radio-input"
                        type="radio"
                        name="status"
                        id="InActive"
                        checked={clientData.status === 'InActive'}
                        onChange={(e) => handleFieldValidation('status', e.target.value, "new")}
                        value='InActive'
                      />
                      <label className="form-check-label font-14 font-regular" htmlFor="InActive">
                        Inactive
                      </label>
                    </div>
                    {errorMessages.status && <span className="font-12 font-semibold mandatory-color mt-2 mb-0">{errorMessages.status}</span>}
                  </div>

                  <div className="col-md-4 mb-4">
                    <label className="form-label font-14 font-semibold label-color label-color w-100">
                      Registration Type <span className="mandatory-color">*</span>
                    </label>
                    <div className="form-check form-check-inline mt-2">
                      <input
                        className="form-check-input custom-radio-input"
                        type="radio"
                        name="regtype"
                        id="SSO"
                        defaultChecked
                        checked={clientData?.registrationType.toLowerCase().trim() === 'sso'}
                        onChange={(e) => handleFieldValidation('registrationType', e.target.value, "new")}
                        value='SSO'
                      />
                      <label className="form-check-label font-14 font-regular" htmlFor="SSO">
                        SSO
                      </label>
                    </div>
                    <div className="form-check form-check-inline mt-2">
                      <input
                        className="form-check-input custom-radio-input"
                        type="radio"
                        name="regtype"
                        id="Email"
                        checked={clientData?.registrationType.toLowerCase().trim() === 'email'}
                        value={clientData?.email}
                        onChange={(e) => handleFieldValidation('registrationType','email', "new")}
                      />
                      <label className="form-check-label font-14 font-regular" htmlFor="Email">
                        Email
                      </label>
                    </div>
                    {errorMessages.errRegistrationType && <span className="font-12 font-semibold mandatory-color mt-2 mb-0">{errorMessages.errRegistrationType}</span>}
                  </div>

                  {/* <div className="col-md-4 mb-4">
                    <label className="form-label font-14 font-semibold label-color label-color w-100">
                      Environment Type <span className="mandatory-color">*</span>
                    </label>
                    <div className="form-check form-check-inline mt-2">
                      <input
                        className="form-check-input custom-radio-input"
                        type="radio"
                        name="envtype"
                        id="Tenant"
                        checked={clientData?.authenticationType === 'Tenant'}
                        onChange={(e) => handleFieldValidation('environmenttype', e.target.value, "new")}
                        value='Tenant'
                      />
                      <label className="form-check-label font-14 font-regular" htmlFor="Tenant">
                        Tenant
                      </label>
                    </div>
                    <div className="form-check form-check-inline mt-2">
                      <input
                        className="form-check-input custom-radio-input"
                        type="radio"
                        name="envtype"
                        id="g-suite"
                        disabled={true}
                        checked={clientData?.authenticationType === 'g-suite'}
                        onChange={(e) => handleFieldValidation("environmenttype", e.target.value, "new")}
                        value='g-suite'
                      />
                      <label className="form-check-label font-14 font-regular" htmlFor="g-suite">
                        Gsuite
                      </label>
                    </div>
                    {errorMessages.errAuthenticationType && <span className="font-12 font-semibold mandatory-color mt-2 mb-0">{errorMessages.errAuthenticationType}</span>}
                  </div> */}

                  <div className="col-md-12">
                    <div className="d-flex justify-content-end align-items-center py-4">
                      <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4" onClick={() => history.push('/Admin/ManageClients')}>
                        Cancel
                      </button>
                      <button className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4" onClick={() => handleClientdata('new')} disabled={!enable}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
                {/*forms ends here*/}
              </div>
            </div>
          </div> :
          <div className="container-fluid container-height">
            <div className="row">
              <div className="col-md-12">
                <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
                  <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap gap-3 ">
                    <h5 className="primary-header font-20 font-bold mb-0 d-flex align-items-center">
                      <a href="#">
                        <img
                          src="/images/head-back-arrow.svg"
                          alt="back-arrow"
                          width={19}
                          className="me-2"
                          onClick={() => history.push('/Admin/ManageClients')}
                        />
                      </a>
                      Zeb
                    </h5>
                  </div>




                  <div className="col-md-12 d-lg-flex d-sm-block gap-3">
                    <div className="col-md-12 col-lg-3 mb-5">
                      <div className="user-details py-3 px-4 grey-border">
                        <div className="text-end mb-4">
                          <img
                            src="/images/edit-icon.svg"
                            alt="icon-icon"
                            className="profile-edit-icon cursor-pointer"
                            onClick={() => setEditPopup(true)}
                          />
                        </div>
                        <div className="d-sm-flex d-lg-block justify-content-around">
                          <div className="text-center mb-5">
                            <span className="user-profile mb-1 d-flex align-items-center justify-content-center font-14 font-semibold profile-legend-name success m-auto">
                              {displayClientData?.userName[0]} 
                              {/* {role.toLowerCase() == 'primaryadmin' || role.toLowerCase() == 'secondaryadmin' || role.toLowerCase() == 'adminviewer' ? adminName[0] : clientName[0]} */}
                            </span>
                            <h5 className="font-24 font-semibold mb-2">
                              {/* {role.toLowerCase() == 'primaryadmin' || role.toLowerCase() == 'secondaryadmin' || role.toLowerCase() == 'adminviewer' ? adminName : clientName} */}
                              {displayClientData?.userName}
                            </h5>
                            <p className="font-16 font-regular profile-grey">
                              {/* {role.toLowerCase() == 'primaryadmin' || role.toLowerCase() == 'secondaryadmin' || role.toLowerCase() == 'adminviewer' ? adminMailId : mailId} */}
                              {displayClientData?.email}
                            </p>

                          </div>
                          <div>
                            <p className="font-14 font-regular profile-grey mb-2">
                              Company Name
                            </p>
                            <p className="font-13 font-semibold mb-4">
                              {displayClientData?.companyName}
                            </p>
                            {role.toLowerCase() == "primaryadmin" ||
                              role.toLowerCase() == "secondaryadmin" ||
                              role.toLowerCase() == "adminviewer" || role.toLowerCase() == "secondaryuser" || role.toLowerCase() == "viewer" || role.toLowerCase() == "migrationmanager" ? null : (
                              <>

                              </>
                            )}

                            <p className="font-14 font-regular profile-grey mb-2">
                              Phone
                            </p>
                            <p className="font-13 font-semibold mb-4">
                              {displayClientData?.phone}
                            </p>
                            <p className="font-14 font-regular profile-grey mb-2">
                              Registration Type
                            </p>
                            <p className="font-13 font-semibold mb-4">
                             {editClientData?.registrationType} 
                            </p>
                            <p className="font-14 font-regular profile-grey mb-2">
                              Status
                            </p>
                            <p className="font-13 font-semibold mb-4">
                              {editClientData?.status}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>



                    {/*Tab Inner starts here*/}
                    <div className="tab-content" id="pills-tabContent">
                      {/*Tabs starts here*/}
                      <ul className="nav nav-pills mb-4 cust-padding-20" id="pills-tab" role="tablist"                       >
                        <li className="nav-item custom-bg" role="presentation">
                          <button className="nav-link theme-tab font-16" data-bs-toggle="pill" data-bs-target="#license-assignment" type="button" role="tab" aria-selected="true"                          >
                            License
                          </button>
                        </li>
                      </ul>
                      {/*Tabs ends here*/}
                      {/*Account Information Tab starts here*/}
                      <div className="tab-pane fade show active cust-padding-20" id="accountInformation" role="tabpanel" >
                        <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                          <h5 className="font-bold font-18 color-black">
                            Available License
                          </h5>
                          {/*Assign License & Revoke License Button starts here*/}
                          <div className='d-flex gap-3 w-md-100 flex-wrap'>
                            <button className="btn primary-outline-btn w-md-100 font-14 font-semibold text-nowrap" onClick={() => { history.push('AssignRevokeLicense', { action: 'revoke', id: clientId }); }}                               >
                              Revoke License
                            </button>
                            <button className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4" onClick={() => { history.push('AssignRevokeLicense', { action: 'assign', id: clientId }); }}                               >
                              Assign License
                            </button>
                          </div>
                          {/*Assign License & Revoke License Button ends here*/}
                        </div>

                        <div className="col-md-12 mb-3 grey-border license-container px-4 py-3 ">
                          <div className="d-flex gap-2 align-items-center pb-3 bottom-border">
                            <img
                              src="/images/tenant-license.svg"
                              alt="slack-logo"
                              className=""
                            />
                            <h5 className="font-16 font-bold m-0">
                              Tenant License
                            </h5>
                            <button className="d-flex align-items-center scroll-btn justify-content-end">
                              <img
                                src="/images/right-arrow.svg"
                                alt="right-arrow"
                              />
                            </button>
                          </div>
                          <div className="d-flex gap-5 pt-3 overflow-hidden">
                            <div>
                              <p className="font-14 font-regular profile-grey mb-2">
                                Plan Expires On
                              </p>
                              <p className="font-13 font-semibold mb-0">
                                May 15, 2024
                              </p>
                            </div>
                            <div>
                              <p className="font-14 font-regular profile-grey mb-2">
                                Parallel Instance
                              </p>
                              <p className="font-13 font-semibold mb-0">3/5</p>
                            </div>
                            <div>
                              <p className="font-14 font-regular profile-grey mb-2">
                                Assessment
                              </p>
                              <p className="font-13 font-semibold mb-0">3/5</p>
                            </div>
                            <div>
                              <p className="font-14 font-regular profile-grey mb-2">
                                Groups
                              </p>
                              <p className="font-13 font-semibold mb-0">7/30</p>
                            </div>
                            <div>
                              <p className="font-14 font-regular profile-grey mb-2">
                                User Mailbox
                              </p>
                              <p className="font-13 font-semibold mb-0">7/30</p>
                            </div>
                            <div>
                              <p className="font-14 font-regular profile-grey mb-2">
                                Shared Mailbox
                              </p>
                              <p className="font-13 font-semibold mb-0">7/30</p>
                            </div>
                            <div>
                              <p className="font-14 font-regular profile-grey mb-2">
                                Rooms &amp; Equipment's
                              </p>
                              <p className="font-13 font-semibold mb-0">7/30</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 mb-3 grey-border license-container px-4 py-3 ">
                          <div className="d-flex gap-2 align-items-center pb-3 bottom-border">
                            <img
                              src="/images/gsuit-license.svg"
                              alt="slack-logo"
                              className=""
                            />
                            <h5 className="font-16 font-bold m-0">
                              G-Workspace License
                            </h5>
                            <button className="d-flex align-items-center scroll-btn justify-content-end">
                              <img
                                src="/images/right-arrow.svg"
                                alt="right-arrow"
                              />
                            </button>
                          </div>
                          <div className="d-flex gap-5 pt-3 overflow-hidden">
                            <div>
                              <p className="font-14 font-regular profile-grey mb-2">
                                Plan Expires On
                              </p>
                              <p className="font-13 font-semibold mb-0">
                                May 15, 2024
                              </p>
                            </div>
                            <div>
                              <p className="font-14 font-regular profile-grey mb-2">
                                Parallel Instance
                              </p>
                              <p className="font-13 font-semibold mb-0">3/5</p>
                            </div>
                            <div>
                              <p className="font-14 font-regular profile-grey mb-2">
                                Assessment
                              </p>
                              <p className="font-13 font-semibold mb-0">3/5</p>
                            </div>
                            <div>
                              <p className="font-14 font-regular profile-grey mb-2">
                                Gmail
                              </p>
                              <p className="font-13 font-semibold mb-0">7/30</p>
                            </div>
                            <div>
                              <p className="font-14 font-regular profile-grey mb-2">
                                My Drive
                              </p>
                              <p className="font-13 font-semibold mb-0">7/30</p>
                            </div>
                            <div>
                              <p className="font-14 font-regular profile-grey mb-2">
                                Shared Drive
                              </p>
                              <p className="font-13 font-semibold mb-0">7/30</p>
                            </div>
                            <div>
                              <p className="font-14 font-regular profile-grey mb-2">
                                Groups
                              </p>
                              <p className="font-13 font-semibold mb-0">7/30</p>
                            </div>
                            <div>
                              <p className="font-14 font-regular profile-grey mb-2">
                                Sites
                              </p>
                              <p className="font-13 font-semibold mb-0">7/30</p>
                            </div>
                            <div>
                              <p className="font-14 font-regular profile-grey mb-2">
                                Chats
                              </p>
                              <p className="font-13 font-semibold mb-0">7/30</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*Account Information Tab ends here*/}
                      {/*Password Reset Tab starts here*/}
                      {/* <div
                          className="tab-pane fade  cust-padding-20"
                          id="password-reset"
                          role="tabpanel"
                        >
                          <div className="row">
                            <div className="col-md-12 mb-2">
                              <h5 className="font-bold font-18 color-black">
                                Password Reset
                              </h5>
                            </div>
                            <div className="col-md-4 mb-4">
                              <label
                                className="form-label font-14 font-semibold label-color label-color"
                                htmlFor="email"
                              >
                                Email Address{" "}
                                <span className="mandatory-color">*</span>
                              </label>
                              <input
                                disabled
                                type="text"
                                className="form-control custom-form font-14 font-regular py-2"
                                id="email"
                                placeholder="Enter Email Address"
                                defaultValue={AccountInfo.mail}
                                onChange={(e) =>
                                  handleFieldValidation(
                                    e.target.id,
                                    e.target.value,
                                    "password"
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-4 mb-4">
                              <label
                                className="form-label font-14 font-semibold label-color label-color"
                                htmlFor="old-password"
                              >
                                Old Password{" "}
                                <span className="mandatory-color">*</span>
                              </label>
                              <div className="position-relative">
                                <input
                                  type={oldhide ? 'text' : 'password'} // Changed variable name and simplified conditional statement
                                  className="form-control password-eye-hide custom-form font-14 font-regular py-2"
                                  id="old-password"
                                  placeholder="Enter Old Password"
                                  value={password.oldpassword}
                                  onChange={(e) => {
                                    //console.log("Old Password Input Changed:", e.target.value);
                                    handleFieldValidation(e.target.id, e.target.value, "password"); // Changed to pass value directly
                                  }}
                                />
                                {oldhide ? ( // Simplified conditional rendering
                                  <img
                                    className="position-absolute custom-open-eye-postion"
                                    src="/images/password-eye-open.svg"
                                    alt="password-icon"
                                    onClick={() => {
                                      //console.log("Hide password clicked");
                                      setoldHide(false); // Changed variable name
                                    }}
                                  />
                                ) : (
                                  <img
                                    className="position-absolute custom-postion"
                                    src="/images/password-icon.svg"
                                    alt="password-icon"
                                    onClick={() => {
                                      //console.log("Show password clicked");
                                      setoldHide(true); // Changed variable name
                                    }}
                                  />
                                )}

                                {errpassword.erroldpassword && (
                                  <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                    {errpassword.erroldpassword}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4 mb-4">
                              <label
                                className="form-label font-14 font-semibold label-color label-color"
                                htmlFor="new-password"
                              >
                                New Password{" "}
                                <span className="mandatory-color">*</span> <Tippy
                                  arrow={true}
                                  followCursor={'horizontal'}
                                  plugins={[followCursor]}
                                  placement='top'
                                  content="Password should contain atleast 8-20 characters atleast 1 Special character, atleast 1 Number, atleast 1 Uppercase, atleast 1 Lowercase"
                                >
                                  <img
                                    src="/images/tooltip.svg"
                                    alt="tooltip"
                                    width={11}

                                  />
                                </Tippy>
                              </label>
                              <div className="position-relative">
                                <input
                                  type={hide == true ? 'text' : 'password'}
                                  className="form-control password-eye-hide custom-form font-14 font-regular py-2"
                                  id="new-password"
                                  placeholder="Enter New Password"
                                  value={password.newpassword}
                                  onChange={(e) =>
                                    handleFieldValidation(
                                      e.target.id,
                                      e.target.value,
                                      "password"
                                    )
                                  }

                                />
                                {hide == true ? (<img
                                  className="position-absolute custom-open-eye-postion"
                                  src="/images/password-eye-open.svg"
                                  alt="password-icon"
                                  onClick={() => setHide(false)}
                                />) : (<img
                                  className="position-absolute custom-postion"
                                  src="/images/password-icon.svg"
                                  alt="password-icon"
                                  onClick={() => setHide(true)}
                                />)}
                                {errpassword.errNewpassword && (
                                  <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                    {errpassword.errNewpassword}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-4 mb-4">
                              <label
                                className="form-label font-14 font-semibold label-color label-color"
                                htmlFor="confirm-password"
                              >
                                Confirm Password{" "}
                                <span className="mandatory-color">*</span>
                                <Tippy
                                  arrow={true}
                                  followCursor={'horizontal'}
                                  plugins={[followCursor]}
                                  placement='top'
                                  content="Please re-enter your password to confirm. Ensure it matches the password you entered above."
                                >
                                  <img
                                    src="/images/tooltip.svg"
                                    alt="tooltip"
                                    width={11}

                                  />
                                </Tippy>
                              </label>
                              <div className="position-relative">
                                <input
                                  type={Confirmhide ? 'text' : 'password'}
                                  className="form-control password-eye-hide custom-form font-14 font-regular py-2"
                                  id="confirm-password"
                                  placeholder="Confirm New Password"
                                  value = {password.confirmpassword}
                                  onChange={(e) =>
                                    handleFieldValidation(
                                      e.target.id,
                                      e.target.value,
                                      "password"
                                    )
                                  }
                                />
                                {Confirmhide == true ? <img
                                  className="position-absolute custom-open-eye-postion"
                                  src="/images/password-eye-open.svg"
                                  alt="password-icon"
                                  onClick={() => setconfirmHidee(false)}
                                /> : <img
                                  className="position-absolute custom-postion"
                                  src="/images/password-icon.svg"
                                  alt="password-icon"
                                  onClick={() => setconfirmHidee(true)}
                                />}
                              </div>
                              {errpassword.errconfirmpassword && (
                                <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                  {errpassword.errconfirmpassword}
                                </div>
                              )}
                            </div>
                            <div className="col-md-12 mt-3">
                              <div className="d-flex justify-content-end align-items-center flex-wrap gap-3 py-4">
                                <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center py-2 px-4"
                                  onClick={() => passwordCancelClick()}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4"
                                  onClick={(e) =>
                                    handleActions(e, "password", "submit")
                                  }
                                  disabled={!enable}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      {/*Password Reset Tab ends here*/}
                      {/*Manage Users Tab starts here*/}
                      {/* <div
                          className="tab-pane fade cust-padding-20"
                          id="manage-users"
                          role="tabpanel"
                        >
                          <div className="row">
                            <div className="col-md-12 mb-2">
                              <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                                <h5 className="font-bold font-18 color-black">
                                  Manage Users ({secondaryUsers?.length}) {""}

                                  <Tippy
                                    delay={500}
                                    arrow={true}
                                    followCursor={false}
                                    plugins={[followCursor]}
                                    placement='top'
                                    content="Users with multiple roles can be added: Secondary users can make changes in the application except for adding users. Migration managers can support the migration progress. Viewers can only access the application"
                                  >

                                    <img
                                      src="/images/tooltip.svg"
                                      alt="tooltip"
                                      width={11}

                                    />

                                  </Tippy>
                                </h5>
                                <div className="d-md-flex d-block gap-3 flex-wrap align-items-center w-md-100">
                                  <div className="d-flex gap-3 mb-md-0 mb-3 align-items-center">
                                    <div className="input-group input-search flex-nowrap">
                                      <input
                                        type="search"
                                        className="form-control border-end-0 font-14 font-regular w-auto"
                                        placeholder="Search"
                                        onKeyDown={(event) => {
                                          if (event.key == 'Enter') {
                                            getClientDetails('initial')
                                          }
                                        }}
                                        onChange={handleSearch}
                                      />
                                      <button className="btn border-start-0" type="button" onClick={onSearch}>
                                        <img
                                          src="/images/search.svg"
                                          alt="search"
                                          className="d-block "
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                      {/*Grid starts here*/}
                      {/* <div className="table-responsive theme-table bg-white ">
                                <table className="table table-borderless table-shadow mb-0">
                                  <thead>
                                    <tr>
                                      <th className="text-nowrap">
                                        User Name
                                        <span className="ms-1 cursor-pointer">
                                          <img
                                            src={`/images/sort-arrow-up.svg`}
                                            alt="sort-arrow"
                                            style={{ cursor: "pointer" }}
                                            id={`userName@ASC`}
                                            hidden={
                                              manageUsersSortHide ==
                                                `userName@DESC` || ""
                                                ? false
                                                : true
                                            }
                                            onClick={(e) => {
                                              handleSort(e);
                                            }}
                                          />
                                          <img
                                            src={`/images/sort-arrow-down.svg`}
                                            alt="sort-arrow"
                                            id={`userName@DESC`}
                                            style={{ cursor: "pointer" }}
                                            hidden={
                                              manageUsersSortHide ==
                                                `userName@DESC` || ""
                                                ? true
                                                : false
                                            }
                                            onClick={(e: any) => {
                                              handleSort(e);
                                            }}
                                          />
                                        </span>
                                      </th>
                                      <th className="text-nowrap">
                                        Email Address
                                        <span className="ms-1 cursor-pointer">
                                          <img
                                            src={`/images/sort-arrow-up.svg`}
                                            alt="sort-arrow"
                                            style={{ cursor: "pointer" }}
                                            id={`mailId@ASC`}
                                            hidden={
                                              manageUsersSortHide ==
                                                `mailId@DESC` || ""
                                                ? false
                                                : true
                                            }
                                            onClick={(e: any) => {
                                              handleSort(e);
                                            }}
                                          />
                                          <img
                                            src={`/images/sort-arrow-down.svg`}
                                            alt="sort-arrow"
                                            id={`mailId@DESC`}
                                            style={{ cursor: "pointer" }}
                                            hidden={
                                              manageUsersSortHide ==
                                                `mailId@DESC` || ""
                                                ? true
                                                : false
                                            }
                                            onClick={(e: any) => {
                                              handleSort(e);
                                            }}
                                          />
                                        </span>
                                      </th>
                                      <th className="text-nowrap">
                                        Authentication Type
                                        <span className="ms-1 cursor-pointer">
                                          <img
                                            src={`/images/sort-arrow-up.svg`}
                                            alt="sort-arrow"
                                            style={{ cursor: "pointer" }}
                                            id={`isSSOAuthentication@ASC`}
                                            hidden={
                                              manageUsersSortHide ==
                                                `isSSOAuthentication@DESC` || ""
                                                ? false
                                                : true
                                            }
                                            onClick={(e: any) => {
                                              handleSort(e);
                                            }}
                                          />
                                          <img
                                            src={`/images/sort-arrow-down.svg`}
                                            alt="sort-arrow"
                                            id={`isSSOAuthentication@DESC`}
                                            style={{ cursor: "pointer" }}
                                            hidden={
                                              manageUsersSortHide ==
                                                `isSSOAuthentication@DESC` || ""
                                                ? true
                                                : false
                                            }
                                            onClick={(e: any) => {
                                              handleSort(e);
                                            }}
                                          />
                                        </span>
                                      </th>
                                      <th className="text-nowrap">
                                        Role
                                        <span className="ms-1 cursor-pointer">
                                          <img
                                            src={`/images/sort-arrow-up.svg`}
                                            alt="sort-arrow"
                                            style={{ cursor: "pointer" }}
                                            id={`adminRolesId@ASC`}
                                            hidden={
                                              manageUsersSortHide ==
                                                `adminRolesId@DESC` || ""
                                                ? false
                                                : true
                                            }
                                            onClick={(e) => {
                                              handleSort(e);
                                            }}
                                          />
                                          <img
                                            src={`/images/sort-arrow-down.svg`}
                                            alt="sort-arrow"
                                            id={`adminRolesId@DESC`}
                                            style={{ cursor: "pointer" }}
                                            hidden={
                                              manageUsersSortHide ==
                                                `adminRolesId@DESC` || ""
                                                ? true
                                                : false
                                            }
                                            onClick={(e) => {
                                              handleSort(e);
                                            }}
                                          />
                                        </span>
                                      </th>
                                      <th className="text-nowrap">
                                        Account Status
                                        <span className="ms-1 cursor-pointer">
                                          <img
                                            src={`/images/sort-arrow-up.svg`}
                                            alt="sort-arrow"
                                            style={{ cursor: "pointer" }}
                                            id={`Status@ASC`}
                                            hidden={
                                              manageUsersSortHide ==
                                                `Status@DESC` || ""
                                                ? false
                                                : true
                                            }
                                            onClick={(e) => {
                                              handleSort(e);
                                            }}
                                          />
                                          <img
                                            src={`/images/sort-arrow-down.svg`}
                                            alt="sort-arrow"
                                            id={`Status@DESC`}
                                            style={{ cursor: "pointer" }}
                                            hidden={
                                              manageUsersSortHide ==
                                                `Status@DESC` || ""
                                                ? true
                                                : false
                                            }
                                            onClick={(e) => {
                                              handleSort(e);
                                            }}
                                          />
                                        </span>
                                      </th>
                                      {role.toLowerCase() !== "viewer" ||
                                        role.toLowerCase() !== "adminviewer" ? (
                                        <th className="text-nowrap text-center ">
                                          Action
                                        </th>
                                      ) : null}
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control custom-form font-regular font-14"
                                          placeholder="Enter User Name"
                                          name="userName"
                                          value={!editMode ? secondaryUser.userName : ''}
                                          onChange={(e) => {
                                            {
                                              //console.log(e.target.value);
                                            }

                                            handleFieldValidation(
                                              "userName",
                                              e.target.value,
                                              "manageUser"
                                            );
                                          }}
                                        />

                                        <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                          {errmanageUser.erruserName}
                                        </span>
                                      </td>

                                      <td>
                                        <input
                                          type="text"
                                          // className={`form-control custom-form font-regular font-14 ${(!secondaryUser.email && formSubmitted) || (erremail && formSubmitted) ? 'is-invalid' : ''}`}
                                          className="form-control custom-form font-regular font-14"
                                          placeholder="Enter Email Address"
                                          name="email"
                                          value={
                                            !editMode ? secondaryUser.email : ""
                                          }
                                          onChange={(e) => {
                                            handleFieldValidation(
                                              "email",
                                              e.target.value,
                                              "manageUser"
                                            );
                                          }}
                                        />
                                        <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                          {errmanageUser.errmail}
                                        </span>

                                      </td>
                                      <td>
                                        <select
                                          className="form-control custom-form font-regular font-14"
                                          id="type"
                                          aria-label="Default select example"
                                          name="authenticationType"
                                          value={
                                            !editMode
                                              ? secondaryUser.authenticationType
                                              : ""
                                          }
                                          onChange={(e) => {
                                            handleFieldValidation(
                                              "authenticationType",
                                              e.target.value,
                                              "manageUser"
                                            );
                                          }}
                                        >
                                          <option value="" disabled>
                                            Select
                                          </option>
                                          {authenticationType.map(
                                            (option: any, index: any) => (
                                              <option
                                                key={index}
                                                value={option.authenticationType}
                                              >
                                                {option.authenticationType}
                                              </option>
                                            )
                                          )}
                                        </select>
                                        {/* {formSubmitted && invalidAuthType && (
                                                                    <div className="invalid-feedback">Authentication Type is required.</div>
                                                                )} */}

                      {/* <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                          {errmanageUser.errauthenticationType}
                                        </div>

                                      </td>
                                      <td>
                                        <select
                                          className="form-control custom-form font-regular font-14"
                                          id="type2"
                                          aria-label="Default select example"
                                          name="role"
                                          value={
                                            !editMode ? secondaryUser.role : ""
                                          }
                                          onChange={(e) => {
                                            handleFieldValidation(
                                              "role",
                                              e.target.value,
                                              "manageUser"
                                            );
                                          }}
                                        >
                                          <option value="" disabled>
                                            Select
                                          </option>
                                          {roleData.map(
                                            (option: any, index: any) => (
                                              <option
                                                key={index}
                                                value={option.roleType.replace(/^\w/, (c: any) => c.toUpperCase())}
                                              >
                                                {option.roleType}
                                              </option>
                                            )
                                          )}
                                        </select>
                                        {/* {formSubmitted && invalidRole && !editMode(
                                                                    <div className="invalid-feedback">Role is required.</div>
                                                                )} */}

                      {/* <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">
                                          {errmanageUser.role}
                                        </div> */}

                      {/* </td> */}
                      {/* <td>
                                        <span className="text-nowrap">
                                          <span className="table-status draft d-inline-block me-2 " />
                                          Queue
                                        </span>
                                      </td> */}
                      {/* {role.toLowerCase() !== "viewer" ||
                                        role.toLowerCase() !== "adminviewer" ? (
                                        <td className="text-center">
                                          <span className="d-inline-block">
                                            <button
                                              className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center"
                                              onClick={(e) => handleActions(e, 'manageUser', 'addAccount')}
                                              disabled={!manageUserbutton}
                                            > */}
                      {/* <img
                                                src="/images/blue-plus-icon.svg"
                                                alt="blue-plus-icon"
                                              />
                                            </button>
                                          </span>
                                        </td>
                                      ) : null}
                                    </tr> */}
                      {/* {secondaryUsers?.filter(acc => Object.values(acc).some(value => value !== '')).map((acc: any, index) => (
                                      <React.Fragment key={index}>
                                        {editMode && editData.manageUserDetailsId === acc.manageUserDetailsId ? (
                                          // Render input fields for the row being edited */}
                      {/* <tr>
                                            <td>
                                              <input
                                                type="text"
                                                className="form-control custom-form font-regular font-14"
                                                placeholder="Enter User Name"
                                                name="userName"
                                                defaultValue={editData.userName}
                                                onChange={(e) => {
                                                  handleFieldValidation("userName", e.target.value, "manageUserEdit");
                                                }}
                                              />
                                              {erreditmanageUser.erruserName && (
                                                <div className="font-12 font-semibold mandatory-color mt-2 mb-0">
                                                  {erreditmanageUser.erruserName}
                                                </div>
                                              )}
                                            </td>
                                            <td>
                                              <input
                                                type="text"
                                                className="form-control custom-form font-regular font-14"
                                                placeholder="Enter Email Address"
                                                name="mailId"
                                                defaultValue={editData.mailId}
                                                onChange={(e) => {
                                                  handleFieldValidation("email", e.target.value, "manageUserEdit");
                                                }}
                                              />
                                              {erreditmanageUser.errmail && (
                                                <div className="font-12 font-semibold mandatory-color mt-2 mb-0">
                                                  {erreditmanageUser.errmail}
                                                </div>
                                              )}
                                            </td>
                                            <td>
                                              <select
                                                className="form-control custom-form font-regular font-14"
                                                id="authenticationType"
                                                aria-label="Default select example"
                                                name="authenticationType"
                                                defaultValue={editData.authenticationType}
                                                onChange={(e) => {
                                                  handleFieldValidation("authenticationType", e.target.value, "manageUserEdit");
                                                }}
                                              >
                                                <option value="" disabled>Select</option>
                                                {authenticationType.map((option, index) => (
                                                  <option key={index} value={option.authenticationType}>
                                                    {option.authenticationType}
                                                  </option>
                                                ))}
                                              </select>
                                              {erreditmanageUser.erruserName && (
                                                <div className="font-12 font-semibold mandatory-color mt-2 mb-0">
                                                  {erreditmanageUser.erruserName}
                                                </div>
                                              )}
                                            </td> 
                                            <td>
                                              <select
                                                className="form-control custom-form font-regular font-14"
                                                id="role"
                                                aria-label="Default select example"
                                                name="role"
                                                defaultValue={editData.roleType}
                                                onChange={(e) => {
                                                  handleFieldValidation("role", e.target.value, "manageUserEdit");
                                                }}
                                              >
                                                <option value="" disabled>Select</option>
                                                {roleData.map((option: {
                                                  roleType: string;
                                                }, index) => (
                                                  <option key={index} value={option.roleType}>
                                                    {option.roleType}
                                                  </option>
                                                ))}
                                              </select>
                                              {erreditmanageUser.role && (
                                                <div className="font-12 font-semibold mandatory-color mt-2 mb-0">
                                                  {erreditmanageUser.role}
                                                </div>
                                              )}
                                            </td>
                                            <td>
                                              <span className="text-nowrap">
                                                {editData.accountStatus}
                                              </span>
                                            </td>
                                            <td className="text-center">
                                              <span className="d-inline-block">
                                                <button
                                                  className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center"
                                                  onClick={(e) => handleActions(e, 'manageUser', 'editAccount')}
                                                  disabled={!manageUserbutton}
                                                >
                                                  <img src="/images/tick-action-icon.svg" alt="tick-icon" />
                                                </button>
                                                <button
                                                  className="btn action-rounded-btn success-action d-flex align-items-center justify-content-center"
                                                  onClick={() => {
                                                    seteditmode(false);
                                                    setEditData({ ...editData, accountStatus: '', authenticationType: '', userName: '', manageUserDetailsId: '', roleType: '', mailId: '' });
                                                  }}
                                                >
                                                  <img src="/images/cancel-icon.svg" alt="cancel" />
                                                </button>
                                              </span>
                                            </td>
                                          </tr> */}
                      {/* ) : (
                                          // Render normal row data
                                          <tr>
                                            <td>{acc.userName}</td>
                                            <td>{acc.mailId}</td>
                                            <td>{acc.authenticationType}</td>
                                            <td>{acc.roleType}</td>
                                            <td>
                                              <span className="text-nowrap">
                                                <span className={acc?.accountStatus?.toLowerCase() === "invited"
                                                  ? "table-status inprogress d-inline-block me-2"
                                                  : acc?.accountStatus?.toLowerCase() === "failed"
                                                    ? "table-status danger d-inline-block me-2"
                                                    : acc?.accountStatus?.toLowerCase() === "active"
                                                      ? "table-status success d-inline-block me-2"
                                                      : ''
                                                } />
                                                {acc.accountStatus}
                                              </span>
                                            </td>
                                            {role.toLowerCase() !== 'viewer' && role.toLowerCase() !== 'adminviewer' && acc?.accountStatus?.toLowerCase() !== "active" ? (
                                              <td className="text-center">
                                                <span className="action-drop-down">
                                                  <img
                                                    src="/images/keb-menu-icon.svg"
                                                    alt="keb-menu-icon"
                                                    data-bs-toggle="dropdown"
                                                    className="p-2 action-icon rounded-3"
                                                    aria-expanded="false"
                                                  />
                                                  <span className="dropdown-menu font-regular font-14 p-1">
                                                    <span className="d-flex gap-2 px-3 py-2 list" onClick={() => handleEdit(acc)}>
                                                      <img src="/images/edit-icon.svg" alt="edit" />
                                                      Edit
                                                    </span>
                                                    <span className="d-flex gap-2 px-3 py-2 list" onClick={() => { setDeletePopup(true); setDeleteid(acc.manageUserDetailsId) }}>
                                                      <img src="/images/red-delete-icon.svg" alt="delete" />
                                                      Delete
                                                    </span>
                                                    {(Math.floor((currentDate.getTime() - new Date(acc.createdDate).getTime()) / (1000 * 60 * 60 * 24))) < 3 ? null :
                                                      <span className="d-flex gap-2 px-3 py-2 list" onClick={() => sendInvite(acc.userName, acc.mailId, acc.roleType, acc.authenticationType)}>
                                                        <img src="/images/resend-icon.svg" alt="resend" />
                                                        Resend
                                                      </span>
                                                    }
                                                    {/* <span className="d-flex gap-2 px-3 py-2 list" onClick={() => sendInvite(acc.userName, acc.mailId, acc.roleType, acc.authenticationType)}>
                                                      <img src="/images/resend-icon.svg" alt="resend" />
                                                      Resend
                                            //         </span> */}
                      {/* //       </span>
                                            //     </span>
                                            //   </td>
                                            // ) : */}
                      {/* (
                                                <td className="text-center">
                                                  <span className="action-drop-down">
                                                    <img
                                                      src="/images/keb-menu-icon.svg"
                                                      alt="keb-menu-icon"
                                                      data-bs-toggle="dropdown"
                                                      className="p-2 action-icon rounded-3"
                                                      aria-expanded="false"
                                                    />
                                                    <span className="dropdown-menu font-regular font-14 p-1">
                                                      <span className="d-flex gap-2 px-3 py-2 list" onClick={() => handleEdit(acc)}>
                                                        <img src="/images/edit-icon.svg" alt="edit" />
                                                        Edit
                                                      </span>
                                                      <span className="d-flex gap-2 px-3 py-2 list" onClick={() => { setDeletePopup(true); setDeleteid(acc.manageUserDetailsId) }}>
                                                        <img src="/images/red-delete-icon.svg" alt="delete" />
                                                        Delete
                                                      </span>

                                                    </span>
                                                  </span>
                                                </td>
                                              )}
                                          </tr>
                                        )}
                                      </React.Fragment>
                                    ))} */}
                      {/* {noData && (
                                      <tr>
                                        <td colSpan={100}>
                                          <div className="pt-4 pb-3 text-center">
                                            <img
                                              src="/images/no-data-found-img.svg"
                                              alt="no-data-found-img"
                                              className="mb-5 mx-auto"
                                            />
                                            <h5 className="font-12 font-bold color-no-rec">No Record</h5>
                                            <p className="font-10 font-regular color-no-rec">
                                              There is no Record to show you right now
                                            </p>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div> */}
                      {/* Grid ends here
                            </div>
                          </div>
                        </div>   */}
                      {/*Manage Users Tab ends here*/}
                      {/*Invoice History Tab starts here*/}
                      {/* <div
                          className="tab-pane fade cust-padding-20"
                          id="invoice-history"
                          role="tabpanel"
                        >
                          <div className="row">
                            <div className="col-md-12 mb-2">
                              <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                                <h5 className="font-bold font-18 color-black">
                                  Invoice History (5)
                                </h5>
                                <div className="d-md-flex d-block gap-3 flex-wrap align-items-center w-md-100">
                                  <div className="d-flex gap-3 mb-md-0 mb-2 align-items-center">
                                    <div className="input-group input-search flex-nowrap">
                                      <input
                                        type="search"
                                        className="form-control border-end-0 font-14 font-regular w-auto"
                                        placeholder="Search"
                                      />
                                      <button
                                        className="btn border-start-0"
                                        type="button"
                                      >
                                        <img
                                          src="/images/search.svg"
                                          alt="search"
                                          className="d-block "
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*Grid starts here*/}
                      {/* <div className="table-responsive theme-table bg-white ">
                                <table className="table table-borderless table-shadow mb-0">
                                  <thead>
                                    <tr>
                                      <th className="text-nowrap">
                                        Invoice #
                                        <span className="ms-1 cursor-pointer">
                                          <img
                                            src="/images/sort-arrow-down.svg"
                                            alt="sort-arrow-down"
                                          />
                                        </span>
                                      </th>
                                      <th className="text-nowrap">
                                        Payment Status
                                        <span className="ms-1 cursor-pointer">
                                          <img
                                            src="/images/sort-arrow-down.svg"
                                            alt="sort-arrow-down"
                                          />
                                        </span>
                                      </th>
                                      <th className="text-nowrap">
                                        Payment Date
                                        <span className="ms-1 cursor-pointer">
                                          <img
                                            src="/images/sort-arrow-down.svg"
                                            alt="sort-arrow-down"
                                          />
                                        </span>
                                      </th>
                                      <th className="text-nowrap text-center">
                                        Action
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      className="accordion-table-row font-semibold font-14"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#invoice-acc-one"
                                    >
                                      <td className="text-nowrap p-3">
                                        <span className="d-flex align-items-center gap-3">
                                          <img
                                            src="/images/table-accordion-arrow.svg"
                                            alt="table-accordion-arrow"
                                            className="cursor-pointer table-accordion-arrow"
                                          />
                                          <span className="ac-heading">
                                            20220810-023-024
                                          </span>
                                        </span>
                                      </td>
                                      <td>
                                        <span className="text-nowrap">
                                          <span className="table-status success d-inline-block me-2"></span>
                                          Success
                                        </span>
                                      </td>
                                      <td>Jan 12, 2024</td>
                                      <td className="text-center">
                                        <span className="d-inline-block">
                                          <button className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center">
                                            <img
                                              src="/images/dowload-circle-icon.svg"
                                              alt="dowload-circle-icon"
                                              title="Download"
                                            />
                                          </button>
                                        </span>
                                      </td>
                                    </tr>
                                    <tr
                                      className="collapse show table-accordion-body"
                                      id="invoice-acc-one"
                                    >
                                      <td colSpan={4}>
                                        <span className="table-responsive theme-table bg-white d-inline-block w-100">
                                          <table className="table table-borderless mb-0 align-middle">
                                            <thead>
                                              <tr>
                                                <th className="text-nowrap">
                                                  Product Type
                                                </th>
                                                <th className="text-nowrap">
                                                  Plan Type
                                                </th>
                                                <th className="text-nowrap">
                                                  Purchase Type
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>Slack</td>
                                                <td>Premium</td>
                                                <td>Channel &amp; Chat Based</td>
                                              </tr>
                                              <tr>
                                                <td>Tenant</td>
                                                <td>Pro</td>
                                                <td>
                                                  Resource Utilization &amp; Channel
                                                  &amp; Chat Based
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>G Suite</td>
                                                <td>Elite</td>
                                                <td>Channel Based</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </span>
                                      </td>
                                    </tr>
                                    <tr
                                      className="accordion-table-row font-semibold font-14 collapsed"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#invoice-acc-two"
                                    >
                                      <td className="text-nowrap p-3">
                                        <span className="d-flex align-items-center gap-3">
                                          <img
                                            src="/images/table-accordion-arrow.svg"
                                            alt="table-accordion-arrow"
                                            className="cursor-pointer table-accordion-arrow"
                                          />
                                          <span className="ac-heading">
                                            20220810-023-024
                                          </span>
                                        </span>
                                      </td>
                                      <td>
                                        <span className="text-nowrap">
                                          <span className="table-status success d-inline-block me-2"></span>
                                          Success
                                        </span>
                                      </td>
                                      <td>Jan 12, 2024</td>
                                      <td className="text-center">
                                        <span className="d-inline-block">
                                          <button className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center">
                                            <img
                                              src="/images/dowload-circle-icon.svg"
                                              alt="dowload-circle-icon"
                                              title="Download"
                                            />
                                          </button>
                                        </span>
                                      </td>
                                    </tr>
                                    <tr
                                      className="collapse table-accordion-body"
                                      id="invoice-acc-two"
                                    >
                                      <td colSpan={4}>
                                        <span className="table-responsive theme-table bg-white d-inline-block w-100">
                                          <table className="table table-borderless mb-0 align-middle">
                                            <thead>
                                              <tr>
                                                <th className="text-nowrap">
                                                  Product Type
                                                </th>
                                                <th className="text-nowrap">
                                                  Plan Type
                                                </th>
                                                <th className="text-nowrap">
                                                  Purchase Type
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>Slack</td>
                                                <td>Premium</td>
                                                <td>Channel &amp; Chat Based</td>
                                              </tr>
                                              <tr>
                                                <td>Tenant</td>
                                                <td>Pro</td>
                                                <td>
                                                  Resource Utilization &amp; Channel
                                                  &amp; Chat Based
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>G Suite</td>
                                                <td>Elite</td>
                                                <td>Channel Based</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </span>
                                      </td>
                                    </tr>
                                    <tr
                                      className="accordion-table-row font-semibold font-14 collapsed"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#invoice-acc-three"
                                    >
                                      <td className="text-nowrap p-3">
                                        <span className="d-flex align-items-center gap-3">
                                          <img
                                            src="/images/table-accordion-arrow.svg"
                                            alt="table-accordion-arrow"
                                            className="cursor-pointer table-accordion-arrow"
                                          />
                                          <span className="ac-heading">
                                            20220810-023-024
                                          </span>
                                        </span>
                                      </td>
                                      <td>
                                        <span className="text-nowrap">
                                          <span className="table-status danger d-inline-block me-2"></span>
                                          Fail
                                        </span>
                                      </td>
                                      <td>Jan 08, 2024</td>
                                      <td className="text-center">
                                        <span className="d-inline-block">
                                          <button className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center">
                                            <img
                                              src="/images/dowload-circle-icon.svg"
                                              alt="dowload-circle-icon"
                                              title="Download"
                                            />
                                          </button>
                                        </span>
                                      </td>
                                    </tr>
                                    <tr
                                      className="collapse table-accordion-body"
                                      id="invoice-acc-three"
                                    >
                                      <td colSpan={4}>
                                        <span className="table-responsive theme-table bg-white d-inline-block w-100">
                                          <table className="table table-borderless mb-0 align-middle">
                                            <thead>
                                              <tr>
                                                <th className="text-nowrap">
                                                  Product Type
                                                </th>
                                                <th className="text-nowrap">
                                                  Plan Type
                                                </th>
                                                <th className="text-nowrap">
                                                  Purchase Type
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>Slack</td>
                                                <td>Premium</td>
                                                <td>Channel &amp; Chat Based</td>
                                              </tr>
                                              <tr>
                                                <td>Tenant</td>
                                                <td>Pro</td>
                                                <td>
                                                  Resource Utilization &amp; Channel
                                                  &amp; Chat Based
                                                </td>
                                              </tr>
                                              <tr>
                                                <td>G Suite</td>
                                                <td>Elite</td>
                                                <td>Channel Based</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div> */}
                      {/*grid ends here*/}
                      {/* </div> */}
                      {/* </div> */}
                      {/* </div>  */}
                      {/*Invoice History Tab ends here*/}
                      {/* </div> */}
                      {/*Tab Inner ends here*/}
                      {/* </div> */}
                      {/* )}  */}
                      {alertPopup === true ? (
                        <div
                          className="modal fade show"
                          id="Alert-popup"
                          data-bs-backdrop="static"
                          data-bs-keyboard="false"
                          tabIndex={-1}
                          aria-labelledby="Alert-popupLabel"
                          aria-hidden="true"
                          style={{
                            display: "block",
                            backgroundColor: "rgba(0,0,0,0.6)",
                          }}
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content popup-brd-radius">
                              <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                                <span className="position-absolute">
                                  <img
                                    src="/images/failure-popup-icon.svg"
                                    alt="Alert-popup-icon"
                                  />
                                </span>
                              </div>
                              <div className="modal-body border-0 text-center">
                                <h5
                                  className="modal-title mb-3 primary-header"
                                  id="Alert-popupLabel"
                                >
                                  Alert
                                </h5>
                                <p className="primary-data font-16">{popupmessage || 'Something went wrong , Please try again later !'}</p>
                              </div>
                              <div className="modal-footer border-0 pb-4 justify-content-center">
                                <button
                                  type="button"
                                  className="btn primary-btn font-14 px-4 font-semibold"
                                  data-bs-dismiss="modal"
                                  onClick={() => setAlertPopup(false)}
                                >
                                  Ok
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {editPopup && (
                        <div
                          className="modal fade show"
                          id="EditProfilePopup"
                          data-bs-backdrop="static"
                          data-bs-keyboard="false"
                          tabIndex={-1}
                          aria-labelledby="MailPopupLabel"
                          aria-hidden="true"
                          style={{
                            display: "block",
                            backgroundColor: "rgba(0,0,0,0.6)",
                          }}
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content p-4">
                              <div className="modal-header border-0 p-0">
                                <h1
                                  className="font-20 font-bold custom-header-color m-0"
                                  id="MailPopupLabel"
                                >
                                  Edit Profile
                                </h1>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={() => {
                                    setEditPopup(false)


                                  }}
                                />
                              </div>

                              <div className="modal-body border-0 p-0 pt-2 pb-5">
                                {loader && <Loader />}

                                <div className="row">
                                  <div className="col-md-6">
                                    <label
                                      className="font-14 font-semibold label-color w-100 my-2 pt-3"
                                      htmlFor="userName"
                                    >
                                      Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control custom-form font-14 font-regular"
                                      id="user-name"
                                      placeholder="Enter User Name "
                                      value={editClientData?.userName}
                                      onChange={(e) =>
                                        handleFieldValidation(
                                          e.target.id,
                                          e.target.value,
                                          "edit"
                                        )
                                      }
                                    />

                                    <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">

                                    </div>

                                  </div>
                                  <div className="col-md-6">
                                    <label
                                      className="font-14 font-semibold label-color w-100 my-2 pt-3"
                                      htmlFor="userMail"
                                    >
                                      Mail
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control custom-form font-14 font-regular"
                                      id="userMail"
                                      placeholder="Enter Mail Address"
                                      defaultValue={editClientData?.email}
                                      disabled
                                      onChange={(e) =>
                                        handleFieldValidation(
                                          e.target.id,
                                          e.target.value,
                                          "edit"
                                        )
                                      }
                                    />

                                    <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">

                                    </div>

                                  </div>
                                  {role.toLowerCase() == "secondaryuser" || role.toLowerCase() == "migrationmanager" || role.toLowerCase() == "viewer" ? null : (<div className="col-md-6">
                                    <label
                                      className="font-14 font-semibold label-color w-100 my-2 pt-3"
                                      htmlFor="userComp"
                                    >
                                      Company
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control custom-form font-14 font-regular"
                                      id="userComp"
                                      placeholder="Enter Company Name"
                                      value={editClientData?.companyName}
                                      onChange={(e) =>
                                        handleFieldValidation(
                                          e.target.id,
                                          e.target.value,
                                          "edit"
                                        )

                                      }
                                    />

                                    <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">

                                    </div>

                                  </div>)}
                                  <div className="col-md-6">
                                    <label
                                      className="font-14 font-semibold label-color w-100 my-2 pt-3"
                                      htmlFor="userComp"
                                    >
                                      Registration Type
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control custom-form font-14 font-regular"
                                      id="userRegType"
                                      placeholder="Enter RegistrationType"
                                      defaultValue={editClientData.registrationType}
                                      disabled
                                      onChange={(e) =>
                                        handleFieldValidation(
                                          e.target.id,
                                          e.target.value,
                                          "edit"
                                        )
                                      }
                                    />

                                    <div className="font-12 font-semibold mandatory-color mt-2 mb-0 "></div>

                                  </div>
                                </div>
                                <div className="col-md-6">
                                    <label
                                      className="font-14 font-semibold label-color w-100 my-2 pt-3"
                                      htmlFor="userComp"
                                    >
                                      Phone
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control custom-form font-14 font-regular"
                                      id="phone"
                                      placeholder="Enter Phone"
                                      value={editClientData?.phone}
                                      onChange={(e) =>
                                        handleFieldValidation(
                                          e.target.id,
                                          e.target.value,
                                          "edit"
                                        )
                                      }
                                    />
                                     {erroreditMessage.errPhone && <span className="font-12 font-semibold mandatory-color mt-2 mb-0 ">{erroreditMessage.errPhone}</span>}

                                    <div className="font-12 font-semibold mandatory-color mt-2 mb-0 "></div>

                                 
                                </div>
                                {role.toLowerCase() == "primaryadmin" ||
                                  role.toLowerCase() == "secondaryadmin" ||
                                  role.toLowerCase() == "adminviewer" || role.toLowerCase() == "secondaryuser" || role.toLowerCase() == "viewer" || role.toLowerCase() == "migrationmanager" ? null : (
                                  <div className="row">
                                    <label
                                      className="form-label font-14 font-semibold label-color my-2 pt-3"
                                      htmlFor="ph-no"
                                    >
                                      Phone
                                    </label>
                                    <div className="input-group flex-nowrap">
                                      <Select className="country-dd"
                                        options={countryOptions}
                                        onChange={handleCountryChange}
                                        value={countryOptions.find((option: any) => option.value === AccountInfo.phonecode)}
                                      />
                                      <input
                                        type="tel"
                                        className="form-control custom-form font-14 font-regular"
                                        placeholder="Enter Phone Number"
                                        id="ph-no"
                                        defaultValue={AccountInfo.phone}
                                        maxLength={15}
                                        onInput={(e: any) => {
                                          const value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                                          e.target.value = value; // Update the input value
                                          handleFieldValidation(e.target.id, value, "accountInfo");
                                        }}
                                      />

                                    </div>
                                    (
                                    <div className="font-12 font-semibold mandatory-color mt-2 mb-0 ">

                                    </div>
                                    )
                                  </div>
                                )}
                              </div>
                              <div className="modal-footer border-0 p-0">
                                <button
                                  type="button"
                                  className="btn secondary-btn"
                                  data-bs-dismiss="modal"
                                  onClick={() => {
                                    setEditPopup(false)
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  className="btn primary-btn"
                                  disabled={!enable}
                                  onClick={(e) => { handleClientdata( "edit") }}
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {Deletepopup === true ? (
                        <div
                          className="modal fade show"
                          id="Failure-popup"
                          data-bs-backdrop="static"
                          data-bs-keyboard="false"
                          aria-labelledby="Failure-popupLabel"
                          aria-modal="true"
                          role="dialog"
                          style={{
                            display: "block",
                            backgroundColor: "rgba(0,0,0,0.8)",
                          }}
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content popup-brd-radius">
                              <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                                <span className="position-absolute">
                                  <img
                                    src="/images/failure-popup-icon.svg"
                                    alt="failure-popup-icon"
                                  />
                                </span>
                              </div>

                              <div className="modal-body border-0 text-center">
                                <h5
                                  className="modal-title mb-3 primary-header"
                                  id="Failure-popupLabel"
                                >
                                  Alert
                                </h5>

                                <p className="primary-data">
                                  Are you sure want to delete the user?
                                </p>
                              </div>

                              <div className="modal-footer border-0 pb-4 justify-content-center">
                                <button
                                  type="button"

                                  className="btn primary-btn font-14 px-4 font-semibold"
                                  data-bs-dismiss="modal"
                                >
                                  Yes
                                </button>
                                <button
                                  type="button"
                                  onClick={() => {
                                    setDeletePopup(false);
                                  }}
                                  className="btn primary-btn font-14 px-4 font-semibold"
                                  data-bs-dismiss="modal"
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {successPopup === true ? (
                        <div className="modal fade show " id="Success-popup"
                          aria-labelledby="Success-popupLabel" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}>
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content popup-brd-radius">
                              <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                                <span className="position-absolute">
                                  <img src="/images/success-popup-icon.svg" alt="success-popup-icon" />
                                </span>
                              </div>
                              <div className="modal-body border-0 text-center">
                                <h5 className="modal-title mb-3 primary-header" id="Success-popupLabel">
                                  Success !
                                </h5>
                                <p className="primary-data">{successPopupType == 'passwordUpdate' ? 'Password Updated Successfully' : successPopupType == 'insertuser' ? 'User Added Successfully' : successPopupType == 'accountInfoupdate' ? 'Account Information Updated Successfully' : successPopupType == 'userInsert' ? 'User Added Successfully' : successPopupType == 'userUpdate' ? 'User Updated Successfully' : successPopupType == 'userDelete' ? 'User Deleted Successfully' : ''}</p>
                              </div>
                              <div className="modal-footer border-0 justify-content-center pb-4">
                                <button
                                  type="button"
                                  className="btn primary-btn font-14 px-4 font-semibold"
                                  data-bs-dismiss="modal"
                                  onClick={() => {
                                    if (successPopupType == 'passwordUpdate') {
                                      sessionStorage.clear();
                                      if (role.toLowerCase().includes("admin")) {
                                        history.push('/Admin/Login')
                                      }
                                      else {
                                        history.push('/Login')
                                      }
                                      setSuccessPopup(false);
                                    }
                                    setSuccessPopup(false);
                                  }}                            >
                                  Ok
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loader && <Loader />}
          </div>
        }
      </div>
    </>
  );
};
export default ClientProfile;





