// ContactUs.jsx (steps: 4 - 41)
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FAQ, contactus, editQuestion, sessionResponse } from '../../Common/Interface';
import { decodeSessionDetails } from '../../Services/CommonServices';
import { addCommentDetails, deleteFaq, fetchAllFaq, fetchCategory, insertFaq } from '../../Services/ContactUsServices';
import { commonErrorMsgs, defaultAddress } from '../../Common/Constant';
import { getSession } from '../../Services/manageClientAPI';
import objCryptoJS from "crypto-js";
import jwt from 'jwt-decode'



export default function Contactus() {
    /* PS_EX_01 to PS_EX_18 will be executed */
    const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
    const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
    const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
    const [objAdmin, setObjAdmin] = useState({})
    const [objClient, setObjClient] = useState({})
    const [alert, setAlert] = useState<boolean>(false)
    const [alertMessage, setAlertMessage] = useState<string>('')
    let ContactUs: contactus = {
        selectedCategory: "",
        comments: ""
    }
    const [showContactUs, setShowContactUs] = useState<string>('Contactus');
    const [category, setCategory] = useState<Array<any>>([]);
    const [contactUsReq, setContactUsReq] = useState<any>(ContactUs)
    const [loader, setLoader] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [successPOPup, setSuccessPopup] = useState<boolean>(false)
    let FAQ: FAQ = {
        question: "",
        answer: "",
        categoryId: ""
    }
    let addQuestion: editQuestion = {
        faqId: '',
        question: "",
        answer: "",
        category: "",
    };
    let editQuestion: editQuestion = {
        faqId: "",
        question: "",
        answer: "",
        category: "",
    };
    let allFaq: editQuestion = {
        question: "",
        answer: "",
        category: "",
        faqId: "",
    };
    const [faqRequest, setFaqRequest] = useState<editQuestion>(addQuestion);
    const [editFaqRequest, setEditFaqRequest] = useState<editQuestion>(editQuestion);
    const [getQuestionAndAnswer, setGetQuestionAndAnswer] = useState<Array<any>>([allFaq]);
    const [updateButton, setUpdateButton] = useState<boolean>(false);
    let [clientName, setclientName] = useState<string>('');
    let [companyName, setCompanyName] = useState<string>('');
    let [emailAddress, setEmailAddress] = useState<string>('');
    let [role, setRole] = useState<string>('');
    let [isAdmin, setIsAdmin] = useState<boolean>(false);
    let [adminName, setAdminName] = useState<string>('');
    let [adminEmailAddress, setAdminEmailAddress] = useState<string>('');
    let [adminrole, setAdminRole] = useState<string>('');
    const [faqDetail, setFaqDetail] = useState<Array<any>>([FAQ])
    const history = useHistory();
    const [sendMail, setSendMail] = useState<boolean>(true)
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  // //debugger
  let InActiveTimeout: NodeJS.Timeout;


    useEffect(() => {
        debugger
        //console.log(window.location.pathname);
        sessionTokenProperties();
        resetTimer();
        window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);
        if (window.location.pathname.toString().toLowerCase().includes("contactus")) {
            if (showContactUs == 'Contactus') {
                setContactUsReq(ContactUs)
                getCategory()
            }
            else if (showContactUs == 'Faq') {
                getAllFaq()
            }
        }
        else if (window.location.pathname.toString().toLowerCase().includes("faq") || showContactUs == 'addFaq') {
            sessionTokenProperties()
            setShowContactUs('addFAQ')
            getCategory()
            getAllFaq()
        }
        /*PS_EX_36 to PS_EX_41 will be executed */

        const mediaQuery: any = window.matchMedia('(max-width: 768px)');
        const handleMediaQueryChange = (event: any) => {
            setIsMobile(event.matches);
        };
        setIsMobile(!mediaQuery.matches);
        mediaQuery.addListener(handleMediaQueryChange);
        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('keydown', resetTimer);
      
        };
        

    }, [showContactUs]);

    let resetTimer = async () => {
        try {
          // debugger;
          //console.log("Reset Timer Start");
    
          let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
          //console.log("sessionResponse value :", sessionResponse);
    
          // //debugger
          if (sessionResponse == 'expired') {
            debugger
            sessionStorage.removeItem("c_at");
            setisSessionEpired(true)
            setAlert(true)
            setAlertMessage('Your Session has Expired please login again to continue');
          }
          else if (sessionResponse == 'updateToken') {
            if(emailAddress == "" || emailAddress == undefined){
                await sessionTokenProperties();
            }
            debugger
            let token = await getToken()
            let payload = {
              mailId: emailAddress,
              isAdmin: objClient == undefined ? true : false,
              token: token
            };
            let getsessionToken = await getSession(payload);
            const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
            const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;
    
            const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
            const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
            let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
            await sessionStorage.removeItem("c_at");
            await sessionStorage.setItem('c_at', updatedToken);
            let updatedobjClient: any = jwt(c_decryptedValue);
            SessionEpiredDateTime = updatedobjClient.CreatedAt;
            setSessionEpiredDateTime(SessionEpiredDateTime);
          }
          //console.log("Reset Timer End");
        }
        catch (error) {
          //console.log("Reset Timer Catch");
          setAlert(true)
          setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
          setLoader(false);
        }
      }
    
      const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
        try {
          // debugger
          //console.log("Reset Session Timer Start");
          // Assuming InActiveTimeout is a number representing the ID of the current timeout
    
          if (typeof window !== 'undefined') {
            //console.log("Reset Session Timer Inside");
            clearTimeout(InActiveTimeout);
            const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);
    
            InActiveTimeout = setTimeout(() => {
              //console.log("Expired");
              sessionStorage.removeItem("c_at");
              //console.log("removed session");
              setisSessionEpired(true)
              setAlert(true)
              setAlertMessage('Your Session has Expired please login again to continue');
              // return 'expired'
            }, tokenExpiryCheckInterval);
    
            //console.log("Reset Session Timer checking");
    
            const currentDateTime = new Date().toISOString();
            const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
            //console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);
            
            const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
            //console.log("diffInMilliSeconds values:", diffInMilliseconds);
            
            const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
            //console.log("minutes : ", minutes);
    
            const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
            //console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);
    
            if (minutes > sessionUpdateTimeGap) {
    
              //console.log("Reset Session Timer need to update");
              return 'updateToken'
            }
    
            //console.log("Reset Session Timer End");
            return 'NoActionRequired'
          }
          return 'NoActionRequired'
        }
        catch (ex) {
          //console.log("Reset Session Timer Catch");
          throw ex
        }
      };

    let sessionTokenProperties = async () => {
        try {
            const tokenResponse: any = await decodeSessionDetails();
            debugger
            if (tokenResponse.adminDetails) {
                if (tokenResponse.adminDetails) {
                    setObjAdmin(tokenResponse.adminDetails)
                    setAdminName(tokenResponse.adminDetails.Name);
                    setAdminEmailAddress(tokenResponse.adminDetails.Email);
                    emailAddress = tokenResponse.adminDetails.Email;
                    setEmailAddress(emailAddress);
                    setSessionEpiredDateTime(tokenResponse.adminDetails.CreatedAt);
                    setAdminRole(tokenResponse.adminDetails.Role);
                }
                else {
                    if (window.location.pathname.toString().toLowerCase().includes("admin")) {
                        history.push("/Admin/Login")
                    }
                }
            }
            else {
                if (tokenResponse.clientDetails) {
                    setObjClient(tokenResponse.clientDetails)
                    setclientName(tokenResponse.clientDetails.Name);
                    setCompanyName(tokenResponse.clientDetails.CompanyName)
                    emailAddress = tokenResponse.clientDetails.Email;
                    setEmailAddress(emailAddress);
                    setRole(tokenResponse.clientDetails.Role);
                    setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
                }
                else {
                    history.push("/Login")
                }
            }
            //#endregion
        } catch (error) {
            console.error('Failed in main method', error);
        }
    };

    const getToken = async () => {
        let clientToken: string;
        let adminToken: string;
        let token: { clientToken?: string; adminToken?: string } = {};
        debugger
        try {
            if (objClient != undefined) {
                let sessionToken: any = sessionStorage.getItem('c_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
                    token['clientToken'] = clientToken

                }


            }
            if (objAdmin != undefined) {
                let sessionToken: any = sessionStorage.getItem('a_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                    token['adminToken'] = adminToken

                }
            }
            return token
        } catch (error) {
            throw error
        }
    }

    //PS_EX_30 to PS_EX_35 will be executed
    async function getCategory() {

        try {
            let token = await getToken()
            let payload = {
                token: token,
            }
            setLoader(true)
            // Step 18: getCategory
            let result = await fetchCategory(payload)
            //console.log(result);

            if (result?.data?.statusCode == 200) {
                setLoader(false)
                setCategory(result?.data?.data)
            }
            else {
                setLoader(false)
                setAlert(true)
                setAlertMessage(commonErrorMsgs.generalError)
            }
        } catch (error: any) {
            setLoader(false)
            setAlert(true)
            setAlertMessage(commonErrorMsgs.generalError)
        }
    }
    /**PS_EX_106 to PS_EX_108 will be executed
     */
    const handleChange = (event: any) => {
        setContactUsReq({ ...contactUsReq, [event.target.name]: event.target.value })
    };


    function bindDropDown() {
        return (
            category?.map((value, index) => {
                return <option value={value.categoryId}>{value.category}</option>
            })
        )
    }

    //PS_EX_49 to PS_EX_51 will be executed
    function oncancel() {
        ContactUs.comments = ""
        ContactUs.selectedCategory = ""
        setContactUsReq(ContactUs)
    }
    /**PS_EX_56 to PS_EX_59 will be executed */
    async function getAllFaq() {
        setLoader(true)
        let token = await getToken()
        //console.log(contactUsReq.selectedCategory, "praveen");

        let payload = {
            token: token,
            categoryId: Number(contactUsReq.selectedCategory) ? Number(contactUsReq.selectedCategory) : null
        }
        try {
            let result: any = await fetchAllFaq(payload)
            //console.log(result, "sham");

            if (result?.data?.statusCode == 200) {
                setLoader(false)
                setFaqDetail(result?.data?.data)
                //console.log("se6t", "setFaqDetail");

                setGetQuestionAndAnswer(result?.data?.data);
            }
            else {
                setLoader(false)
                setAlert(true)
                setAlertMessage(commonErrorMsgs.generalError)
            }

        } catch (error: any) {
            setLoader(false)
            setAlert(true)
            setAlertMessage(commonErrorMsgs.generalError)
        }
    }

    /*PS_EX_64 to PS_EX_69 will be executed */
    async function handleSubmit() {
        if (contactUsReq.selectedCategory && contactUsReq.comments) {
            try {
                let token = await getToken()
                //console.log(contactUsReq.selectedCategory, "praveen");

                let payload = {
                    token: token,
                    categoryId: Number(contactUsReq.selectedCategory),
                    comments: contactUsReq.comments
                };
                setLoader(true)
                let postComments = await addCommentDetails(payload)
                //console.log(postComments?.data?.statusCode, "deepthi");
                setLoader(false)

                if (postComments?.data?.statusCode == 200) {
                    setSuccessPopup(true)
                    setLoader(false)
                }
                else {
                    setLoader(false)
                    setAlert(true)
                    setAlertMessage(commonErrorMsgs.generalError)
                }

            } catch (error: any) {
                setLoader(false)
                setAlert(true)
                setAlertMessage(commonErrorMsgs.generalError)
            }


        }
    };

    function bindFaq() {
        return (
            <div>
                {faqDetail.length > 0 ? faqDetail?.map((item, index) => (
                    <div className="table-responsive theme-table bg-white my-2" key={index}>
                        <table className="table table-borderless mb-0">
                            <thead>
                                <tr>
                                    <th className="text-nowrap table-accordion-head" colSpan={2}>
                                        <div className="d-flex align-items-center justify-content-between gap-3">
                                            <div className="d-flex align-items-center">
                                                <span><img src="/images/faq-question-icon.svg" alt="FAQ" className="me-2" /></span>
                                                <label className="font-14 font-semibold color-grey-v1 table-break-space">{item.question}</label>
                                            </div>
                                            <button className={`btn btn-link table-accordion-arrow ${index === 0 ? '' : 'collapsed'}`} type="button" data-bs-toggle="collapse" data-bs-target={`#accfaq${index}`} aria-expanded={index === 0 ? 'true' : 'false'} aria-controls={`accfaq${index}`}>
                                                <img src="/images/table-accordion-arrow.svg" alt="table-accordion-arrow" className="cursor-pointer" />
                                            </button>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className={`collapse ${index === 0 ? 'show' : ''}`} id={`accfaq${index}`}>
                                    <td colSpan={2}>
                                        <p className="font-14 font-semibold label-color mb-2">
                                            {item.answer}
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )) : 
                        <div className="pt-4 pb-3 text-center">
                            <img
                                src="/images/no-data-found-img.svg"
                                alt="no-data-found-img"
                                className="mb-3"
                            />
                            <h5 className="font-12 font-bold color-no-rec">No records</h5>
                            <p className="font-10 font-regular color-no-rec">
                                There is no record to show you right now
                            </p>
                        </div>
                    
                }
            </div>
        );
    }

    const renderSuccessPopup = () => {
        return (
            <div className="modal fade show " id="Success-popup" tabIndex={-1}
                aria-labelledby="Success-popupLabel" aria-modal="true" role="dialog" style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.8)" }}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                                <img src="/images/success-popup-icon.svg" alt="success-popup-icon" />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5 className="modal-title mb-3 primary-header" id="Success-popupLabel">
                                Success !
                            </h5>
                            <p className="primary-data">Email has been sent successfully.</p>
                        </div>
                        <div className="modal-footer border-0 justify-content-center pb-4">
                            <button
                                type="button"
                                className="btn primary-btn font-14 px-4 font-semibold"
                                data-bs-dismiss="modal"
                                // 108
                                onClick={() => { setSuccessPopup(false); setShowContactUs('Contactus') }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    // PS_EX_44 to PS_EX_47 wil be executed
    const handleClick = () => {

        const mapUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(defaultAddress)}`;

        window.open(mapUrl, '_blank');
    }


    function handleOnchange(event: any) {
        //console.log(event.target.value, "test");

        setFaqRequest({ ...faqRequest, [event.target.name]: event.target.value });
    }
    /**PS_EX_72 to PS_EX_79 will be executed
     */
    async function addQuestionAndAnswer() {
           const token = await getToken()
        setLoader(true)
        try {
            let payload = {
                token: token ,
                faqId: faqRequest.faqId ? Number(faqRequest.faqId) : null,
                question: faqRequest.question,
                answer: faqRequest.answer,
                categoryId: Number(faqRequest.category),
            };
            //console.log(payload, "alfred");
            // Step 34: getCategory
            let addFaq = await insertFaq(payload);
//console.log(addFaq?.data,"alfred");

            if (addFaq?.data?.statusCode == 200) {
                // Step 42: Update State
                setFaqRequest({
                    ...faqRequest,
                    question: "",
                    answer: "",
                    category: ""
                })
                setUpdateButton(false)
                getAllFaq()
            }
        } catch (error: any) {
            //console.log(error.message);
        }
    }

    /* PS_EX_99 to PS_EX_102 will be executed */
    const areObjectsEqual = (obj1: any, obj2: any) => {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }
        for (let key of keys1) {
            if (obj1[key].trim() !== obj2[key].trim()) {
                return false;
            }
        }
        return true;
    };

    //console.log(faqRequest, "faqrequest");
    /**PS_EX_110 to PS_EX_112 will be executed
    */
    function editQuestionandAnswer(item: any) {
        try {
            debugger
            //console.log("shammelbin",);
            setFaqRequest((prevState) => ({
                ...prevState,
                faqId: item.faqId.toString(),
                question: item.question,
                answer: item.answer,
                category: item.categoryId.toString(),
            }));
            //console.log("aaaa");
            setEditFaqRequest((prevState) => ({
                ...prevState,
                faqId: item.faqId.toString(),
                question: item.question,
                answer: item.answer,
                category: item.categoryId.toString(),
            }));
            // 109
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } catch (error: any) {
            //console.log(error.message)
        }
    }

    /**  PS_EX_82 to PS_EX_88 will be executed*/
    async function deleteQuestionandAnswer(faqId: number) {
        const token = await getToken()
        setLoader(true)
        try {
            let payload={
                token : token,
                faqId :faqId
            }
            let delFaq = await deleteFaq(payload);
            if (delFaq?.status == 200) {
                // 68 - 69 // render the page
                setFaqRequest(addQuestion)
                setUpdateButton(false)
                getAllFaq();
            }
        } catch (error: any) {
            //console.log(error.message);
        }
    }

    function bindQuestionAndAnswer() {
        return (
            <div>
                {getQuestionAndAnswer.map((item, index) => (
                    <div className="col-md-12 col-sm-12 mb-2" key={index}>
                        <div className="card h-100 py-3 ps-4 pe-3 border-radius-8 card-hover">
                            <div className="row">
                                <div className="col-xl-11 col-lg-10 col-md-10 col-sm-12">
                                    <div className="d-flex align-items-center mb-2">
                                        <span>
                                            <img
                                                src="/images/faq-question-icon.svg"
                                                alt="FAQ"
                                                className="me-2"
                                            />
                                        </span>
                                        <label className="font-14 font-semibold color-grey-v1 table-break-space">
                                            {item.question}
                                        </label>
                                    </div>
                                    <p className="font-14 font-regular label-color mb-0">
                                        {item.answer}
                                    </p>
                                </div>
                                <div className="col-xl-1 col-lg-2 col-md-2 col-sm-12 card-hover-block align-self-center">
                                    <div className="d-flex align-items-center justify-content-center mt-4 mt-md-0">
                                        <span className="d-inline-block me-3">
                                            <button
                                                className="btn action-rounded-btn resume-action d-flex align-items-center justify-content-center"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                data-bs-title="Edit"
                                                data-bs-original-title=""
                                                title=""
                                                onClick={() => {
                                                    setUpdateButton(true);
                                                    editQuestionandAnswer(item);
                                                }}
                                            >
                                                <img src="/images/edit-action-icon.svg" alt="edit-action-icon" />
                                            </button>
                                        </span>
                                        <span className="d-inline-block">
                                            <button
                                                className="btn action-rounded-btn danger-action d-flex align-items-center justify-content-center"
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="bottom"
                                                data-bs-title="Cancel"
                                                data-bs-original-title
                                                onClick={() => {
                                                    deleteQuestionandAnswer(Number(item.faqId));
                                                }}
                                            >
                                                <img src="/images/red-delete-icon.svg" alt="cancel" />
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <>
            {loader && (
                <div className="overlay">
                    <div className="position-absolute top-50 start-50 translate-middle">
                        <div
                            className="spinner-border Loader loader-color align-center "
                            role="status"
                        >
                            <span>
                                <span className="loader-inner-circle" />
                            </span>
                        </div>
                        <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                            Loading..
                        </p>
                    </div>
                </div>
            )}
            {showContactUs == 'Contactus' && <div className="col-md-12 container-height">
                <div className="card px-4 py-4 mt-3 mb-53 border-0 rounded-3">
                    <div className="d-md-flex d-block justify-content-between mb-4 align-items-center">
                        <h5 className="secondary-header mb-0">
                            Contact Us
                        </h5>
                        {adminrole ? <button type="button" className="btn primary-btn text-nowrap w-md-100 mt-4 mt-md-0 font-semibold font-14 d-flex gap-2 justify-content-center px-3 align-items-center" onClick={(e) => {
                            setShowContactUs('addfaq')
                            history.push('/FAQ')
                        }}>
                            <img src="/images/setting-icon.svg" alt="setting-icon" />
                            Configurations
                        </button> : <></>}
                    </div>
                    <div className="card px-4 py-4 border-0 rounded-3 mb-4 contactus-card-bg">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-9 col-md-12 col-sm-12 text-center py-4">
                                <span><img src="/images/chat-icon.svg" alt="chat-icon" className="mb-2" /></span>
                                <h2 className="font-30 font-bold sub-head-black">We'd love to hear form you.</h2>
                                <p className="primary-data">Here's what some of my satisfied clients have to say about my
                                    work</p>
                                <div className="row gap-3 justify-content-center">
                                    <div className="col-lg-auto col-md-auto col-sm-auto px-3 py-2 my-1 border-0 rounded-3 bg-white">
                                        <span className="d-flex align-items-center">
                                            <img src="/images/assistance-icon.svg" alt="assistance-icon" className="me-2" />
                                            <label className="font-13 font-semibold  sub-head-black">24/7 Assistance</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-auto col-md-auto col-sm-auto px-3 py-2 my-1 border-0 rounded-3 bg-white">
                                        <span className="d-flex align-items-center">
                                            <img src="/images/quick-resolution-icon.svg" alt="quick-resolution-icon" className="me-2" />
                                            <label className="font-13 font-semibold  sub-head-black">Quick Change Resolutions</label>
                                        </span>
                                    </div>
                                    <div className="col-lg-auto col-md-auto col-sm-auto px-3 py-2 my-1 border-0 rounded-3 bg-white">
                                        <span className="d-flex align-items-center">
                                            <img src="/images/flxible-hrs.svg" alt="Flexible" className="me-2" />
                                            <label className="font-13 font-semibold  sub-head-black">Flexible Working Hours</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row pt-2">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <h5 className="font-16 font-bold sub-head-black mt-1 mb-3">Others</h5>
                            <ul className="list-unstyled ps-0">
                                <li className="d-flex justify-content-between contactus-card-bg border-0 rounded-3 p-3 mb-4">
                                    <div>
                                        <h5 className="font-14 font-regular tab-grey-color">You can email us here</h5>
                                        <a href={`mailto:${'support@avasoft.com'}`} className="mb-0 font-16 font-semibold  sub-head-black">support@avasoft.com</a>
                                    </div>
                                    <button onClick={() => { window.open(`mailto:${'support@avasoft.com'}`, "_self") }} type="button" className="btn white-btn py-2 px-3 rounded-3 d-flex align-items-center" style={{ cursor: 'pointer' }}>
                                        <img src="/images/other-back-icon.svg" alt="other-back-icon" />
                                    </button>
                                </li>
                                <li className="d-flex justify-content-between contactus-card-bg border-0 rounded-3 p-3 mb-4">
                                    <div>
                                        <h5 className="font-14 font-regular tab-grey-color">Contact us for support</h5>
                                        {isMobile ? (
                                            <span className="mb-0 font-16 font-semibold sub-head-black">
                                                +12 9202992021
                                            </span>
                                        ) : (
                                            <a
                                                href="tel:9202992021"
                                                className="mb-0 font-16 font-semibold sub-head-black"
                                            >
                                                +12 9202992021
                                            </a>
                                        )}
                                    </div>
                                    <button onClick={() => { window.open("tel:9202992021", "_self") }} type="button" className="btn white-btn py-2 px-3 rounded-3 d-flex align-items-center" style={{ cursor: 'pointer' }}>
                                            <img src="/images/other-back-icon.svg" alt="other-back-icon" />
                                    </button>
                                    {/* {window.matchMedia
                                        (
                                            "(max-width: 768px)"
                                        ).matches && <button onClick={() => { window.open("tel:9202992021", "_self") }} type="button" className="btn white-btn py-2 px-3 rounded-3 d-flex align-items-center" style={{ cursor: 'pointer' }}>
                                            <img src="/images/other-back-icon.svg" alt="other-back-icon" />
                                        </button>} */}
                                </li>
                                <li className="d-flex justify-content-between contactus-card-bg border-0 rounded-3 p-3 mb-4">
                                    <div>
                                        <h5 className="font-14 font-regular tab-grey-color">Location</h5>
                                        <p className="mb-0 font-16 font-semibold  sub-head-black">
                                            <span>
                                                <img src="/images/india-location-icon.svg" alt="india-location-icon" className="me-2" />
                                            </span>
                                            India
                                        </p>
                                    </div>
                                    <button type="button" onClick={() => { handleClick() }} className="btn white-btn py-2 px-3 rounded-3 d-flex align-items-center" style={{ cursor: 'pointer' }}>
                                        <img src="/images/other-back-icon.svg" alt="other-back-icon" />
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-8 col-md-6 col-sm-12">
                            <div className="card px-4 py-4 mt-0 mb-5 border-0 rounded-3 contactus-card-bg">
                                <div className="row">
                                    <h5 className="secondary-header mb-3">Contact Us</h5>
                                    <div className="col-lg-6 col-md-12 col-sm-12 mb-3">
                                        <label className="form-label font-14 font-semibold label-color label-color" htmlFor="Category">Category</label>
                                        <select className="form-select font-regular font-14 custom-form py-2" id="Category" aria-label="select" value={contactUsReq.selectedCategory} name="selectedCategory" onChange={(e) => { handleChange(e) }}>
                                            <option value="" disabled>Select</option>
                                            {bindDropDown()}
                                        </select>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                                        <label className="form-label font-14 font-semibold label-color label-color" htmlFor="Comments">Comments</label>
                                        <textarea rows={4} className="form-control custom-textarea custom-form font-14 font-regular resize-none" maxLength={1000} id="Comments" value={contactUsReq.comments} name="comments" placeholder="Enter Comments" defaultValue={""} onChange={(e) => { handleChange(e) }} />
                                    </div>
                                    <div className="d-md-flex d-grid gap-3 justify-content-unset justify-content-md-end mt-4 mb-2">
                                        <button type="button" onClick={() => { oncancel() }} className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center px-3 align-items-center me-2">
                                            Cancel
                                        </button>
                                        <button type="button" disabled={contactUsReq.selectedCategory.trim() === "" || contactUsReq.selectedCategory.toLowerCase() == "select" || contactUsReq.comments.trim() === ""} onClick={() => { setShowContactUs('Faq') }} className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center px-3 align-items-center">
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {alert ? <div
                className="modal fade show"
                tabIndex={-1}
                style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content popup-brd-radius">
                        <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                            <span className="position-absolute">
                                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
                            </span>
                        </div>
                        <div className="modal-body border-0 text-center">
                            <h5
                                className="modal-title mb-3 primary-header"
                                id="Failure-popupLabel"
                            >
                                {alertMessage}
                            </h5>
                            <p className="primary-data">
                                {alert}
                            </p>
                        </div>
                        <div className="modal-footer border-0 pb-4 justify-content-center">
                            <button
                                type="button"
                                className="btn primary-btn font-14 px-4 font-semibold"
                                onClick={() => { 
                                    if (isSessionEpired) {
                                        history.push('/Login')
                                      }
                  
                                      setAlert(false) }}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div> : null}
            {showContactUs == 'Faq' && <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3 container-fluid container-height">
                {/* bread crumb starts here */}
                <ul className="breadcrumb-item mb-4 ps-0">
                    <li><a className="pe-0" onClick={() => { setShowContactUs('Contactus') }}>{"Contact Us"}</a></li>
                    <li className="pe-2">FAQs</li>
                </ul>
                {/* bread crumb ends here */}
                <h5 className="secondary-header mb-3">
                    <a onClick={() => { setShowContactUs('Contactus') }}><img src="/images/head-back-arrow.svg" style={{ cursor: 'pointer' }} alt="back-arrow" className="me-2" /></a>
                    FAQ's
                </h5>
                <p className="font-16 font-regular tab-grey-color mb-2">Need help with something? Here are our most frequently asked questions.</p>
                {/* accordion starts here */}
                <div className="col-md-12 mt-2 mb-3">

                    {bindFaq()}
                </div>
                <div className="form-check form-check-inline mb-3">
                    <input className="form-check-input cus-check-input" style={{ cursor: 'pointer' }} type="checkbox" name="inlineRadioOptions" id="questionrelates" defaultValue="option1" onClick={(e) => {
                        if (sendMail) {
                            setSendMail(false)
                        }
                        if (!sendMail) {
                            setSendMail(true)
                        }
                    }} />
                    <label className="form-check-label font-14 font-semibold" style={{ cursor: 'pointer' }} htmlFor="questionrelates">
                        These are the questions that relates to the query you chose, if you don't find any answers over here please select the checkbox to proceed further.
                    </label>
                </div>
                <div className="d-md-flex d-grid my-3 gap-3 justify-content-unset justify-content-md-end">
                    <button type="button" className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center" onClick={() => { setShowContactUs('Contactus') }}>
                        Cancel
                    </button>
                    <button type="button" className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center" disabled={sendMail} onClick={() => { handleSubmit() }}>
                        Send Email
                    </button>
                </div>
            </div>}
            {showContactUs == 'addFAQ' && 
            <div className='container-fuild container-height'>
            <div className="col-md-12">
                <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3 container-fluid container-height">
                    {/* bread crumb starts here */}
                    <ul className="breadcrumb-item mb-4 ps-0">
                        <li>
                            <a
                                className="pe-0"
                                onClick={() => {
                                    setShowContactUs('Contactus')
                                    history.push("/Contactus");
                                }}
                            >
                                Contact Us
                            </a>
                        </li>
                        <li className="pe-2">Configurations</li>
                    </ul>
                    {/* bread crumb ends here */}
                    <h5 className="secondary-header mb-4">
                        <a
                            onClick={() => {
                                setShowContactUs('Contactus')
                                history.push("/Contactus");
                            }}
                            style={{ cursor: 'pointer' }}
                        >
                            <img
                                src="/images/head-back-arrow.svg"
                                alt="back-arrow"
                                className="me-2"
                            />
                        </a>
                        Configurations
                    </h5>
                    <h5 className="secondary-header">FAQ's</h5>
                    <p className="font-16 font-regular mb-4 tab-grey-color">
                        Need help with something? Here are our most frequently asked
                        questions.
                    </p>
                    {/*form starts here */}
                    <h5 className="font-16 font-bold color-gray-v5 mb-4">
                        Add Question & Answer
                    </h5>
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12 mb-4">
                            <label
                                className="form-label font-14 font-semibold label-color label-color"
                                htmlFor="Question"
                            >
                                Question
                            </label>
                            <input
                                type="text"
                                className="form-control custom-form font-14 font-regular"
                                id="Question"
                                placeholder="Enter Question"
                                name="question"
                                value={faqRequest.question}
                                onChange={(e) => {
                                    handleOnchange(e);
                                  
                                }}
                                maxLength={1000}
                            />
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 mb-4">
                            <label
                                className="form-label font-14 font-semibold label-color label-color"
                                htmlFor="Category"
                            >
                                Category
                            </label>
                            <select
                                id="Category"
                                className="form-select custom-form pe-4"
                                aria-label="select"
                                value={faqRequest.category}
                                name="category"
                                onChange={(e) => {
                                    handleOnchange(e);
                                }}
                            >
                                <option value="" disabled>Select</option>
                                {bindDropDown()}
                            </select>
                        </div>
                        <div className="col-md-12 col-sm-12 mb-4">
                            <label
                                className="form-label font-14 font-semibold label-color label-color"
                                htmlFor="Answer"
                            >
                                Answer
                            </label>
                            <textarea
                                rows={4}
                                className="form-control custom-textarea custom-form font-14 font-regular resize-none"
                                id="Answer"
                                name="answer"
                                placeholder="Enter Answer"
                                value={faqRequest.answer}
                                onChange={(e) => {
                                    handleOnchange(e);
                                }}
                                disabled={true ? role.toLowerCase() === 'adminviewer' : false}
                                maxLength={1000}
                            />
                        </div>
                        <div className="d-md-flex d-grid gap-3 justify-content-unset justify-content-md-end mt-2 mb-4">
                            <button
                                type="button"
                                className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center px-3 align-items-center"
                                onClick={() => {
                                    addQuestionAndAnswer();
                                }}
                                disabled={areObjectsEqual(faqRequest, editFaqRequest) || faqRequest.answer.trim() == "" || faqRequest.category.trim() == "" || faqRequest.question.trim() == "" || faqRequest.category.toLowerCase() == "select"}
                            >
                                {updateButton == false ? 'Add' : 'Update'}
                            </button>
                        </div>
                        <h5 className="font-16 font-bold color-gray-v5 mt-2 mb-4">
                            Question & Answer
                        </h5>
                        <div className="col-md-12 col-sm-12 mb-2">
                            {loader && (
                                <div className="overlay">
                                    <div className="position-absolute top-50 start-50 translate-middle">
                                        <div
                                            className="spinner-border Loader loader-color align-center "
                                            role="status"
                                        >
                                            <span>
                                                <span className="loader-inner-circle" />
                                            </span>
                                        </div>
                                        <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                                            Loading..
                                        </p>
                                    </div>
                                </div>
                            )}
                            {bindQuestionAndAnswer()}
                        </div>
                    </div>
                </div>
            </div>
            </div>}
            {successPOPup && renderSuccessPopup()}
        </>
    )

}



