import { client } from "../Client/Client";
import { BE_URL, assessmentGrid } from "../Common/Constant";

   
  export async function insertScheduleMigration(selectedOptions: any) {
   
    const response = await client({
      method: 'POST',
       url: BE_URL + "/task/Migration/insertScheduleMigration",

     //  url: BE_URL + migrationEndpoint.scheduleMigration,
     // url:'http://localhost:8080/Migration/InsertScheduleMigration',
      data: selectedOptions
    });
    return response;
  };
   
  export async function startMigration(selectedOptions: any) {
   
    const response = await client({
      method: 'POST',
       url: BE_URL + "/task/Migration/StartMigration",

    //   url: BE_URL + migrationEndpoint.startOutlookMigration,
     // url:'http://localhost:8080/Migration/StartMigration',
      data: selectedOptions
    });
    return response;
  };
  
  
//  PS_186, PS_187, PS_188, PS_189, PS_190 
  export async function getMappedEntities(data: any) {
   
    const response = await client({
      method: 'POST',
       url: BE_URL + "/task/Migration/GetMappedEntities",
      //url:'http://localhost:8080/Migration/GetMappedEntities',

    //   url: BE_URL + migrationEndpoint.getMappedMailboxes, GetMappedMailboxes
      data: data
    });
    return response;
  };

  export async function getSharePointListsService(data: any) {
   
    const response = await client({
      method: 'POST',
       url: BE_URL + "/task/Migration/getSiteData",
     // url:'http://localhost:8080/Migration/getSiteData',

    //   url: BE_URL + migrationEndpoint.getMappedMailboxes, GetMappedMailboxes
      data: data
    });
    return response;
  };
   
//  PS_223, PS_224, PS_225, PS_226, PS_227, PS_228, PS_229, PS_230, PS_231, PS_232, PS_233, PS_234, PS_235
  export async function updateAction(data:any) {
    const response = await client({
      method: 'POST',
       url: BE_URL + "/task/Migration/UpdateAction",

     // url:'http://localhost:8080/Migration/updateAction',
    //   url: BE_URL + migrationEndpoint.updateUserAction,
      data: data
    });
    return response;
  }

  export async function getSourceEntitiesService(data:any) {
    const response = await client({
      method: 'POST',
      url: BE_URL + "/task/Migration/getSourceEntities",

      // url:'http://localhost:8083/Migration/updateUserActionOnMailbox',
    //   url: BE_URL + migrationEndpoint.updateUserAction,
      data: data
    });
    return response;
  }
   

  export async function insertLastVisitedPageValueApi(data: any) {

    const response = await client({
      method: 'POST',
      url: BE_URL + "/task/CommonMethods/InsertLastVisitedPage",
      // url:'http://localhost:8080/CommonMethods/InsertLastVisitedPage',

    //   url: BE_URL + lastVisitedEndpoint.postLastVisitedValue, 
      data: data
    });
    //console.log(response, "This is the reponse");
    return response;
  };

  // export async function fetchApplicationNames(data: any) {

  //   const response = await client({
  //     method: 'POST',
  //     // url: BE_URL + "/task/CommonMethods/GetApplicationNames",
  //     url:'http://localhost:8083/CommonMethods/GetApplicationNames',
  //   //   url: BE_URL + assesmentEndpoint.fetchApplicationNames,
  //     data: data
  //   });
  //   //console.log(response, "This is the reponse");
  //   return response;
  // };
  export async function fetchApplicationNames(data:object) {//PS_AD-43
    let config = {//PS_AD-44
        method: 'POST',
        // url: ADMIN_BE_URL + adminLoginEndpoint.getAdminCardsDataUrl,
         url: BE_URL + assessmentGrid.fetchApplicationNames,
        //url: 'http://localhost:8080/viewAssesment/fetchApplicationNames',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    }
    const response = await client(config);//PS_AD-45
    return response;//PS_AD-50
};

export async function updateSelectedLists(data:object) {//PS_AD-43
  let config = {//PS_AD-44
      method: 'POST',
      // url: ADMIN_BE_URL + adminLoginEndpoint.getAdminCardsDataUrl,
       url: BE_URL + assessmentGrid.fetchApplicationNames,
      //url: 'http://localhost:8080/Migration/updateSelectedLists',
      headers: {
          'Content-Type': 'application/json'
      },
      data: data
  }
  const response = await client(config);//PS_AD-45
  return response;//PS_AD-50
};