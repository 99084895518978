import { client } from "../Client/Client";
import { appSpecificConfig, BE_URL, dashboard } from "../Common/Constant";
 
export async function postOutlookCustomization(selectedOptions:object) {
 
    const response = await client({
      method: 'POST',
    // url: 'http://localhost:8080/appSpecificConfig/handleInsertAppConfig',
      url: BE_URL + appSpecificConfig.postOutlookCustomization,
      data: selectedOptions
    });
    return response;
  };

  export async function postCustomization(selectedOptions:object) {
 
    const response = await client({
      method: 'POST',
      //url: 'http://localhost:8080/appSpecificConfig/handleInsertAppConfig',
       url: BE_URL + appSpecificConfig.handleInsertAppConfig,
      data: selectedOptions
    });
    return response;
  };

  export async function fetchPreferencesData(data:object) {
 
    const response = await client({
      method: 'POST',
    // url: 'http://localhost:8080/appSpecificConfig/fetchPreferencesData',
     url: BE_URL + appSpecificConfig.fetchPreferencesData,
      data: data
    });
    return response;
  };

  export async function postPreferencesData(data:object) {
 
    const response = await client({
      method: 'POST',
    // url: 'http://localhost:8080/appSpecificConfig/postPreferencesData',
     url: BE_URL + appSpecificConfig.postPreferencesData,
      data: data
    });
    return response;
  };

  export async function fetchUserOptionsFromDatabase(data:object) {
 
    const response = await client({
      method: 'POST',
     // url: 'http://localhost:8080/appSpecificConfig/fetchUserOptionsFromDatabase',
      url: BE_URL + appSpecificConfig.fetchUserOptionsFromDatabase,
      data: data
    });
    return response;
  };

  export async function fetchOptionsFromDatabase(data:object) {
 
    const response = await client({
      method: 'POST',
    // url: 'http://localhost:8080/appSpecificConfig/fetchOptionsFromDatabase',
     url: BE_URL + appSpecificConfig.fetchOptionsFromDatabase,
      data: data
    });
    return response;
  };