// PS_01, PS_02, PS_03, PS_04, PS_05, PS_08
import React, { useState, useEffect } from 'react';
import objCryptoJS from 'crypto-js';
// import commonService from '/commonServices';
import { useLocation, useHistory } from 'react-router-dom';
import Loader from '../Common/Loader';
import { sessionResponse } from '../../Common/Interface';
// import { payloadInterface } from '../Interface'; // Replace any and any with actual interface names as needed
import { deletenotify, fetchNotifyDataApi } from "../../Services/psConfigServices";//PS_AD-04
import { decodeSessionDetails } from '../../Services/CommonServices';
import { insertLastVisitedPageValueApi } from '../../Services/MigrationAndManageMigrationServices';
interface NotificationConfigurationProps {
    notification: { email: string };
    setNotification: React.Dispatch<React.SetStateAction<{ email: string }>>;
    emailTouched: boolean;
    setEmailTouched: React.Dispatch<React.SetStateAction<boolean>>;
    sourceDataError: {
        notification: string;
        sourceAccountEmail: string;
        sourceAccountPassword: string;
        targetAccountEmail: string;
        targetAccountPassword: string;
        sourceServiceAccount: string;
        targetServiceAccount: string;
        sourceServiceAppId: string;
        sourceServiceAppSecret: string;
        targetServiceAppId: string;
        targetServiceAppSecret: string;
        sourceServiceAccountLength: string,
        targetServiceAccountLength: string,
        sourceServiceAppLength: string;
        targetServiceAppLength: string;
        showPopupMessage: string;
        invalidEmailAddress: string;
        SourceAzureAppId: string;
        SourceAzureSecretId: string;
        emailMaxLengthError: string;
    };
    setsourceDataError: React.Dispatch<React.SetStateAction<{
        notification: string;
        sourceAccountEmail: string;
        sourceAccountPassword: string;
        targetAccountEmail: string;
        targetAccountPassword: string;
        sourceServiceAccount: string;
        targetServiceAccount: string;
        sourceServiceAppId: string;
        sourceServiceAppSecret: string;
        targetServiceAppId: string;
        targetServiceAppSecret: string;
        sourceServiceAccountLength: string,
        targetServiceAccountLength: string,
        sourceServiceAppLength: string;
        targetServiceAppLength: string;
        showPopupMessage: string;
        invalidEmailAddress: string;
        SourceAzureAppId: string;
        SourceAzureSecretId: string;
        emailMaxLengthError: string;
    }>>;
    sourceDataErrorMessage: {
        notification: string;
        sourceAccountEmail: string;
        sourceAccountPassword: string;
        targetAccountEmail: string;
        targetAccountPassword: string;
        sourceServiceAccount: string;
        targetServiceAccount: string;
        sourceServiceAppId: string;
        sourceServiceAppSecret: string;
        targetServiceAppId: string;
        targetServiceAppSecret: string;
    };
    setsourceDataErrorMessage: React.Dispatch<React.SetStateAction<{
        notification: string;
        sourceAccountEmail: string;
        sourceAccountPassword: string;
        targetAccountEmail: string;
        targetAccountPassword: string;
        sourceServiceAccount: string;
        targetServiceAccount: string;
        sourceServiceAppId: string;
        sourceServiceAppSecret: string;
        targetServiceAppId: string;
        targetServiceAppSecret: string;
    }>>;
    roleCheck: string;
    notifications: any; // Adjust the type based on your actual data structure
    setNotifications: React.Dispatch<React.SetStateAction<{ email: string }[]>>;
    notificationSAC: boolean;
    setNotificationSAC: React.Dispatch<React.SetStateAction<boolean>>;
    handleSaveAndContinueNotification: () => void;
    nestedTab: string;
    notiHideTab: string;
    taskId: any;

}
const NotificationConfiguration: React.FC<NotificationConfigurationProps> = ({
    notification,
    setNotification,
    setNotifications,
    emailTouched,
    setEmailTouched,
    sourceDataErrorMessage,
    setsourceDataErrorMessage,
    sourceDataError,
    setsourceDataError,
    roleCheck,
    notifications,
    notificationSAC,
    setNotificationSAC,
    handleSaveAndContinueNotification,
    nestedTab,
    notiHideTab,
    taskId
}) => {

    // PS_09, PS_10, PS_11, PS_12, PS_13, PS_14, PS_15, PS_16, PS_17, PS_18, PS_19, PS_20, 
    // PS_21, PS_22, PS_23, PS_24, PS_25, PS_26, PS_27, PS_28, PS_29, PS_30, PS_31
    const location = useLocation();
    const [showPopup, setShowPopup] = useState<boolean>(false);

    const [deleteEmail, setDeleteEmail] = useState<string>('');
    const [deleteTab, setDeleteTab] = useState<string>('');
    const clientId = "2"
    const [index, setIndex] = useState<number>();
    const [DeletePopup, setDeletePopup] = useState<boolean>(false);
    const [mainNewTab, setMainNewTab] = useState<string>('notifications');
    const [loader, setLoader] = useState<boolean>(false)
    // const isOpen = true;
    const [alert, setAlert] = useState<boolean>(false);

    let history = useHistory()
    const [deleteNotifyAccount, setdeleteNotification] = useState<any[]>([]);
    const [ctoken, setCToken] = useState<string>(sessionStorage.getItem('clientToken') || '');
    const [tokenExpiration, setTokenExpiration] = useState<number>(parseInt(sessionStorage.getItem('tokenExpiration') || '0'));
    const [atoken, setAToken] = useState<string>(sessionStorage.getItem('a_at') || '');
    const [isAdmin, setIsAdmin] = useState<boolean>(sessionStorage.getItem('isAdmin') === 'true');
    const [objAdmin, setObjAdmin] = useState({})
    const [objClient, setObjClient] = useState({})
    let [adminName, setAdminName] = useState('');
    let [adminEemailAddress, setAdminEmailAddress] = useState('');
    let [adminrole, setAdminRole] = useState('');
    let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
    let [clientName, setclientName] = useState('');
    let [companyName, setCompanyName] = useState('');
    let [emailAddress, setEmailAddress] = useState('');
    let [role, setRole] = useState('');
    const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
    const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
    const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
    const [emailValid, setEmailValid] = useState<boolean>(true);




    // PS_32, PS_33

    useEffect(() => {
        sessionTokenProperties()
        fetchDataNotify()

    }, []);

    // PS_34, PS_35, PS_36, PS_37, PS_38, PS_39, PS_40, PS_41
    let sessionTokenProperties = async () => {
        try {
            const tokenResponse: sessionResponse = await decodeSessionDetails();
            debugger
            if (tokenResponse.adminDetails) {
                if (tokenResponse.adminDetails) {
                    setObjAdmin(tokenResponse.adminDetails)
                    setAdminName(tokenResponse.adminDetails.Name);
                    setAdminEmailAddress(tokenResponse.adminDetails.Email);
                    setAdminRole(tokenResponse.adminDetails.Role);
                }
                else {
                    if (window.location.pathname.toString().toLowerCase().includes("admin")) {
                        history.push("/Admin/Login")
                    }
                }
            }
            else {
                if (tokenResponse.clientDetails) {
                    setObjClient(tokenResponse.clientDetails)
                    setclientName(tokenResponse.clientDetails.Name);
                    setCompanyName(tokenResponse.clientDetails.CompanyName)
                    setEmailAddress(tokenResponse.clientDetails.Email);
                    setRole(tokenResponse.clientDetails.Role);
                }
                else {
                    history.push("/Login")
                }
            }
            //#endregion
        } catch (error) {
            setAlert(true);
            setLoader(false)

            console.error('Failed in main method', error);
        }
    };


    const getToken = async () => {
        let clientToken: string;
        let adminToken: string;
        let token: { clientToken?: string; adminToken?: string } = {};
        debugger
        try {
            if (objClient !== undefined) {
                let sessionToken: any = sessionStorage.getItem('c_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
                    token['clientToken'] = clientToken

                }


            }
            if (objAdmin !== undefined) {
                let sessionToken: any = sessionStorage.getItem('a_at')
                if (sessionToken) {
                    sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
                    var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
                    adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

                    token['adminToken'] = adminToken

                }
            }
            return token
        } catch (error) {
            setAlert(true);
            setLoader(false)

            throw error
        }
    }
    //console.log(atoken, "admin token daaaaa");
    //console.log(ctoken, "client token daaaaa");

    // Function to validate if an email is valid
    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    // PS_66, PS_67, PS_68, PS_69, PS_70
    const handleClick = (index: any, tab: string, email: string) => {
        try {
            //console.log("handleclick inside");
            setIndex(index)
            setDeleteTab(tab)
            setDeleteEmail(email)
            setDeletePopup(true)
        } catch (error: any) {
            setAlert(true);
            setLoader(false)

        }
    };

    // PS_72, PS_73, PS_74, PS_75, PS_76, PS_93, PS_94, PS_95, PS_96, PS_97, PS_98, PS_99, PS_100, PS_101
    const handleDeleteAccount = async (index: any, tab: string, email: string) => {
        try {
            let token = await getToken()

            if (tab === 'Notifications') {
                setDeletePopup(false)
                if (notifications?.length === 1) {
                    setDeletePopup(false)
                    setLoader(true)
                    const payload = {
                        emailId: email,
                        token: token,
                        taskId: taskId,
                    };
                    const response = await deletenotify(payload);
                    setLoader(false)

                }
                const updatedAccounts = [...notifications];
                updatedAccounts.splice(index, 1);
                setNotifications(updatedAccounts);
                setdeleteNotification([...deleteNotifyAccount, { email }]);
            }
        } catch (error: any) {
            setAlert(true);
            setLoader(false)


        }
    };

    // PS_57, PS_58, PS_59, PS_60, PS_61, PS_62, PS_63, PS_64, PS_65
    const handleAddAccount = async (tab: string, notiHideTab: string) => {
        try {

            if (tab === 'Notifications') {
                const trimmedEmail = notification?.email.trim();
                if (emailValid && trimmedEmail) {
                    if (notifications.some((account: { email: any; }) => account.email === trimmedEmail)) {

                        setsourceDataError({
                            ...sourceDataError,
                            notification: 'Email already exists.',
                        });
                    }

                    else {
                        setNotifications([...notifications, { email: notification.email }]);
                        setNotification({ email: '' });
                        setsourceDataError({ ...sourceDataError, notification: '' });
                    }

                } else {
                    // If email is empty, set validation error
                    setsourceDataError({ ...sourceDataError, notification: sourceDataErrorMessage.notification });
                    setEmailValid(false)
                }
                if (notiHideTab.toLowerCase() === 'hide') {
                    handleSaveAndContinueNotification();
                }
            }
        } catch (error: any) {
            setLoader(false)
            setAlert(true);
        }
    }

    // PS_42, PS_43, PS_44, PS_45, PS_50, PS_51, PS_52, PS_53, PS_54, PS_55, PS_56
    const handleInputChange = (e: any, tab: string) => {
        try {
            const { name, value } = e.target;
            if (tab === 'Notifications') {
                setEmailValid(isValidEmail(value));
                setNotification({ ...notification, [name]: value });
                if (value.trim() === '') {
                    setsourceDataError({
                        ...sourceDataError,
                        notification: 'Email ID cannot be empty',
                    });
                }
                else if (notifications.some((account: { email: any; }) => account.email === value.trim())) {

                    setsourceDataError({
                        ...sourceDataError,
                        notification: 'The entered Email Id is already exist',
                    });
                }
                else if (value.trim().length >= 320) {
                    setsourceDataError({
                        ...sourceDataError,
                        notification: 'Maximum allowed charcters exceeded',
                    });
                } 
                else if (!isValidEmail(value)) {
                    setEmailValid(false);
                    setsourceDataError({
                        ...sourceDataError,
                        notification: 'The entered Email ID is invalid',
                    });
                }else {
                    setsourceDataError({
                        ...sourceDataError,
                        notification: '',
                    });
                }
            }
        } catch (erro: any) {
            setAlert(true);
            setLoader(false)

        }
    }
    const fetchDataNotify = async () => {
        try {
            let token = await getToken()

            setLoader(true)
            const payload = {

                token: token,
                taskId: taskId,
            }
            const result = await fetchNotifyDataApi(payload);
            setLoader(false)
            //console.log(result.data, "fetchhhhhhhh");

            if (result.data.data) {

                //console.log("inside if");

                const notificationsData = result.data.data.map((email: any) => ({
                    email: email
                }));

                setNotifications(notificationsData);
            }
        } catch (error) {
            setAlert(true);
            setLoader(false)

            //console.log('Error fetching data:', error);
        }
    };

    const insertLastVisitedPageValue = async (data: any) => {
        try {
            setLoader(true)
            let token = await getToken()

            const payload = {
                token: token,
                taskId: taskId,
                lastVisitedValue: data
            };

            // Insert only newly entered records into the database
            const response: any = await insertLastVisitedPageValueApi(payload);
setLoader(false)
            if (!response) {
                //console.log('The current page value failed to insert');
            } else {
                //console.log('The current page value inserted successfully');

            }
        } catch (error) {
            console.error('Error inserting records:', error);
        }
    };

    return (
        <div className="col-md-12 mt-4 mt-md-8">
            {/*Third Tab - Notification Starts here*/}
            {mainNewTab === 'notifications' && (notiHideTab === 'show' || notiHideTab === 'hide') ? (
                <div
                    className="tab-pane fade show active"
                    id="v-pills-notifications"
                    role="tabpanel"
                    aria-labelledby="v-pills-notifications-tab"
                >
                    <h5 className="font-20 font-bold mb-2">
                        Notifications
                    </h5>
                    <p className="font-14 font-regular mb-3">
                        The addition of mail IDs allows task updates to be sent to configured recipients via email. Stay informed about task progress and receive notifications directly in your inbox for efficient monitoring and management.
                    </p>
                    <div className="table-responsive theme-table bg-white">
                        <table className="table table-borderless mb-0">
                            <thead>
                                <tr>
                                    <th className="text-nowrap">Email Address</th>
                                    <th className="text-nowrap text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <input
                                            type="text"
                                            className="form-control custom-form font-14 font-regular w-50"
                                            placeholder="Enter Email Address"
                                            name="email"
                                            value={notification.email}
                                            onChange={(e) => handleInputChange(e, 'Notifications')}
                                            onBlur={() => setEmailTouched(true)}
                                            disabled={roleCheck.toLowerCase().includes('viewer')}
                                            maxLength={320}
                                        />
                                        <span className="mandatory-color">
                                            {sourceDataError.notification}
                                        </span>
                                        {emailTouched && !emailValid && notification.email && sourceDataError.notification === '' && (
                                            <span className="mandatory-color">
                                                {sourceDataError.invalidEmailAddress}
                                            </span>
                                        )}
                                    </td>
                                    <td className="text-center">
                                        <span className="d-inline-block">
                                            <button
                                                disabled={roleCheck.toLowerCase().includes('viewer')}
                                                onClick={(e) => { handleAddAccount('Notifications', notiHideTab); setNotificationSAC(false); }}
                                                className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center"
                                            >
                                                <img
                                                    src="/images/blue-plus-icon.svg"
                                                    alt="blue-plus-icon"
                                                />
                                            </button>
                                        </span>
                                    </td>
                                </tr>
                                {notifications.slice().sort((a: { timestamp: any; }, b: { timestamp: any; }) => {
                                    const timestampA = a.timestamp; // or whatever the property is that contains the timestamp
                                    const timestampB = b.timestamp;
                                    if (timestampA < timestampB) return -1;
                                    if (timestampA > timestampB) return 1;
                                    return 0;
                                }).reverse().map((acc: any, index: any) => (
                                    <tr key={index}>
                                        <td>
                                            <span
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="right"
                                                title={acc.email}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {acc.email.length > 22 ? acc.email.slice(0, 22) + "..." : acc.email}
                                            </span>
                                        </td>
                                        <td className="text-center">
                                            <span className="d-inline-block">
                                                <button
                                                    onClick={() => handleClick(index, 'Notifications', acc.email)}
                                                    disabled={roleCheck.toLowerCase().includes('viewer')}
                                                    className="btn action-rounded-btn primary-action d-flex align-items-center justify-content-center"
                                                >
                                                    <img src="/images/red-delete-icon.svg" alt="delete-icon" />
                                                </button>
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {notiHideTab.toLowerCase() === 'show' && (
                        <div className="d-flex justify-content-end mt-3">
                            <button
                                disabled={(notificationSAC) || roleCheck.toLowerCase().includes('viewer')}
                                onClick={() => {
                                    handleSaveAndContinueNotification();
                                    insertLastVisitedPageValue("GeneralConfigurations-errorHandling")
                                
                                }}
                                className="btn primary-btn text-nowrap font-semibold text-white font-14 d-flex gap-2 justify-content-center align-items-center"
                            >
                                Save &amp; Continue
                            </button>
                        </div>
                    )}
                </div>
            ) : null}
            {/*Third Tab - Notification Ends here*/}

            {/* </div> */}
            {/* </div> */}
            {loader && (
    <div className="overlay overlay-front">
      <div className="position-absolute top-50 start-50 translate-middle">
        <div
          className="spinner-border Loader loader-color align-center "
          role="status"
        >
          <span>
            <span className="loader-inner-circle" />
          </span>
        </div>
        <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
          Loading..
        </p>
      </div>
    </div>

  )}


            {showPopup === true ? (
                <div className="modal fade show" id="Failure-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="Failure-popupLabel" aria-modal="true" role="dialog" style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}>

                    <div className="modal-dialog modal-dialog-centered">

                        <div className="modal-content popup-brd-radius">

                            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">

                                <span className="position-absolute">

                                    <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />

                                </span>

                            </div>

                            <div className="modal-body border-0 text-center">

                                <h5 className="modal-title mb-3 primary-header" id="Failure-popupLabel">Failure</h5>

                                <p className="primary-data">{sourceDataError.showPopupMessage}</p>

                            </div>

                            <div className="modal-footer border-0 pb-4 justify-content-center">

                                <button type="button" onClick={() => setShowPopup(false)} className="btn primary-btn font-14 px-4 font-semibold" data-bs-dismiss="modal">Retry</button>

                            </div>

                        </div>

                    </div>

                </div>) : null}
            {DeletePopup === true ? (
                <div
                    className="modal fade show"
                    id="Failure-popup"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1} // Change this line
                    aria-labelledby="Failure-popupLabel"
                    aria-modal="true"
                    role="dialog"
                    style={{ display: "block", backgroundColor: "rgba(0,0,0,0.8)" }}
                >
                    <div className="modal-dialog modal-dialog-centered">

                        <div className="modal-content popup-brd-radius">

                            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">

                                <span className="position-absolute">

                                    <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />

                                </span>

                            </div>

                            <div className="modal-body border-0 text-center">

                                <h5 className="modal-title mb-3 primary-header" id="Failure-popupLabel">Alert</h5>

                                <p className="primary-data">Are you sure want to delete?</p>

                            </div>

                            <div className="modal-footer border-0 pb-4 justify-content-center">

                                <button type="button" onClick={() => {
                                    handleDeleteAccount(index, deleteTab, deleteEmail)
                                }}
                                    className="btn primary-btn font-14 px-4 font-semibold" data-bs-dismiss="modal">Yes</button>
                                <button type="button" onClick={() => {
                                    setDeletePopup(false)

                                }}
                                    className="btn primary-btn font-14 px-4 font-semibold" data-bs-dismiss="modal">No</button>

                            </div>

                        </div>

                    </div>

                </div>) : null}
        </div>

    )

};

export default NotificationConfiguration;