import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { ConnectionDataInterface, ConnectionDataErrorInterface, TaskPayload, sessionResponse } from '../../Common/Interface';
import objCryptoJS from "crypto-js";
import { getDropDownConnections, getConnectionForTask, checkAndCreateTask, generateBlobSaasTokenApi } from '../../Services/ConnectionSevice';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
import { saveAs } from 'file-saver';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import { decodeSessionDetails } from '../../Services/CommonServices';
import jwt from 'jwt-decode'
import { getSession } from '../../Services/manageClientAPI';
import { log } from 'console';

export default function ConnectionComponent() {
  let location: any = useLocation().state;


  const history = useHistory();

  const stage = 2;


  console.log("location ", location.state);
  
//PS_1 to 61

  const [loader, setLoader] = useState(false);
  // Assuming context and structure is provided since pseudocode does not detail these
  let connectionDataObj: ConnectionDataInterface = {
    connectionName: '',
    adminEmail: '',
    azureAppId: '',
    azureAppSecreteId: '',
    connectionsId: '',
    serviceAccountKey: '',
    tenantId: '',
    serviceEmailId: '',
    Environment: location.state.environment,
    userRefreshToken: '',
  };


  let connectionDataObjError: ConnectionDataErrorInterface = {
    connectionName: '',
    adminEmail: '',
    azureAppId: '',
    azureAppSecreteId: '',
    // tenantId: '',
    serviceAccountKey: '',
    serviceEmailId: '',
  };

  let connectionDataErrorMessage: ConnectionDataErrorInterface = {
    connectionName: "Please enter valid tenant name",
    adminEmail: "Admin email cannot be empty",
    azureAppId: "Azure App Id cannot be empty",
    azureAppSecreteId: "Azure App Secret Value cannot be empty",
    // tenantId: "Tenant Id cannot be empty",
    serviceAccountKey: "Service Account Key cannot be empty",
  };

  let regexmessage: ConnectionDataErrorInterface = {
    connectionName: "Enter valid connetion name",
    adminEmail: "Please enter valid Email Address.",
    azureAppId: "Enter valid Azure App Id",
    azureAppSecreteId: "Enter valid Azure App Secret Value",
    // tenantId: "Enter valid Tenant ID",
    serviceAccountKey: "Enter valid Service Account Key",
  };

  // Skipping the evaluation of taskType as the context is not provided
  // Assuming 'location.state' has a 'taskType' and 'applicationName' properties
  // Due to the lack of context we cannot exactly implement this

  //const isAssessment = true;


  const isAssessment = location?.state?.taskType?.toLowerCase() == 'assessment' ? true : false;
  const applicationName = location?.state?.applicationName ? location?.state?.applicationName : '';
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [connections, setConnections] = useState<ConnectionDataInterface[]>([]);
  const [sourceConnections, setSourceConnections] = useState<ConnectionDataInterface[]>([]);
  const [targetConnections, setTargetConnections] = useState<ConnectionDataInterface[]>([]);
  const [oldConnections, setOldConnections] = useState<ConnectionDataInterface[]>([]);
  const [connectionData, setConnectionData] = useState<ConnectionDataInterface>(connectionDataObj);
  const [sourceConnectionData, setSourceConnectionData] = useState<ConnectionDataInterface>(connectionDataObj);
  const [targetConnectionData, setTargetConnectionData] = useState<ConnectionDataInterface>(connectionDataObj);
  const [showSourceForm, setShowSourceForm] = useState(false);
  const [showTargetForm, setShowTargetForm] = useState(false);
  const [connectionDataError, setConnectionDataError] = useState<ConnectionDataErrorInterface>(connectionDataObjError);
  const [errorMessage, setErrorMessage] = useState('');
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [regexErrorMessage, setRegexErrorMessage] = useState<ConnectionDataErrorInterface>(regexmessage);
  const [searchVal, setSearchVal] = useState('');
  const [disableFields, setDisableFields] = useState(false);
  const [sourceConnectionId, setSourceConnectionId] = useState('');
  const [targetConnectionId, setTargetConnectionId] = useState('');
  const [isSourceConnection, setIsSourceConnection] = useState(true);
  const [isSourceVerified, setIsSourceVerified] = useState(false);
  const [imageSource, setImageSource] = useState('');
  const [selectedSourceConnection, setSelectedSourceConnection] = useState('Select');
  const [selectedTargetConnection, setSelectedTargetConnection] = useState('Select');
  const [successPopup, setSuccessPopup] = useState(false);
  const [alert, setAlert] = useState('');
  const [disableSourceFields, setDisableSourceFields] = useState(false);
  const [disableTargetFields, setDisableTargetFields] = useState(false);
  const [fileInputValue, setFileInputValue] = useState<string>("");
  const [modernOath, setModernOath] = useState<boolean>(false);
  const [eventProcessed, setEventProcessed] = useState(false);
  // Skipping objClient creation and session variable as context is missing
  // Assuming these variables are used in the component but the initialization details are missing
  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';

  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})

  let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
  let [clientName, setclientName] = useState('');
  let [companyName, setCompanyName] = useState('');
  let [emailAddress, setEmailAddress] = useState('');
  let [isAdmin, setIsAdmin] = useState(false);
  let [adminName, setAdminName] = useState('');
  let [adminEemailAddress, setAdminEmailAddress] = useState('');
  let [adminrole, setAdminRole] = useState('');
  const [role, setRole] = useState<string>('');

  const [taskId, setTaskId] = useState<number>(location?.state?.taskId);

  const [taskName, setTaskName] = useState<string>(location?.state?.taskName);

  const [isEdit, setIsEdit] = useState<boolean>(location?.state?.isEdit ? true : false);

  const [token, setToken] = useState<object>({});

  const [hasPayloadRun, setHasPayloadRun] = useState(false);

  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  let isPoupClosedAfterSuccess = false;
  let InActiveTimeout: NodeJS.Timeout;



  const applicationImages: Record<string, string> = {
    outlook: '/images/outlook.svg',
    sharedmailbox: 'path/to/shared-mailbox-icon.png',
    roomsandequipments: 'path/to/rooms-equipment-icon.png',
    teamschannel: 'path/to/teams-logo.png',
    gmail: '/images/gmail.svg',
    grive: '/images/my drive.svg',
  };


//PS_60 to 74
  // Managing source and target connections
  useEffect(() => {
    debugger
    if (isSourceConnection) {

      if (Object.keys(sourceConnectionData).every((key) => {
        // Check that the property is not 'email' and also not an empty string
        if (key !== 'adminEmail') {
          return sourceConnectionData[key as keyof ConnectionDataInterface] !== '';
        }
        return true; // If the property is 'email', it's automatically considered valid
      })) {
        storeMapping(sourceConnectionData);
      }

      if (!isEdit) {
        setConnections(sourceConnections);
        setOldConnections(sourceConnections);
        if (!isSourceVerified) {
          setShowSourceForm(true);
        }
      }
    }
    else {

      if (Object.keys(targetConnectionData).every((key) => {
        // Check that the property is not 'email' and also not an empty string
        if (key !== 'adminEmail') {
          return targetConnectionData[key as keyof ConnectionDataInterface] !== '';
        }
        return true; // If the property is 'email', it's automatically considered valid
      })) {
        storeMapping(targetConnectionData);
      }
      if (!isEdit) {
        setConnections(targetConnections);
        setOldConnections(targetConnections);
        setShowTargetForm(true);
      }
    }
  }, [isSourceConnection]);



  useEffect(() => {


    // location?.state.environment === location?.state.environment
    const responseRedirect = (event: any) => {
      debugger;
    
      if (event.data.authorization == `${process.env.REACT_APP_FE_CONNECTORS_URL}/Redirect` && !eventProcessed) {

        let dePass = event.data.authorizationCode;
        let clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
        let data = connectionData
        setConnectionData(prevState => ({
          ...prevState,
          userRefreshToken: clientToken,
          Environment: location?.state?.environment,
        }));
        data = connectionData

        console.log("useeffect Connectioon Data :", connectionData);
        // checkAndCreateTaskBAckEndTrigger();
        
      }
      
    };

    // setConnectionData((prevState) => ({
    //   ...prevState,
    
    // }));

    setIsSourceVerified(false);
    sessionTokenProperties();
    resetTimer();

    if (location?.state?.taskType?.toLowerCase() == 'migration') {
      setAppImage(location?.state?.applicationName[0]);
    }
    if (!taskId) {
      getConnections();
    }

    if (taskId && isEdit) {
      setLoader(true);
      setDisableSourceFields(true);
      setDisableTargetFields(true);
      getConnectionEdit();
      //file.name = "Service Account Key";
    }
    window.addEventListener("message", responseRedirect);
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);

    return () => {
      window.removeEventListener("message", responseRedirect);
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };

  }, []);
//PS_225 to 238
  useEffect(() => {
    debugger
   
    if (connectionData?.userRefreshToken && !eventProcessed) {
      setEventProcessed(true);
      // triggerBE = false;
      debugger;

      setLoader(true)

      console.log("connectionData inside useEffect", connectionData);

      debugger;

      let forward;
      setLoader(true)
      checkAndCreateTaskBAckEndTrigger(connectionData, forward = true);

    }

  }, [connectionData])


  let resetTimer = async () => {
    try {
      // ;
      //console.log("Reset Timer Start");

      let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
      //console.log("sessionResponse value :", sessionResponse);

      // //
      if (sessionResponse == 'expired') {

        sessionStorage.removeItem("c_at");
        setisSessionEpired(true)
        setAlert('Your Session has Expired please login again to continue');
      }
      else if (sessionResponse == 'updateToken') {
        if (emailAddress == "" || emailAddress == undefined) {
          await sessionTokenProperties();
        }

        let token = await getToken()
        let payload = {
          mailId: emailAddress,
          isAdmin: false,
          token: token
        };
        let getsessionToken = await getSession(payload);
        const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
        const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

        const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
        const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
        let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
        await sessionStorage.removeItem("c_at");
        await sessionStorage.setItem('c_at', updatedToken);
        let updatedobjClient: any = jwt(c_decryptedValue);
        SessionEpiredDateTime = updatedobjClient.CreatedAt;
        setSessionEpiredDateTime(SessionEpiredDateTime);
      }
      //console.log("Reset Timer End");
    }
    catch (error) {
      //console.log("Reset Timer Catch");
      setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
      setLoader(false);
    }
  }

  const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
    try {
      // 
      //console.log("Reset Session Timer Start");
      // Assuming InActiveTimeout is a number representing the ID of the current timeout

      if (typeof window !== 'undefined') {
        //console.log("Reset Session Timer Inside");
        clearTimeout(InActiveTimeout);
        const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

        InActiveTimeout = setTimeout(() => {
          //console.log("Expired");
          sessionStorage.removeItem("c_at");
          //console.log("removed session");
          setisSessionEpired(true)
          setAlert('Your Session has Expired please login again to continue');
          // return 'expired'
        }, tokenExpiryCheckInterval);

        //console.log("Reset Session Timer checking");

        const currentDateTime = new Date().toISOString();
        const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
        //console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

        const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
        //console.log("diffInMilliSeconds values:", diffInMilliseconds);

        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        //console.log("minutes : ", minutes);

        const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
        //console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

        if (minutes > sessionUpdateTimeGap) {

          //console.log("Reset Session Timer need to update");
          return 'updateToken'
        }

        //console.log("Reset Session Timer End");
        return 'NoActionRequired'
      }
      return 'NoActionRequired'
    }
    catch (ex) {
      //console.log("Reset Session Timer Catch");
      throw ex
    }
  };


  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: any = await decodeSessionDetails();

      if (tokenResponse.clientDetails) {
        setObjClient(tokenResponse.clientDetails)
        setclientName(tokenResponse.clientDetails.Name);
        setCompanyName(tokenResponse.clientDetails.CompanyName)
        emailAddress = tokenResponse.clientDetails.Email;
        setEmailAddress(emailAddress);
        setRole(tokenResponse.clientDetails.Role);
        setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
      }
      else {
        // history.push("/Login")
      }
      //#region only for required Modules
      if (tokenResponse.adminDetails) {
        setObjAdmin(tokenResponse.adminDetails)
        setAdminName(tokenResponse.adminDetails.Name);
        setAdminEmailAddress(tokenResponse.adminDetails.Email);
        setRole(tokenResponse.adminDetails.Role);
      }
      // else {
      //     history.push("/Admin/Login")
      // }
      //#endregion
    } catch (error) {
      console.error('Failed in main method', error);
    }
  };

  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};

    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = clientToken

        }


      }
      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

          token['adminToken'] = adminToken

        }
      }
      return token
    } catch (error) {
      throw error
    }
  }
//PS_85 to 92
  const setAppImage = (appName: string) => {
    const imageSrc = applicationImages[appName] || '/images/outlook.svg';
    setImageSource(imageSrc);
  };

//PS_76 to 84
  // Define storeMapping function based on your pseudocode
  const storeMapping = (value: ConnectionDataInterface) => {
    setConnectionData(value);
    setEventProcessed(true);
    setConnectionDataError(connectionDataObjError);

    if (isSourceConnection) {

      setSourceConnectionId(value.connectionsId);
      setSelectedSourceConnection(value.connectionName);
      if (!sourceConnectionId)
        setDisableSourceFields(true)
    } else {
      setTargetConnectionId(value.connectionsId);
      setSelectedTargetConnection(value.connectionName);
      setDisableTargetFields(true);
    }
  };

//PS_93 to 108
  const getConnections = async () => {

    setLoader(true);
    try {
      let token = await getToken()

      const payload = {
        token,
        isAssessment,
        Environment: location?.state.environment
      };


      const response = await getDropDownConnections(payload);
      console.log("check data", response?.data.data.sourceData[0])

      if (response && response.data) {
        if (isSourceConnection) {
          setConnections(response.data.data.sourceData);
          setOldConnections(response.data.data.sourceData);
          setSourceConnections(response.data.data.sourceData);
          setTargetConnections(response.data.data.targetData);
        }

      }
    } catch (error: any) {
      setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
    } finally {
      setLoader(false);
    }
  };

  //PS111 to 128
  const getConnectionEdit = async () => {
    // Set loader state to true

    setLoader(true);
    try {
      let token = await getToken()

      // Payload creation
      const payload = {
        token: token, // Replace with actual token retrieval logic
        taskId: taskId, // Replace with actual task ID
        isAssessment: isAssessment, // Adjust as per your requirements
      };


      // Handling the service call
      const responseData: any = await getConnectionForTask(payload);

      // Assuming responseData contains an array
      setSourceConnectionData(responseData.data.data.sourceData);

      setConnectionData(responseData.data.data.sourceData)

      if (!isAssessment) {
        setTargetConnectionData(responseData.data.data.targetData);
      }

      // Imagine storeMapping is a function that needs to be called
      // storeMapping(responseData.sourceConnection);

      // Hide loader
      setLoader(false);

      // Return data (not usually needed with stateful components but added as per pseudocode)
      return responseData;
    } catch (error) {
      // Handle error
      console.error(error);

      // Hide loader
      setLoader(false);

      // Return error (again, not common with stateful components but added for completeness)
      return error;
    }
  };

  const [file, setFile] = useState<string | any>("");
  const jsonRegex = /\.json$/i;
  const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

//PS_131 to 139
  const OnchangeconnectionData = (e: React.ChangeEvent<HTMLInputElement>) => {

    const { name, value } = e.currentTarget;
    console.log(name);
    const file1: any = e.target.files?.[0];
    if (name === 'serviceAccountKey') {
      setFile(file1);
      if (file1) {
        const reader = new FileReader();
        reader.onload = () => {
          const dePass = reader.result as string;
          const encrypted = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          setConnectionData(prevState => ({         // Save the content in state
            ...prevState,
            [name]: encrypted,
          }))
        };
        reader.readAsText(file1);
      }
    } else {
      setConnectionData(prevState => ({
        ...prevState,
        [name]: value,
      }))
    }
    // Resetting the connectionDataError state for the changed field
    setConnectionDataError(prevState => ({
      ...prevState,
      [name]: '',
    }));
    const connectionNameRegex = /^(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};:"\\|,.<>\/?]{1,80}$/;
    // Regex validations


    if (name && value.trim() === '') {
      if (name === 'connectionName' && value.trim() === '') {
        setConnectionDataError((prevState) => ({
          ...prevState,
          [name]: 'Connection Name cannot be empty',
        }));
      }
      if (name === 'tenantId' && value.trim() === '') {
        setConnectionDataError((prevState) => ({
          ...prevState,
          [name]: 'Tenant ID cannot be empty',
        }));
      }
      if (name === 'azureAppId' && value.trim() === '') {
        setConnectionDataError((prevState) => ({
          ...prevState,
          [name]: 'Azure App ID cannot be empty',
        }));
      }
      if (name === 'azureAppSecreteId' && value.trim() === '') {
        setConnectionDataError((prevState) => ({
          ...prevState,
          [name]: 'Azure App Secret Value cannot be empty',
        }));
      }
      if (name === 'serviceEmailId' && value.trim() === '') {
        setConnectionDataError((prevState) => ({
          ...prevState,
          [name]: 'Service Email ID cannot be empty',
        }));
      }
      if (name === 'adminEmail' && value.trim() === '') {
        setConnectionDataError((prevState) => ({
          ...prevState,
          [name]: 'Admin Email ID cannot be empty',
        }));
      }
    }
    else if (name === 'connectionName' && !(/^(?=.*[a-zA-Z])[a-zA-Z0-9]{1,80}$/.test(value))) {
      setConnectionDataError((prevState) => ({
        ...prevState,
        [name]: 'Enter valid Connection name',
      }));
    }
    else if (name === 'tenantId' && !(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(value))) {
      setConnectionDataError((prevState) => ({
        ...prevState,
        tenantId: 'Enter valid Tenant ID',
      }));
    } else if (name === 'azureAppId' && !(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(value))) {
      setConnectionDataError((prevState) => ({
        ...prevState,
        azureAppId: 'Enter valid Azure App ID',
      }));
    } else if (name === 'azureAppSecreteId' && !(/^[a-zA-Z0-9~._-]{40}$/.test(value))) {
      setConnectionDataError((prevState) => ({
        ...prevState,
        azureAppSecreteId: 'Enter valid Azure App Secret Value',
      }));
    } else if (name === 'serviceAccountKey' && !(jsonRegex.test(file1?.name))) {
      setConnectionDataError((prevState) => ({
        ...prevState,
        serviceAccountKey: 'Select the valid config file',
      }));
    } else if (name === 'serviceEmailId' && !(emailRegex.test(value))) {
      setConnectionDataError((prevState) => ({
        ...prevState,
        serviceEmailId: '"Provided Email ID is not an Service Email ID"',
      }));
    } else if (name === 'adminEmail' && !(emailRegex.test(value))) {
      setConnectionDataError((prevState) => ({
        ...prevState,
        adminEmail: '"Provided Email ID is not an Admin Email ID"',
      }));
    };
  }

//PS_190 to 202
  const resetToDefault = () => {
    // Reset source related states
    if (isSourceConnection) {
      setSourceConnectionId('');
      setSelectedSourceConnection('Select');
      setIsSourceVerified(false);
      setShowSourceForm(false); // Assuming we want to hide the form upon reset
      setDisableSourceFields(false);
    }
    else {
      // Reset target related states
      setTargetConnectionId('');
      setSelectedTargetConnection('Select');
      setShowTargetForm(false); // Assuming we want to hide the form upon reset
      setDisableTargetFields(false);
    }
    // Reset general connection data and error messages
    setConnectionData(connectionDataObj);
    setConnectionDataError(connectionDataObjError);

    // Clear other error messages if they exist
    // setConnectionServiceTsErrorMessage(''); // Uncomment if you have a state for service errors
    // setErrorMessage(''); // Uncomment if you have a state for general error messages
  };

//PS_218 to 224
  const openMicrosoftAuthWindow = async () => {
    try {
      console.log("openMicrosoftAuthWindow start");

      sessionStorage.setItem('R_Url', '/Connectors');

      let loaderContent: any = "Please wait. We are connecting the Teams settings";
      setLoader(loaderContent);

      const microsoftLoginUrlTemmplate = process.env.REACT_APP_MICROSOFT_AUTH_URL;
      let microsoftLoginUrl = microsoftLoginUrlTemmplate
        ? microsoftLoginUrlTemmplate
          .replace("{0}", encodeURIComponent(connectionData.azureAppId))
          .replace("{1}", encodeURIComponent(`${process.env.REACT_APP_FE_CONNECTORS_URL}/Redirect`))
        : "";

      const width = 500;
      const height = 600;
      const left = (window.innerWidth - width) / 2;
      const top = (window.innerHeight - height) / 2;

      const newWindow = window.open(microsoftLoginUrl, '_blank', `width=${width},height=${height},left=${left},top=${top}`);

      if (!newWindow) {
        setAlert("Popup has been blocked, enable it to authetnicate your account");
      } else {
        newWindow?.postMessage("message", `${process.env.REACT_APP_FE_CONNECTORS_URL}`)
        const checkWindowClosed = setInterval(() => {
          if (newWindow.closed) {
            clearInterval(checkWindowClosed);
            setLoader(false)
          }
        }, 1000); // Check every second
      }
      console.log("openMicrosoftAuthWindow end");
    } catch (error) {
      console.log("openMicrosoftAuthWindow catch");
      setAlert("Cannot able to connect")
    }
  }
// console.log(location.state, "state in connectionsss");

//PS_204 to 257
  const handleNext = async () => {
    try {
      debugger
      setEventProcessed(false);
      let token = await getToken()
      if (errorMessage) {
        setErrorMessage(''); // Clear out any existing error messages
      }

      if (isEdit) {
        if (isSourceConnection) {
          if (isAssessment) {
            history.push('/AssessmentConfigurations', {
              state: {
                ...location.state
              },
            });
            return;
          }
          else {
            setIsSourceVerified(true)
            setIsSourceConnection(false);
            setLoader(false);
            return;
          }
        }
        else {
          if (Array.isArray(location.state.applicationName)) {
            console.log("praveeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
            
            history.push('/Configurations', {
              state: {
                ...location.state,
                applicationName: location.state.applicationName[0]
              },
            });
        }
        else{
          history.push('/Configurations', {
            state: {
              ...location.state,
             
            },
          });
        }
          return;
        }
      }

      if (((location?.state.environment == "tenant") || ((location?.state.environment == 'googleworkspace') && !isSourceConnection)) && ((sourceConnectionId=='' && isSourceConnection) || (targetConnectionId=='' && !isSourceConnection))) {
        await openMicrosoftAuthWindow()
        setLoader(true)
      } else {
        setLoader(true)
        checkAndCreateTaskBAckEndTrigger()
      }
    } catch (error: any) {
      // Handle error case: set alert and loader, then return
      setAlert(error.message); // Assuming setAlert is your state setter for alert messages
      setLoader(false);
    }
  };

  //PS_179 to 299
  async function checkAndCreateTaskBAckEndTrigger(connection?: any, forward?: any) {
    let token = await getToken();
    setLoader(true);
    debugger
    try {

      const connectionDat = connection ? connection : connectionData

      console.log(connectionData, " : Validation part connectionData");
      const validationPassed = await validate(); // Assuming validate is a function you have that returns boolean
      if (!validationPassed) {
        setLoader(false); // Assuming setLoader is your state setter for the loader
        return; // early return if validation fails
      }

      // Construct taskPayload from state variables and location state
      const taskPayload: TaskPayload = {
        token: token,
        sourceData: isSourceConnection ? { ...connectionDat } : { ...sourceConnectionData },
        targetData: isSourceConnection ? { ...targetConnectionData } : { ...connectionDat },
        taskName: taskName, // Replace with your logic to retrieve taskName from local
        applicationid: location?.state?.applicationId,
        appName: location?.state?.applicationName,
        connectionType: isSourceConnection ? 'source' : 'target',
        taskType: isAssessment ? 'assessment' : 'migration',
        sourceConnectionId: sourceConnectionId,
        targetConnectionId: targetConnectionId,
        environment: location?.state.environment,
        isAdmin: sessionStorage.getItem('a_at') ? true : false // Replace with your actual logic to determine isAdmin
      };

      if (taskPayload.sourceData.azureAppSecreteId) {
        let dePass = taskPayload.sourceData.azureAppSecreteId.toString(objCryptoJS.enc.Utf8);
        taskPayload.sourceData.azureAppSecreteId = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
      }
      if (taskPayload.targetData.azureAppSecreteId) {
        let dePass = taskPayload.targetData.azureAppSecreteId.toString(objCryptoJS.enc.Utf8);
        taskPayload.targetData.azureAppSecreteId = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
      }

      setLoader(true); // Set loader to true before making the API call

      let response: any;
      console.log("taskPayload : ", taskPayload);

      if(connection){
      setConnectionData(prevState => ({
        ...prevState,
        userRefreshToken: "",
      }));
      }
      setLoader(true);
      response = await checkAndCreateTask(taskPayload);
      debugger
      
      if (response.data.statusCode !== 200) {
        setLoader(false)
        setAlert(response.data.statusMessage)
        console.log()
        return;
      }
      else if (isAssessment) {
        history.push('/AssessmentConfigurations', {
          state: {
            ...location.state,
            taskId: response.data.data.taskId,
          },
        });
      }
      else if (isSourceConnection && !isAssessment) {
        connectionDat.tenantId = response.data.data.tenantId

        if(location?.state.environment !== 'googleworkspace'){
          connectionDat.userRefreshToken = response.data.data.refreshToken
        }

        console.log(connectionDat)
        setIsSourceVerified(true)
        setSourceConnectionData(connectionDat)
        setIsSourceConnection(false);
        setLoader(false);
      }
      else if (!isSourceConnection && !isAssessment) {


        if (Array.isArray(location.state.applicationName)) {
          console.log("praveeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
          
          history.push('/Configurations', {
            state: {
              ...location.state,
              taskId: response.data.data.taskId,
              applicationName: location.state.applicationName[0]
            },
          });
      }
      else{
        history.push('/Configurations', {
          state: {
            ...location.state,
            taskId: response.data.data.taskId,
          },
        });
      }

      }
    }
    catch (error: any) {
      // Handle error case: set alert and loader, then return
      setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.'); // Assuming setAlert is your state setter for alert messages
      setLoader(false);
    }
  }
//PS_140 to 160
  const validate = async (): Promise<boolean> => {
    debugger
    let isValid = true;

    // Resetting error state before validation
    const newErrors: ConnectionDataErrorInterface = {
      connectionName: '',
      adminEmail: '',
      azureAppId: '',
      azureAppSecreteId: '',
      serviceAccountKey: '',
      serviceEmailId: '',
    };
    if (location?.state.environment == 'googleworkspace' && isSourceConnection) {
      if (!connectionData.connectionName) {
        newErrors.connectionName = 'Connection name cannot be empty';
        isValid = false;
      }
      if (!connectionData.serviceEmailId) {
        newErrors.serviceEmailId = 'Service Email ID cannot be empty';
        isValid = false;
      }
      if (!connectionData.serviceAccountKey) {
        newErrors.connectionName = 'Service Account key cannot be empty';
        isValid = false;
      }
    } else {
      if (!connectionData.connectionName) {
        newErrors.connectionName = 'Connection name cannot be empty';
        isValid = false;
        if (connectionData.connectionName === sourceConnectionData.connectionName && connectionData.connectionName !== '') {
          newErrors.connectionName = 'Connection name matches the source connection name';
          isValid = false;
        }
      }
      if (!connectionData.azureAppId) {
        newErrors.azureAppId = 'Azure App Id cannot be empty';
        isValid = false;
        if (connectionData.azureAppId === sourceConnectionData.azureAppId && connectionData.azureAppId !== '') {
          newErrors.azureAppId = 'Azure App ID matches the source Azure App ID';
          isValid = false;
        }
      }
      if (!connectionData.azureAppSecreteId) {
        newErrors.azureAppId = 'Azure Secret ID cannot be empty';
        isValid = false;
        if (connectionData.azureAppSecreteId === sourceConnectionData.azureAppSecreteId && connectionData.azureAppSecreteId !== '') {
          newErrors.azureAppSecreteId = 'Azure Secret ID matches the source Azure Secret ID';
          isValid = false;
        }
      }
      if (!connectionData.adminEmail) {
        newErrors.adminEmail = 'Admin Email Address cannot be empty';
        isValid = false;
        if (connectionData.adminEmail === sourceConnectionData.adminEmail && connectionData.adminEmail !== '') {
          newErrors.adminEmail = 'Admin Email Address matches the source Azure Secret ID';
          isValid = false;
        }
      }
    }
    setConnectionDataError(newErrors);
    return isValid;
  };

  //PS_169 to 175

  function searchSource(value: string = searchVal) {
    const searchValueLower: string = value.trim()?.toLowerCase(); // Convert search value to lowercase

    if (searchValueLower === "") {
      setConnections(oldConnections);
    } else {
      const filteredData = oldConnections.filter((item) => {
        return (
          item.connectionName &&
          item.connectionName?.toLowerCase().includes(searchValueLower)
        );
      });
      setConnections(filteredData);
    }
  }

  const [focusedIndex, setFocusedIndex] = useState(-1);
  const dropdownRef = useRef(null);
//PS_162 168
  const handleKeyDown = (e: any) => {
    if (e.key === "ArrowDown") {
      setFocusedIndex((prevIndex) => (prevIndex + 1) % connections.length);
    } else if (e.key === "ArrowUp") {
      setFocusedIndex((prevIndex) => (prevIndex - 1 + connections.length) % connections.length);
    } else if (e.key === "Enter" && focusedIndex >= 0) {
      storeMapping(connections[focusedIndex]);
      closeDropdown(); // Close the dropdown after selection
    }
  };

  const closeDropdown = () => {
    const dropdownToggle = document.querySelector('.dropdown-toggle') as HTMLElement;
    if (dropdownToggle) {
      dropdownToggle.click(); // Simulate a click on the dropdown toggle button to close it
    }
  };


  const GetToken = async () => {
    try {
      setLoader(true);
      let token = await getToken();
      let obj = {
        type: "r",
        token: token
      };
      const forSASToken = await generateBlobSaasTokenApi(obj);

      // Create the full URL
      const fullUrl = `https://${process.env.REACT_APP_Azure_Storage}.blob.core.windows.net/video/Azure_App_Configuration_Guide.pdf?${forSASToken?.data?.data}`;

      // Fetch the file content
      const response: any = await fetch(fullUrl);
      const blob = await response.blob();

      // Create a link element and trigger the download
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = "Azure App Configuration Guide.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setLoader(false);
    }
  };


  console.log(connectionData, " connection data")

  console.log(location.state, 'locations')
  return (
    <div className='container-height'>
      <div className="row">
        <div className="col-md-12">
          <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
            {/* bread crumb starts here */}
            <ul className="breadcrumb-item mb-4 ps-0">
              <li>
                <a
                  className="pe-0"
                  onBlur={(e) => {
                    e.stopPropagation();
                  }}
                  onMouseDown={() => {
                    history.push("/task", {
                      state: {
                        applicationName: location?.state?.applicationName,
                        applicationId: location?.state?.applicationId,
                        taskName: location?.state?.taskName,
                        taskType: location?.state?.taskType || "Migration",
                      },
                    });
                  }}
                >
                  Task
                </a>
              </li>
              <li className="pe-2">{location?.state?.taskName}</li>
            </ul>
            {/* bread crumb ends here */}
            <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 mt-2">
              <h5 className="primary-header mb-0 d-flex align-items-center">
                <a>
                  <img
                    src="/images/head-back-arrow.svg"
                    alt="back-arrow"
                    width={19}
                    className="me-2"
                    style={{ cursor: 'pointer' }}
                    onBlur={(e) => {
                      e.stopPropagation();
                    }}
                    onMouseDown={() => {
                      history.push("/task", {
                        state: {
                          applicationName: location?.state?.applicationName,
                          applicationId: location?.state?.applicationId,
                          ...location?.state?.state
                        },
                      });
                    }}
                  />
                </a>
                {location?.state?.taskName} - {location?.state?.taskType == "assessment" ? "Assessment" : "Migration"}
                {/* User Migration icon */}
                <img
                  src={`/images/outlook.svg`}
                  alt=''
                  width={23}
                  className="ms-2"
                />

              </h5>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card p-md-4 border-0 rounded-3">
                  <div className="row justify-content-center">
                    {/* Wizard structure starts here */}
                    <div className="col-md-7">
                      <div className="d-flex justify-content-between align-items-center step-wizard">
                        <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer"
                          onClick={() => {
                            // if (location?.state?.taskId && location?.state?.isEdit) {
                            location?.state?.taskType.toLowerCase() == "assessment" ?
                              history.push('/SelectApps', {
                                state: {
                                  ...location?.state,
                                  pageType: 'selectapps',
                                  // taskType: 'assessment',
                                  isEdit: true,
                                  stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage
                                }
                              })

                              :

                              history.push("/SelectApps", {
                                state: {

                                  // applicationName: location?.state?.applicationName,
                                  // applicationId: location?.state?.applicationId,
                                  ...location?.state,
                                  isEdit: true,
                                  // taskType:'assessment',
                                  pageType: 'selectapps',
                                  stage: ((location?.state?.stage) && (location?.state?.stage > stage)) ? location?.state?.stage : stage
                                },
                              });
                            // }
                          }
                          }>
                          <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14"
                          >
                            1
                          </div>
                          <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text">
                            Select App
                          </span>
                        </div>

                        <div className="d-flex align-items-center gap-1 bg-white z-index-1">
                          <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14 active">
                            2
                          </div>
                          <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text active">
                            Connectors
                          </span>
                        </div>

                        <div className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer"
                          onBlur={(e) => {
                            e.stopPropagation();
                          }}
                          onMouseDown={() => {
                            if (location?.state?.taskId && location?.state?.isEdit && location?.state?.stage >= 2) {
                              location?.state?.taskType.toLowerCase() == "assessment" ?
                                history.push("/AssessmentConfigurations", {
                                  state: {
                                    ...location?.state
                                  },
                                })
                                :
                                history.push("/Configurations", {
                                  state: {
                                    ...location?.state
                                  },
                                })

                            }
                          }}>
                          <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14 " >
                            3
                          </div>
                          <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text" >
                            Configurations
                          </span>
                        </div>

                        <div
                          className="d-flex align-items-center gap-1 bg-white z-index-1 cursor-pointer"
                          onBlur={(e) => {
                            e.stopPropagation();
                          }}
                          onMouseDown={() => {
                            if (location?.state?.taskId && location?.state?.isEdit && location?.state?.stage >= 3) {
                              location?.state?.taskType?.toLowerCase() == "assessment" ? location?.state?.stage == 3 ?
                                history.push("/Assessment", {
                                  state: {
                                    ...location?.state,
                                    pageType: 'export'
                                  },
                                }) : history.push("/Inprogress", {
                                  state: {
                                    ...location?.state
                                  },
                                })
                                :
                                history.push("/Migration", {
                                  state: {
                                    ...location?.state
                                  },
                                })
                            }
                          }}
                        >
                          <div className="wizard-step-num d-flex justify-content-center align-items-center font-bold font-14">
                            4
                          </div>
                          <td style={{ textTransform: "capitalize" }}>
                            <span className="bg-white me-1 font-14 font-semibold d-none d-xl-block wizard-step-text">
                              {location?.state?.taskType}
                            </span>
                          </td>
                        </div>
                      </div>
                    </div>
                    {/* Wizard structure ends here */}
                    <div className="col-md-9 mt-4 mt-md-5">
                      <h3 className="m-0 font-22 font-bold mb-4 pb-2">
                        Connectors
                      </h3>
                      {/* tab structure starts here */}
                      <ul
                        className="nav nav-pills mb-2 pb-1"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button className={`nav-link theme-tab ${isSourceConnection ? 'active' : ""} font-16`} disabled={isSourceConnection}
                            onMouseDown={() => {
                              if (isEdit) {

                                setConnectionData(sourceConnectionData)
                                setIsSourceConnection(true)
                              }
                              else if (!isSourceConnection) {
                                //setSelectedSourceConnection('Select')
                                setConnectionData(sourceConnectionData)
                                setConnections(sourceConnections)
                                setIsSourceConnection(true)
                              }
                            }}>
                            Source
                          </button>
                        </li>
                        {!isAssessment && <li className="nav-item" role="presentation">
                          <button className={`nav-link theme-tab ${isSourceConnection ? '' : "active"} font-16`} disabled={isEdit ? false : !isSourceVerified}
                            onBlur={(e) => {
                              e.stopPropagation();
                            }}
                            onMouseDown={() => {
                              if (isEdit) {
                                setConnectionData(targetConnectionData)
                                setIsSourceConnection(false)
                              }
                              else if ((isSourceVerified && isSourceConnection)) {
                                setSelectedTargetConnection('Select')
                                setConnections(targetConnections)
                                setSourceConnectionData(connectionData)
                                setIsSourceConnection(false)

                              }


                            }}>
                            Target
                          </button>
                        </li>}
                      </ul>

                      <div className="p-3 mb-4 d-flex align-items-start gap-2 border-radius-8 info-div-bg">
                        <img
                          src="/images/tooltip.svg"
                          alt="tooltip"
                          width={11}
                        />
                        <span className="lh-1 font-12 cust-role-color">
                          Connect to M365 source tenant. Refer{" "}
                          <a className="primary-color font-bold cursor-pointer" style={{ textDecoration: 'none' }} onClick={(e) => { e.preventDefault(); GetToken(); }}>
                            Azure App Configuration Guide
                          </a>{" "}

                          to fetch the app details. {location?.state?.taskType.toLowerCase() == "assessment" ? "Assessment will be done" : "The migration will be taken"} based on the connected M365 tenant details.
                        </span>

                      </div>

                      {((location?.state?.isEdit) ? false : isSourceConnection ? showSourceForm : showTargetForm) && (
                        <>
                          <div className="col-md-6 pt-2 pe-md-5 mb-3 pb-md-3">
                            <label className="form-label font-14 font-semibold label-color label-color">
                              Choose {isSourceConnection ? 'Source' : 'Target'} Connections <span className="mandatory-color">*</span> <Tippy
                                delay={500}
                                arrow={true}
                                followCursor={'horizontal'}
                                plugins={[followCursor]}
                                placement='top'
                                content="Links the system to the chosen source tenant, allowing data retrieval for processing or analysis."
                              >
                                <img
                                  src="/images/tooltip.svg"
                                  alt="tooltip"
                                  width={11}
                                />
                              </Tippy>

                            </label>
                            {/* Mapping Dropdown starts here */}

                            <div className="input-group input-search">
                              <div className="dropdown dd-flex-1">
                                <button
                                  className="bg-white color-black font-14 w-100 text-start dpd-field d-flex justify-content-between align-items-center dropdown-toggle"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  disabled={role === 'viewer'}
                                >
                                  <span>{isSourceConnection ? selectedSourceConnection : selectedTargetConnection}</span>
                                </button>
                                <ul
                                  className="dropdown-menu w-100 pb-0 rounded-start search-wrapper"
                                  ref={dropdownRef}
                                  onKeyDown={handleKeyDown}
                                  tabIndex={0}                                  >
                                  <li className="px-1 mt-1">
                                    <div className="input-group input-search px-2">
                                      <input
                                        type="search"
                                        value={searchVal}
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            searchSource(e.currentTarget.value);
                                          }
                                        }}
                                        className="form-control border-end-0 font-14 font-regular"
                                        placeholder="Search"
                                        onChange={(e) => {
                                          setSearchVal(e.currentTarget.value);
                                        }}
                                      />
                                      <button
                                        className="btn border-start-0 d-flex align-items-center"
                                        type="button"
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          searchSource();
                                        }}
                                      >
                                        <img
                                          src="/images/search.svg"
                                          alt="search"
                                        />
                                      </button>
                                    </div>
                                  </li>
                                  {connections?.length <= 0 ? (
                                    <li className="text-center font-13 color-grey-v2 p-3">
                                      No Results
                                    </li>
                                  ) : null}
                                  {connections?.map((data, index) => {
                                    return (
                                      <li
                                        key={index}
                                        className={`dropdown-item py-0 cursor-pointer ${focusedIndex === index ? "bg-light" : ""}`}
                                        onClick={() => { storeMapping(data) }}

                                      >
                                        <span className="d-flex justify-content-between align-items-center border-bottom py-2">
                                          <span className="py-1">
                                            {data.connectionName}
                                          </span>
                                        </span>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                              {(role?.toLowerCase() === 'viewer' || role?.toLowerCase() === 'migrationmanager') ? (
                                <span className="input-group-text bg-white cursor-pointer">
                                  <img
                                    src="/images/add-icon-blue.svg"
                                    alt="add"
                                    width={10}
                                  />
                                </span>
                              ) : (
                                <span
                                  className="input-group-text bg-white cursor-pointer"
                                  onClick={resetToDefault}
                                >
                                  <img
                                    src="/images/add-icon-blue.svg"
                                    alt="add"
                                    width={10}
                                  />
                                </span>
                              )}
                            </div>
                            {/* Mapping Dropdown ends here */}
                          </div>


                        </>
                      )}

                      {((isSourceConnection && (selectedSourceConnection != 'Select' || !showSourceForm)) || (!isSourceConnection && (selectedTargetConnection != 'Select' || !showTargetForm))) && (
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="soureTab"
                            role="tabpanel"
                          >
                            {/* Source tab content starts here */}
                            <h4 className="font-bold font-16 mb-4">
                              {isSourceConnection ? "Source" : "Target"} Information
                            </h4>
                            {((location.state.environment.toLowerCase() == "tenant") || ((location.state.environment == 'googleworkspace') && !isSourceConnection)) &&
                              <div className="row justify-content-between">

                                <div className="col-md-6 pe-md-5 mb-4 pb-md-3">
                                  <label
                                    className="form-label font-14 font-semibold label-color label-color"
                                    htmlFor="TenantName"
                                  >
                                    Connection Name <span className="mandatory-color">*</span>  <Tippy
                                      delay={500}
                                      arrow={true}
                                      followCursor={'horizontal'}
                                      plugins={[followCursor]}
                                      placement='top'
                                      content="The entered name will be used to identify the connector"
                                    >
                                      <img
                                        src="/images/tooltip.svg"
                                        className="cursor-pointer"
                                        alt="tooltip"
                                      />
                                    </Tippy>
                                  </label>

                                  <input
                                    type="text"
                                    className="form-control custom-form font-14 font-regular py-2"
                                    name="connectionName"
                                    id="TenantName"
                                    maxLength={80}
                                    disabled={isSourceConnection ? disableSourceFields : disableTargetFields}
                                    value={connectionData.connectionName}
                                    placeholder="Enter Connection Name"
                                    onChange={(e) => {
                                      OnchangeconnectionData(e);
                                    }}
                                  />
                                  <span className="mandatory-color">
                                    <span>
                                      {connectionDataError.connectionName}
                                    </span>
                                  </span>
                                </div>

                                <div className="col-md-6 ps-md-5 mb-4 pb-md-3">
                                  <label
                                    className="form-label font-14 font-semibold label-color label-color"
                                    htmlFor="AdminEmail"
                                  >
                                    Admin Email Address
                                    <span className="mandatory-color"> * </span>
                                    <Tippy
                                      delay={500}
                                      arrow={true}
                                      followCursor={'horizontal'}
                                      plugins={[followCursor]}
                                      placement='top'
                                      content={imageSource == 'gmail' ? "Exchange administrator, is your primary administrator for Mailbox. They will have access to and manage the entirety of yourExchange admin center, including settings, policies, and upgrades." : imageSource == 'gmail' ? "SharePoint administrator, is your primary administrator for SharePoint. They will have access to and manage the entirety of your SharePoint admin center, including settings, policies, and upgrades." : "Teams administrator, is your primary administrator for Teams. They will have access to and manage the entirety of your Teams admin center, including settings, policies, and upgrades."}
                                    >
                                      <img
                                        src="/images/tooltip.svg"
                                        alt="tooltip"
                                        className="cursor-pointer"

                                        width={11}
                                      />
                                    </Tippy>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control custom-form font-14 font-regular py-2"
                                    id="AdminEmail"
                                    disabled={isSourceConnection ? disableSourceFields : disableTargetFields}
                                    name="adminEmail"
                                    value={connectionData.adminEmail}
                                    placeholder="Enter Service Mail ID"
                                    onChange={(e) => {
                                      if (!(location?.state?.taskId))
                                        OnchangeconnectionData(e);
                                    }}
                                  />
                                  <span className="mandatory-color">
                                    {connectionDataError.adminEmail}
                                  </span>
                                </div>

                                <div className="col-md-6 pe-md-5 mb-4 pb-md-3">
                                  <label
                                    className="form-label font-14 font-semibold label-color label-color"
                                    htmlFor="AzureAppID"
                                  >
                                    Azure App ID
                                    <span className="mandatory-color"> * </span>
                                    <Tippy
                                      delay={500}
                                      arrow={true}
                                      followCursor={'horizontal'}
                                      plugins={[followCursor]}
                                      placement='top'
                                      content="Unique ID for connecting to the tenant, generated during Azure registration. Check Azure docs for the App ID"
                                    >
                                      <img
                                        src="/images/tooltip.svg"
                                        alt="tooltip"
                                        className="cursor-pointer"

                                        width={11}
                                      />
                                    </Tippy>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control custom-form font-14 font-regular py-2"
                                    id="AzureAppID"
                                    name="azureAppId"
                                    disabled={isSourceConnection ? disableSourceFields : disableTargetFields}
                                    value={connectionData.azureAppId}
                                    placeholder="Enter Azure App ID"
                                    onChange={(e) => {
                                      if (!(location?.state?.taskId))
                                        OnchangeconnectionData(e);
                                    }}
                                  />
                                  <span className="mandatory-color">
                                    {connectionDataError.azureAppId}
                                  </span>
                                </div>
                                <div className="col-md-6 ps-md-5 mb-4 pb-md-3">
                                  <label
                                    className="form-label font-14 font-semibold label-color label-color"
                                    htmlFor="AzureAppSecretValue"
                                  >
                                    Azure App Secret Value
                                    <span className="mandatory-color"> * </span>
                                    <Tippy
                                      delay={500}
                                      arrow={true}
                                      followCursor={'horizontal'}
                                      plugins={[followCursor]}
                                      placement='top'
                                      content="Confidential key for Azure app authentication during tenant connection, generated in Azure portal. Refer to Azure docs for managing App Secrets."
                                    >
                                      <img
                                        src="/images/tooltip.svg"
                                        alt="tooltip"
                                        className="cursor-pointer"

                                        width={11}
                                      />
                                    </Tippy>
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      type={passwordVisible ? "text" : "password"}
                                      className="form-control password-eye-hide custom-form font-14 font-regular py-2"
                                      id="AzureAppSecretValue"
                                      name="azureAppSecreteId"
                                      placeholder="Enter Azure App Secret Value"
                                      disabled={isSourceConnection ? disableSourceFields : disableTargetFields}
                                      value={connectionData.azureAppSecreteId}
                                      onChange={(e) => {
                                        if (!(location?.state?.taskId))
                                          OnchangeconnectionData(e);
                                      }}
                                    />
                                    <span className="mandatory-color">
                                      {connectionDataError.azureAppSecreteId}
                                    </span>
                                    <img
                                      className={`position-absolute ${passwordVisible ? 'custom-top' : ''} custom-postion cursor-pointer top-50`}
                                      src={`/images/${passwordVisible ? `password-eye-open.svg` : `password-icon.svg`}`}
                                      alt="password-icon"
                                      onClick={() => { setPasswordVisible(!passwordVisible); }}

                                    />
                                  </div>

                                </div>

                              </div>}

                            {(location.state.environment == 'googleworkspace' && isSourceConnection) && <div className="row justify-content-between">
                              <div className="col-md-6 pe-md-5 mb-4 pb-md-3">
                                <label
                                  className="form-label font-14 font-semibold label-color label-color"
                                  htmlFor="ConnectionName"
                                >
                                  Connection Name <span className="mandatory-color">*</span>  <Tippy
                                    delay={500}
                                    arrow={true}
                                    followCursor={'horizontal'}
                                    plugins={[followCursor]}
                                    placement='top'
                                    content="The entered name will be used to identify the connector"
                                  >
                                    <img
                                      src="/images/tooltip.svg"
                                      className="cursor-pointer"
                                      alt="tooltip"
                                    />
                                  </Tippy>
                                </label>

                                <input
                                  type="text"
                                  className="form-control custom-form font-14 font-regular py-2"
                                  name="connectionName"
                                  id="ConnectionName"
                                  maxLength={80}
                                  disabled={isSourceConnection ? disableSourceFields : disableTargetFields}
                                  value={connectionData.connectionName}
                                  placeholder="Enter Connection Name"
                                  onChange={(e) => {
                                    OnchangeconnectionData(e);
                                  }}
                                />
                                <span className="mandatory-color">
                                  <span>
                                    {connectionDataError.connectionName}
                                  </span>
                                </span>
                              </div>

                              <div className="col-md-6 ps-md-5 mb-4 pb-md-3">
                                <label
                                  className="form-label font-14 font-semibold label-color label-color"
                                  htmlFor="ServiceEmailID"
                                >
                                  Service Email ID
                                  <span className="mandatory-color"> * </span>
                                  <Tippy
                                    delay={500}
                                    arrow={true}
                                    followCursor={'horizontal'}
                                    plugins={[followCursor]}
                                    placement='top'
                                    content="Please provide a Service Email ID with Respective Scopes. Refer configuration guide to add scopes to the User"
                                  >
                                    <img
                                      src="/images/tooltip.svg"
                                      alt="tooltip"
                                      className="cursor-pointer"

                                      width={11}
                                    />
                                  </Tippy>
                                </label>
                                <input
                                  type="text"
                                  className="form-control custom-form font-14 font-regular py-2"
                                  id="ServiceEmailID"
                                  disabled={isSourceConnection ? disableSourceFields : disableTargetFields}
                                  name="serviceEmailId"
                                  value={taskId ? connectionData.adminEmail : connectionData.serviceEmailId}
                                  placeholder="Enter Service Mail ID"
                                  onChange={(e) => {
                                    OnchangeconnectionData(e);
                                  }}
                                />
                                <span className="mandatory-color">
                                  <span>
                                    {connectionDataError.serviceEmailId}
                                  </span>
                                </span>
                              </div>

                              <div className="col-md-6 pe-md-5 mb-4 pb-md-3 upload-btn-wrapper me-2 d-block cursor-pointer">
                                <label
                                  className="form-label font-14 font-semibold label-color label-color"
                                  htmlFor="SecretKey"
                                >
                                  Secret Account Key
                                  <span className="mandatory-color"> * </span>
                                  <Tippy
                                    delay={500}
                                    arrow={true}
                                    followCursor={'horizontal'}
                                    plugins={[followCursor]}
                                    placement='top'
                                    content="Please provide the service Account Key provided in the Google Workspace admin console after configuration. Refer configuration guide to manage the service account key "
                                  >
                                    <img
                                      src="/images/tooltip.svg"
                                      alt="tooltip"
                                      className="cursor-pointer"

                                      width={11}
                                    />
                                  </Tippy>
                                </label>
                                <div className="input-group mb-3">
                                  <span className="cust-filter-btn font-14  font-medium primary-color upload-btn">
                                    Browse
                                    <input className="form-control" type="file"
                                      id="formFile"
                                      disabled={isSourceConnection ? disableSourceFields : disableTargetFields}
                                      name='serviceAccountKey'
                                      onChange={OnchangeconnectionData}
                                      accept=".json"
                                    />
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control file-input-field custom-form font-14 font-regular py-2"
                                    placeholder={ taskId ? 'Service Account Key' : file?.name ? file.name : "No file chosen"}
                                    aria-label="Example text with button addon"
                                    readOnly
                                    value={fileInputValue}
                                  />
                                </div>
                                <span className="mandatory-color">
                                  {connectionDataError.serviceAccountKey}
                                </span>
                              </div>
                            </div>
                            }
                            <div style={{ position: 'relative', width: '100%' }}>
                              <span className="mandatory-color" style={{ position: 'absolute', top: 0, right: 0 }}>
                                {apiErrorMessage?.length > 0
                                  ? apiErrorMessage
                                  : null}
                              </span>
                            </div>
                            {/* Source tab content ends here */}

                          </div>
                        </div>
                      )}

                      <div className="d-md-flex d-grid w-100 gap-3 align-items-center justify-content-unset justify-content-md-between mt-5 ">
                        <button className="btn primary-outline-btn font-14 w-md-100 font-semibold flex-nowrap d-flex align-items-center justify-content-center order-2 order-md-0"
                          onBlur={(e) => {
                            e.stopPropagation();
                          }}
                          onMouseDown={() => {
                            history.push("/selectapps", {
                              state: {
                                // applicationName: location?.state?.applicationName,
                                // applicationId: location?.state?.applicationId,
                                // selectedApps: location?.state?.selectedApps,
                                ...location?.state
                              },
                            });
                          }}>
                          <img
                            src="/images/left-arw-blue.svg"
                            alt="back"
                            className="cursor-pointer"

                            width={12}
                          />
                          <span className="ms-1">Back</span>
                        </button>
                        <div className="d-md-flex d-grid gap-3">
                          <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center me-3 order-2 order-md-0"
                            onBlur={(e) => {
                              e.stopPropagation();
                            }}
                            onMouseDown={() => { history.push('/task') }}>
                            Cancel
                          </button>
                          <button
                            className="btn primary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                            onClick={handleNext}
                          // disabled={connectionData.connectionsId == '' ? !(Object.keys(connectionData).every((key) => {
                          //   // Check that the property is not 'email' and also not an empty string
                          //   if (key !== 'adminEmail' && key !== 'connectionsId'){

                          //     return connectionData[key as keyof ConnectionDataInterface] !== '';

                          //   }
                          //   return true; // If the property is 'email', it's automatically considered valid
                          // })) : false}
                          // disabled={
                          //   connectionData.connectionsId === '' ||(location?.state.environment === 'googleworkspace'
                          //     ?!(Object.keys(connectionData).every((key) => {
                          //           if (['adminEmail', 'azureAppSecreteId', 'azureAppId', 'connectionsId'].includes(key)) {
                          //             return true;
                          //           }
                          //           return connectionData[key as keyof ConnectionDataInterface] !== '';
                          //         })
                          //       )
                          //     : location?.state.environment === 'tenant'
                          //     ? // If the environment is tenant, ignore specific fields
                          //       !(
                          //         Object.keys(connectionData).every((key) => {
                          //           if (['serviceAccountKey', 'serviceEmailId', 'connectionsId'].includes(key)) {
                          //             return true;
                          //           }
                          //           return connectionData[key as keyof ConnectionDataInterface] !== '';
                          //         })
                          //       )
                          //     :
                          //       !(Object.keys(connectionData).every((key) => connectionData[key as keyof ConnectionDataInterface] !== ''))
                          //   )
                          // }
                          >
                            Next
                            <img
                              src="/images/right-arw.svg"
                              alt="next"
                            />
                          </button>

                        </div>
                      </div>
                      {/* tab structure ends here */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loader && (
        <div className="container-fluid">
          <div className="overlay">
            <div className="position-absolute top-50 start-50 translate-middle">
              <div
                className="spinner-border Loader loader-color align-center "
                role="status"
              >
                <span>
                  <span className="loader-inner-circle"></span>
                </span>
              </div>
              <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                Loading..
              </p>
            </div>
          </div>
        </div>
      )}
      {/*Failure Popup starts here */}
      {alert ? <div
        className="modal fade show"
        tabIndex={-1}
        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content popup-brd-radius">
            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
              <span className="position-absolute">
                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
              </span>
            </div>
            <div className="modal-body border-0 text-center">
              <h5
                className="modal-title mb-3 primary-header"
                id="Failure-popupLabel"
              >
                { }
              </h5>
              <p className="primary-data">
                {alert}
              </p>
            </div>
            <div className="modal-footer border-0 pb-4 justify-content-center">
              <button
                type="button"
                className="btn primary-btn font-14 px-4 font-semibold"
                onClick={() => {
                  if (isSessionEpired) {
                    history.push('/Login')
                  }
                  setAlert('')
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div> : null}
      {/*Failure Popup ends here */}
    </div>
  );
};