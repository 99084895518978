
//PS_04 PS_05 PS_06 PS_07 PS_08 PS_09 PS_10 PS_11PS_12PS_13 PS_14
import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getAdminClient, getSession, sendMailToClients, sendOtp, updateServerDown } from "../../Services/manageClientAPI";
import * as XLSX from "xlsx";
import Loader from "../Common/Loader";
import { Country } from "country-state-city";
import Select from "react-select"
import { countries, TCountries } from "countries-list";
import objCryptoJS from "crypto-js";
import { sessionResponse } from "../../Common/Interface";
import { decodeSessionDetails } from "../../Services/CommonServices";
import CryptoJS from 'crypto-js';
import { serverDownContent } from "../../Common/Constant";


// import XLSX from 'xlsx';
// import Tippy, { followCursor } from '@tippyjs/react';

// import {
//   someFunction as profileServiceFunction, // Replace with actual function names
//   anotherFunction,
//   getAdminClientData,
//   client,
// } from './profileService';
// import CommonService from './CommonServices';
// import { adminEndpoints as admin, BEURL } from './constants';
// import { DataTypes } from './interface'; // Assuming the interfaces you need are exported as DataTypes
// import Loader from './loader/LoaderComponent';
// import CryptoJS from 'crypto-js';

const ManageClientProfile: React.FC = () => {

const location:any = useLocation()?.state

  //PS_15 PS_16 PS_17 PS_18 PS_19 PS_20 PS_21 PS_22 PS_23 PS_24 PS_25 PS_26 	PS27 	PS28 	PS29 	PS30 	PS31 	PS32 	PS33 	PS34  	
  //PS35  	PS36  	PS37  	PS38  	PS39  	P40   	P41   	P42   	P43   	P44 
  // Client-related state variables
  const [ctoken, setCToken] = useState<string | null>(null);
  const [clientName, setClientName] = useState("");
  const [mailId, setMailId] = useState("");
  const [role, setRole] = useState("");
  const [tokenExpiration, setTokenExpiration] = useState<Date>(new Date());
  const [clientData, setClientData] = useState([]);

  // Admin-related state variables
  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
  const [atoken, setAToken] = useState<string | null>(null);
  const [adminName, setAdminName] = useState("");
  const [adminMailId, setAdminMailId] = useState("");
  const [adminRole, setAdminRole] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  // Other state variables
  const history = useHistory();
  const filterData = {
    status:'',
    environment: "",
    plan:  location?.state?.status?location?.state?.status:'',
    createdDateFrom: "",
    createdDateto: "",
    modifiedDateFrom: "",
    modifiedDateto: "",
  };
  const [filteredTasks, setFilteredTasks] = useState(filterData);
  const [selectAll, setSelectAll] = useState(false);
  const [isServerDown, setServerDown] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [offset, setOffset] = useState(10);
  const [MailType, setMailType] = useState("Announcement");
  const [mailPopup, setMailPopup] = useState(false);
  const [sortType, setSortType] = useState("desc");
  const [sortValue, setSortValue] = useState("registeredAt");
  const [sortHide, setSortHide] = useState("");
  const [popupmessage, setpopupmessage] = useState("");
  const [environmentfilter, setenvironmentfilter] = useState(['Tenant', 'Google Workspace']);
  const [statusfilter, setstatusfilter] = useState(['Active', 'InActive']);
  const [planfilter, setplanfilter] = useState(['Elite', 'Pro', 'Free']);
  const [subject, setSubject] = useState("");
  const [comments, setComments] = useState("");
  const [Enablemail, setEnablemail] = useState(false)
  const [maillist, setmaillist] = useState("");
  const [allclientData, setallClientData] = useState([]);
  const [count, setCount] = useState<HTMLDivElement | any>(null);
  const fromRef = useRef<HTMLDivElement | null>(null);
  const toRef = useRef<HTMLDivElement | null>(null);
  const [otp, setOTP] = useState<any>("");
  const [otpPopUp, setOTPPopUp] = useState<boolean>(false);
  const [otpError, setOtpError] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(45);
  const [adminmailId, setadminMailId] = useState([])
  const [verify, setVerify] = useState(true);
  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})
  const [basicCountryCodes, setBasicCountryCodes] = useState<string[]>([]);
  const [alertPopup, setAlertPopup] = useState(false)
  const [otpValues, setOTPValues] = useState(["", "", "", "", "", ""]);
  const [successPOPup, setSuccessPopup] = useState(false);
  const [successmessage, setSuccessmessage] = useState('');




  //PS_45 PS_46
  useEffect(() => {
    fetchBasicCountryCodes()
    if (window.location.pathname.toString().toLowerCase().includes("admin")) {
      // resetSessionTimer();
      sessionTokenProperties()
      getAdminClientData()
      //   return () => {
      // window.removeEventListener('click', resetSessionTimer);
      // window.removeEventListener('keydown', resetSessionTimer);
      // };
    }
    else {
      sessionTokenProperties()
      getAdminClientData()
      //   return () => {
      //     window.removeEventListener('click', resetSessionTimer);
      //     window.removeEventListener('keydown', resetSessionTimer);
      // };
    }
  }, [offset, sortType, sortValue]);


  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: sessionResponse = await decodeSessionDetails();
      debugger
      if (window.location.pathname.toString().toLowerCase().includes("admin")) {
        if (tokenResponse.adminDetails) {
          setObjAdmin(tokenResponse.adminDetails)
          setAdminName(tokenResponse.adminDetails.Name);
          setAdminRole(tokenResponse.adminDetails.Role);
          setRole(tokenResponse.adminDetails.Role)
          setAdminMailId(tokenResponse.adminDetails.Email)
          

        }
        else {
          if (window.location.pathname.toString().toLowerCase().includes("admin")) {
            history.push("/Admin/Login")
          }
        }
      }
      else {
        if (tokenResponse.clientDetails) {
          setObjClient(tokenResponse.clientDetails)
          setClientName(tokenResponse.clientDetails.Name);
          setRole(tokenResponse.clientDetails.Role);
          setMailId(tokenResponse.clientDetails.Email)
        }
        else {
          history.push("/Login")
        }
      }
      //#endregion
    } catch (error) {
      console.error('Failed in main method', error);
    }
  };
  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    // debugger
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          //console.log(clientToken, 'ddddddddddddddddddddddddddd');
          setCToken(clientToken)
          token = {
            clientToken: clientToken
          }
        }


      }
      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          //console.log(adminToken, 'sssssssssssssssssss');
          setAToken(adminToken)
          token = {
            adminToken: adminToken
          }
        }
      }
      return token
    } catch (error) {
      throw error
    }
  }
  const fetchBasicCountryCodes = async () => {
    const allCountries = await Country.getAllCountries();
    const basicCodes: any = allCountries.filter(country => {
      // Define the criteria for basic country codes.
      // For example, you might consider countries with phone codes less than 100.
      return parseInt(country.phonecode) < 100;
    });
    // Sort the basic country codes alphabetically
    basicCodes.sort((a: any, b: any) => a.name.localeCompare(b.name));
    setBasicCountryCodes(basicCodes);
  };
  const countryOptions: { value: string, label: string }[] = Object.keys(countries).map((key) => ({
    value: '+' + countries[key as keyof TCountries].phone,
    label: `${countries[key as keyof TCountries].name} (+${countries[key as keyof TCountries].phone})`,
  }));
  // PS_59, PS_60, PS_61, PS_62, PS_63, PS_64, PS_65
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
      if (timer == 0) {
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);

  //PS_47 PS_48 PS_49 PS_50 PS_51 PS_52 PS_53 PS_54 PS_55 PS_56 
  //	PS57 	PS58 	PS59  	PS60  	PS61  	PS62  	PS63   	P64   	P65 
  const getAdminClientData = async (filter = filteredTasks) => {
    setLoader(true)
    const payload = {
      token: await getToken(),
      ...filter,
      search: searchValue,
      loadmore: offset,
      sortfield: sortValue,
      sortType: sortType,
    };

    try {
      debugger;
      
      
      const response = await getAdminClient(payload);
      console.log("the response of client details",response);
      setpopupmessage(response?.data?.statusMessage);
      if (response?.data?.statusCode !== 200) {
        setLoader(false)
        
        
        setpopupmessage(response?.data?.data?.data);
      } else {
        setLoader(false)

        setallClientData(response?.data?.data?.AdminData);
        setCount(response?.data?.data?.AdminData[0]?.totalCount)
        response?.data?.data?.secret == 'true' ? setServerDown(true) : setServerDown(false)
        setadminMailId(response?.data?.data?.adminMail)

      }
    } catch (error: any) {
      console.error(error);
      setLoader(false)
      setAlertPopup(true)
    }
  };

  //PS_77 PS_78 PS_79 PS_80 PS_81 PS_82 PS_83 PS_84 PS_85 PS_86 
  const onSearch = async () => {
    if (!searchValue) {
      return; // Prevent further execution
    }
    const result = await getAdminClientData();
    return result;
  };
  const handleSearch = (event: any) => {
    const value = event.target.value;
    setSearchValue(value);
  };
  //PS_91 PS_92 PS_93 PS_94 PS_95 PS_96 PS_97 PS_98 PS_99 PS_100 
  const handleFilter = (event: any) => {
    const { value, name } = event.target;
    // Create a new object to hold the filter values
    const updatedFilter: any = { ...filteredTasks };

    // Update the filter object with the new value
    updatedFilter[name] = value;

    // Update the state with the new filter object
    setFilteredTasks((prev) => ({ ...prev, [name]: value }));
  };

  //PS_101 PS_102 PS_103 PS_104 PS_105 PS_106 PS_107 PS_108 PS_109 PS_110 	PS111 	PS112 	
  //PS113 	PS114 	PS115 	PS116  	PS117  	PS118  	PS119  	P120   	P121   	P122   	P123   	P124   	P125 
  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    const newData: any = allclientData.map((item: any) => ({
      ...item,
      checked: newSelectAll,
    }));
    setallClientData(newData);
    setSelectAll(newSelectAll);
    setEnablemail(newSelectAll && newData.length > 0);
  };
  const handleCheckboxChange = (index: any) => {
    const newData: any = [...allclientData];
    newData[index].checked = !newData[index].checked;
    setallClientData(newData);
    
    // Check if any item is checked
    const anyChecked = newData.some((item: any) => item.checked);
    setEnablemail(anyChecked);
  
    // Optionally, update selectAll state
    setSelectAll(newData.every((item: any) => item.checked));
  };
  const showmailPop = () => {
    const selectedItems = allclientData.filter((item: any) => item.checked);
    const selectedMailIds = selectedItems.map((item: any) => item.mailId);
    const mailToList = selectedMailIds.join(", ");
    if (selectedItems.length === 0) {
      setMailPopup(false)
    }else{
      setMailPopup(true)
    }
    
    setmaillist(mailToList)
  }
  const exportexcel = () => {

    const filteredData = allclientData?.map((item: any) => (

      {
        'Company Name': item.companyName,
        'Account Status': item.accountStatus,
        'Current Plan': item.planName,
        'User Business mail address': item.mailId,
        'Environment Type': item.environmentType,
        'Registered On': (item.registeredAt)

        // Add more properties if needed
      }));
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "ClientData.xlsx");
  };


  //   debugger
  //   const keyWordArray = CryptoJS.enc.Utf8.parse(key);
  //   const encryptedMessageWordArray =
  //     CryptoJS?.enc?.Base64?.parse(encryptedMessage);
  //   const iv = encryptedMessageWordArray?.clone();
  //   iv.sigBytes = 16;
  //   encryptedMessageWordArray?.words?.splice(0, 4);
  //   encryptedMessageWordArray.sigBytes -= 16;

  //   const cipherParams = CryptoJS.lib.CipherParams.create({
  //     ciphertext: encryptedMessageWordArray,
  //     key: keyWordArray,
  //   });

  //   const decrypted = CryptoJS.AES.decrypt(cipherParams, keyWordArray, {
  //     iv: iv,
  //     mode: CryptoJS.mode.CFB,
  //     padding: CryptoJS.pad.NoPadding,
  //   });

  //   const decryptedPlaintext = decrypted.toString(CryptoJS.enc.Utf8);
  //   //console.log(decryptedPlaintext, decryptedPlaintext);
  //   const decryptedObject = JSON.parse(decryptedPlaintext);
  //   return decryptedObject;
  // }
  //PS_69 PS_70 PS_71 PS_72 PS_73 
  const handleSort = (e: any) => {
    try {
      let sortID = e.target.id;
      let sortval = sortID.split("@");
      setSortValue(sortval[0]);
      setSortType(sortval[1]);
      setSortHide(sortID);
      //setSearchValue("");
    } catch (e: any) {
      //console.log(e.message);
    }
  };

  const generateSession = async (mailId: any) => {
    setLoader(true)
    debugger
    let admintoken: any = sessionStorage.getItem('a_at')
    try {
      let payload = {
        mailId: mailId,
        isAdmin: false,
        token: await getToken()
      };
      let getsessionToken = await getSession(payload);
      setpopupmessage(getsessionToken?.data?.statusMessage)
      //console.log(getsessionToken?.data?.data.sessionToken, 'ddddddddddddddddddddddddddddddddddddd');
      if (getsessionToken?.data?.data.sessionToken) {
        
        let passphrase = `${process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY}`;
        const decryptedBytes = CryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, passphrase);
        const decrptedValue = decryptedBytes.toString(CryptoJS.enc.Utf8);
        let encryptedToken: any = CryptoJS.AES.encrypt(decrptedValue, c_secretKey);
        console.log("the manageclient encryptToken",encryptedToken);
        
        //console.log(decrptedValue, 'llllllllllllllllllllllllllllllllll');
        if (decrptedValue) {
          setLoader(false)
          const newWindow = window.open(`${process.env.REACT_APP_FE_URL}`, '_blank');
          if (newWindow) {
            newWindow.sessionStorage.setItem("c_at", encryptedToken);
            newWindow.sessionStorage.setItem("a_at", admintoken);
            newWindow.sessionStorage.setItem("isAdminGeneratedClientSession", "true");
            newWindow.location.href = '/Task';
          }
        }
      }
    } catch (error: any) {
      setLoader(false)
      setAlertPopup(true)
      //console.log(error, 'dddddddddddddddd');
    }
  };

  //PS_69
  const bindTable = () => {
    console.log("the all clienT data",allclientData);
    

    return allclientData.map((item: any, index: any) => (
     
      <tr key={index}>
        <td className="check-box-td-wid pe-0">
          <input
            className="form-check-input cus-check-input"
            type="checkbox"
            checked={item.checked}
            onChange={() => handleCheckboxChange(index)}
          />
        </td>
        {item?.companyName?.length >= 15 ? (
          <td>
            <a href="">
              <span
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                data-bs-title="Type Team Site"
                // className=""
                data-bs-original-title=""
                title={item.companyName}
              >
                {item.companyName?.slice(0, 10) + "..."}
              </span>
            </a>
          </td>
        ) : (
          <td
            onClick={async (event) => {
              event.preventDefault();
              generateSession(item.mailId)
            }}
          >
            <a
              href=""
            >
              {item.companyName}
            </a>
          </td>
        )}

        {item.accountStatus == 'Active' ? (
          <td>
            <span className="text-nowrap">
              <span
                className={`table-status success d-inline-block me-2 `}
              ></span>
              Active
            </span>
          </td>
        ) : (
          <td>
            <span className="text-nowrap">
              <span
                className={`table-status danger d-inline-block me-2 `}
              ></span>
              InActive
            </span>
          </td>
        )}

        {/* <td>{item.accountStatus}</td> */}
        <td>{item.planType}</td>
        <td>{!item.environmentType ? "-" : item.environmentType}</td>
        {item?.mailId?.length >= 30 ? (
          <td>
            {" "}
            <span
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-title="Type Team Site"
              // className=""
              data-bs-original-title=""
              title={item.mailId}
            >
              {item.mailId?.slice(0, 30) + "..."}
            </span>
          </td>
        ) : (
          <td>{item.mailId}</td>
        )}
        <td>{item.registeredAt}</td>
        {role.toLowerCase() !== "adminviewer" ? (
          <td className="d-flex justify-content-center">
            <button
              className="btn action-rounded-btn resume-action d-flex align-items-center justify-content-center"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              data-bs-title="Edit"
              data-bs-original-title=""
              title=""
              onClick={() => history.push('/Admin/ManageClients/AdminClient', { state: { id: item.manageClientId,clientEmail: item.mailId,companyName:item.companyName,clientName:item.userName } })}

            >
              <img src="/images/edit-action-icon.svg" alt="edit-action-icon" />
            </button>
          </td>
        ) : null}
      </tr>
    ));
  };

  const sendMail = async (mailType:string) => {
    try{
      setLoader(true)
    if (mailType =="Announcement"){
    //console.log("trigger....................");

    const mailPayload = {
      token: await getToken(),
      mail:maillist,
      subject:subject,
      comments:comments  
    };    
    const response: any = await sendMailToClients(mailPayload);
    if (response.data.statusCode == 200) {
      setLoader(false)
      setMailPopup(false)
      setSuccessmessage("Email has been sent successfully")
      setSuccessPopup(true)
     
    }else {
      setAlertPopup(true)
    }
    }
  else if(mailType==="ServerDown"){
    const mailPayload = {
      token: await getToken(),
      mail:maillist,
      subject:subject,
      comments:comments  
    };    
    const response: any = await sendMailToClients(mailPayload);
    if (response.data.statusCode == 200) {
      setLoader(false)
      setMailPopup(false)
      setSuccessmessage("Email has been sent successfully")
      setSuccessPopup(true)
    
    }else {
      setAlertPopup(true)
    
    }setLoader(false)
    
      }else if(mailType==="SERVER-DOWN"){
      
        const payload = {
          token: await getToken()
          
        };
    
        let response : any = await sendOtp(payload)
        if (response.data.statusCode == 200) {
          setLoader(false)
          setTimer(45)
          setOTPPopUp(true);
          //console.log(response.data, "otp");
          setOTP(response?.data?.data?.OTP)
      }else{
        setLoader(false)
        setAlertPopup(true)
      }
  
    console.log("trigger....................");
    }
    
 } catch(error:any)
    {
    setLoader(false)
    setAlertPopup(true)

    }
  };




  //SERVER DOWN 

  //PS_163,PS_164,PS_165,PS_166,PS_167,-,PS_168,PS_169,PS_170,PS_171
  const handlePaste = (event: any, index: any) => {
    event.preventDefault();
    const pastedData = event.clipboardData.getData("Text");
    const pastedDigits = pastedData.split("").slice(0, otpValues.length);
    const newOtpValues = [...otpValues];
    pastedDigits.forEach((digit: any, i: any) => {
      if (digit.match(/\d/)) {
        newOtpValues[index + i] = digit;
      }
    });
    setOTPValues(newOtpValues);
  };
  const handleKeyDown = (e: any, index: any) => {
    if (e.keyCode === 8) { // Check if the backspace key is pressed
      // Clear the value of the current input
      const newOtpValues = [...otpValues];
      newOtpValues[index] = ''; // Clear the value of the current input
      setOTPValues(newOtpValues);

      if (index > 0) {
        // If current input is not the first one
        // Move focus to the previous input
        const prevInput = document.getElementById(`otp-input-${index - 1}`);
        if (prevInput) {
          prevInput.focus();
        }
      }

      // Prevent default behavior to avoid any additional clearing
      e.preventDefault();
      e.stopPropagation();
    }
  };
  //PS_172,PS_173,PS_174,PS_175,-,PS_176,-,PS_177,PS_178,PS_179,PS_180
  const handleChange = (index: any, value: any) => {
    // Allow only digits
    const newDigit = value.replace(/\D/g, "").slice(-1);

    // Update OTP value at the specified index
    const newOtpValues = [...otpValues];
    newOtpValues[index] = newDigit;
    setOTPValues(newOtpValues);
    console.log("the digits of OTP",otpValues);
    

    // Move focus to the next input if a digit is entered
    if (newDigit && index < otpValues.length - 1) {
      const nextInput = document.getElementById(`otp-input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const handleVerifyOtp = async () => {
    debugger;
    try{

    
    let passphrase = `${process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY}`;
    //console.log(otp, 'rrrrrrrrrrrrrrrrrrrrrrr')
    const decryptedBytes = CryptoJS.AES.decrypt(otp, passphrase);
    const decrptedValue = decryptedBytes.toString(CryptoJS.enc.Utf8);
    // const decrptedValue = await decrypt(passphrase , otp)
    ////console.log(otp, otpValues, userOtp, "mdkd");
    //console.log(decrptedValue, "value of decryption");
    debugger
    //console.log(decrptedValue, 'fhghjj');
    const userOTP = otpValues.join("");
    //console.log(otp, otpValues, userOTP, "mdkd");

    if (decrptedValue == userOTP) {
      

      const payload = {
        token: await getToken(),
         serverDownValue: 'true'
        
      };

      let update: any = await updateServerDown(payload)
      if (update?.data?.statusCode == 200) {
        setLoader(false)
        setServerDown(true)
        setOTPPopUp(false);
        setSuccessmessage('OTP Verified Successfully')
        setSuccessPopup(true);
        setMailPopup(false)
        setOTPValues(["", "", "", "", "", ""]);
        setTimer(45);
      }
    } else if(decrptedValue !== userOTP){
      console.log("the entered otp is wrong");
      setOtpError(true)
      
      
      setOTPValues(["", "", "", "", "", ""]);
      
     
      }
      else{
        setLoader(false)
        setAlertPopup(true)

      }
  }
  catch(error:any){
console.log("The Server down issues",error);


  }
}
 const handleServerdownUp = async () =>{
 setLoader(true)
  let serverDownUPpayload = {
      token: await getToken(),
       serverDownValue: 'false'
    }
try {
    let update: any = await updateServerDown(serverDownUPpayload)
    if (update?.data?.statusCode == 200) {
      console.log("the serverdown issue is successful");
      
      setLoader(false)
      setServerDown(false)
    }
    else {
      console.log("There is an issue in serverdown");
      
    }}
    catch(error:any){
      console.log("the error in serverdownUP");
      
    }
 }


  return (
    <>
      <div className="container-fluid container-height">
        <div className="row">
          <div className="col-md-12">
            <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
              {/*heading and filters starts here*/}
              <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                <h5 className="font-20 font-semibold mb-0">
                  Manage Clients ({count})
                </h5>
                {/*filters & buttons*/}
                <div className="d-flex gap-3 w-md-100 flex-wrap">
                  <div className="d-flex gap-3 flex-md-row w-md-100 align-items-center">
                    <span className="custom-switch d-flex justify-content-center position-relative text-nowrap">
                      <input
                        type="checkbox"
                        id="expenditureActive"
                        checked={isServerDown}
                        onChange={async (e) => {
                          if (e.target.checked) {
                            setMailType("SERVER-DOWN")
                            setVerify(true);
                            sendMail("SERVER-DOWN")
                          
                          }
                          else {
                            await handleServerdownUp()
                            setServerDown(false)
                          }
                        }}
                      />
                      <label
                        className="font-14 font-regular cust-role-color"
                        htmlFor="expenditureActive"
                      >
                        Server Down
                      </label>
                    </span>
                  </div>
                  
                  <div className="d-flex gap-3 flex-md-row w-md-100 align-items-center">
                    <div className="input-group input-search">
                      <input
                        type="search"
                        className="form-control border-end-0 font-14 font-regular"
                        placeholder="Search"
                        onKeyDown={(event) => {
                          if (event.key == "Enter") {
                            getAdminClientData();
                          }
                        }}
                        onChange={handleSearch}
                      />
                      <button
                        className="btn border-start-0 d-flex align-items-center"
                        type="button"
                        onClick={onSearch}
                      >
                        <img src="/images/search.svg" alt="search" />
                      </button>
                    </div>
                    <button
                      className="btn icon-swap secondary-outline-btn"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="/images/filter-active-icon.svg"
                        alt="filter-active-icon"
                      />
                      <img src="/images/filter-icon.svg" alt="filter-icon" />
                    </button>
                    {/*filter*/}
                    {/*filter*/}
                    <div
                      className="dropdown-menu dropdown-menu-end custom-filter-width p-3 end-0"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <h5 className="font-bold font-20 mb-0 custom-header-color">
                          Advanced Filter
                        </h5>
                        <span>
                          <img
                            className="cursor-pointer"
                            src="/images/close-icon.svg"
                            alt="close"
                          />
                        </span>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mb-4">
                          <label
                            className="form-label font-14 font-semibold label-color "
                            htmlFor="environment"
                          >
                            Environment Type
                          </label>
                          <select
                            className="form-select custom-form"
                            aria-label="Default select example"
                            id="environment"
                            name="environment"
                            value={filteredTasks.environment}
                            onChange={handleFilter}
                          >
                            <option value="">
                              Select
                            </option>
                            {environmentfilter.map((option, index) => (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label
                            className="form-label font-14 font-semibold label-color "
                            htmlFor="status"
                          >
                            Status
                          </label>
                          <select
                            className="form-select custom-form"
                            aria-label="Default select example"
                            id="status"
                            name="status"
                            value={filteredTasks.status}
                            onChange={handleFilter}
                          >
                            <option value="">
                              Select
                            </option>
                            {statusfilter.map((option, index) => (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-12 mb-2">
                          <label className="font-16 font-semibold custom-header-color">
                            Created On
                          </label>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label
                            className="form-label font-14 font-semibold label-color"
                            htmlFor="from"
                          >
                            From
                          </label>
                          <input
                            className="form-control custom-form"
                            type="date"
                            id="from"
                            name="createdDateFrom"
                            max={filteredTasks.createdDateto}
                            value={filteredTasks.createdDateFrom}
                            onChange={handleFilter}
                          />
                        </div>
                        <div className="col-md-6 mb-4">
                          <label className="form-label font-14 font-semibold label-color"
                            htmlFor="to">To</label>
                          <input
                            className="form-control custom-form" type="date" id="to"
                            name="createdDateto"
                            min={filteredTasks.createdDateFrom}
                            value={filteredTasks.createdDateto}
                            onChange={handleFilter}
                          />
                        </div>
                        <div className="col-md-6 mb-4">
                          <label
                            className="form-label font-14 font-semibold label-color"
                            htmlFor="current-plan"
                          >
                            Current Plan
                          </label>
                          <select
                            className="form-select custom-form"
                            aria-label="Default select example"
                            id="current-plan"
                            name="plan"
                            value={filteredTasks.plan}
                            onChange={handleFilter}
                          >
                            <option value="">
                              Select
                            </option>
                            {planfilter.map((option, index) => (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-md-12">
                          <div className="d-flex justify-content-end align-items-center py-3">
                            <button
                              className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4"
                              onClick={() => {
                                getAdminClientData(filterData);
                                setFilteredTasks(filterData);
                              }}
                            >
                              Clear
                            </button>
                            <button
                              className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4"
                              onClick={() => getAdminClientData()}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*filter*/}
                  </div>
                  <button className="btn primary-outline-btn w-md-100 font-14 font-semibold text-nowrap py-2" disabled={!Enablemail}
                    onClick={showmailPop}>
                    <img
                      className="me-1"
                      src="/images/mail-icon.svg"
                      alt="mail-icon"
                    />
                    Mail
                  </button>
                  <button className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center py-2"
                    onClick={() => exportexcel()}>
                    <img src="/images/excel-icon.svg" alt="excel-icon" />
                    Export
                  </button>
                  {role !== 'adminviewer' ? 
                  <button className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                    onClick={() => history.push('/Admin/ManageClients/NewClient', { state: { action: 'NewClient' } })} >
                    <img src="/images/add-plus-icon.svg" alt="add-plus-icon" />
                    New Client
                  </button>:null}
                </div>
                {/*filters & buttons*/}
              </div>
            </div>
            {/*heading and filters ends here*/}
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active h-100"
                id="manageClientsTab"
                role="tabpanel"
              >
                {/* Manage Clients tab content starts here */}
                <div className="table-responsive theme-table bg-white ">
                  <table className="table table-borderless mb-0">
                    <thead>
                      <tr>
                        <th>
                          <input
                            className="form-check-input cus-check-input"
                            type="checkbox"
                            checked={selectAll}
                            onClick={handleSelectAllChange}
                          />
                        </th>
                        <th className="text-nowrap">
                          Company Name
                          <span
                            className="ms-1 cursor-pointer"

                          >
                            <img
                              src={`/images/sort-arrow-up.svg`}
                              alt="sort-arrow"
                              style={{ cursor: 'pointer' }}
                              id={`companyName@DESC`} hidden={
                                sortHide == `companyName@ASC` || ""
                                  ? false
                                  : true
                              }
                              onClick={(e) => {
                                handleSort(e);
                              }}

                            />
                            <img
                              src={`/images/sort-arrow-down.svg`}
                              alt="sort-arrow"
                              id={`companyName@ASC`}
                              style={{ cursor: 'pointer' }}
                              hidden={
                                sortHide == `companyName@ASC` || ""
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                handleSort(e);
                              }}
                            />
                          </span>
                        </th>
                        <th className="text-nowrap">
                          Status
                          <span
                            className="ms-1 cursor-pointer"

                          >
                            <img
                              src={`/images/sort-arrow-up.svg`}
                              alt="sort-arrow"
                              style={{ cursor: 'pointer' }}
                              id={`accountStatus@DESC`} hidden={
                                sortHide == `accountStatus@ASC` || ""
                                  ? false
                                  : true
                              }
                              onClick={(e) => {
                                handleSort(e);
                              }}

                            />
                            <img
                              src={`/images/sort-arrow-down.svg`}
                              alt="sort-arrow"
                              id={`accountStatus@ASC`}
                              style={{ cursor: 'pointer' }}
                              hidden={
                                sortHide == `accountStatus@ASC` || ""
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                handleSort(e);
                              }}
                            />
                          </span>
                        </th>
                        <th className="text-nowrap">
                          Current Plan
                          <span
                            className="ms-1 cursor-pointer"

                          >
                            <img
                              src={`/images/sort-arrow-up.svg`}
                              alt="sort-arrow"
                              style={{ cursor: 'pointer' }}
                              id={`planType@DESC`} hidden={
                                sortHide == `planType@ASC` || ""
                                  ? false
                                  : true
                              }
                              onClick={(e) => {
                                handleSort(e);
                              }}

                            />
                            <img
                              src={`/images/sort-arrow-down.svg`}
                              alt="sort-arrow"
                              id={`planType@ASC`}
                              style={{ cursor: 'pointer' }}
                              hidden={
                                sortHide == `planType@ASC` || ""
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                handleSort(e);
                              }}
                            />
                          </span>
                        </th>
                        <th className="text-nowrap">
                          Environment Type
                          <span
                            className="ms-1 cursor-pointer"

                          >
                            <img
                              src={`/images/sort-arrow-up.svg`}
                              alt="sort-arrow"
                              style={{ cursor: 'pointer' }}
                              id={`environmentType@DESC`} hidden={
                                sortHide == `environmentType@ASC` || ""
                                  ? false
                                  : true
                              }
                              onClick={(e) => {
                                handleSort(e);
                              }}

                            />
                            <img
                              src={`/images/sort-arrow-down.svg`}
                              alt="sort-arrow"
                              id={`environmentType@ASC`}
                              style={{ cursor: 'pointer' }}
                              hidden={
                                sortHide == `environmentType@ASC` || ""
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                handleSort(e);
                              }}
                            />
                          </span>
                        </th>
                        <th className="text-nowrap">
                          Business User Email Address
                          <span
                            className="ms-1 cursor-pointer"

                          >
                            <img
                              src={`/images/sort-arrow-up.svg`}
                              alt="sort-arrow"
                              style={{ cursor: 'pointer' }}
                              id={`mailId@DESC`} hidden={
                                sortHide == `mailId@ASC` || ""
                                  ? false
                                  : true
                              }
                              onClick={(e) => {
                                handleSort(e);
                              }}

                            />
                            <img
                              src={`/images/sort-arrow-down.svg`}
                              alt="sort-arrow"
                              id={`mailId@ASC`}
                              style={{ cursor: 'pointer' }}
                              hidden={
                                sortHide == `mailId@ASC` || ""
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                handleSort(e);
                              }}
                            />
                          </span>
                        </th>
                        <th className="text-nowrap">
                          Created On
                          <span
                            className="ms-1 cursor-pointer"

                          >
                            <img
                              src={`/images/sort-arrow-up.svg`}
                              alt="sort-arrow"
                              style={{ cursor: 'pointer' }}
                              id={`registeredAt@DESC`} hidden={
                                sortHide == `registeredAt@ASC` || ""
                                  ? false
                                  : true
                              }
                              onClick={(e) => {
                                handleSort(e);
                              }}

                            />
                            <img
                              src={`/images/sort-arrow-down.svg`}
                              alt="sort-arrow"
                              id={`registeredAt@ASC`}
                              style={{ cursor: 'pointer' }}
                              hidden={
                                sortHide == `registeredAt@ASC` || ""
                                  ? true
                                  : false
                              }
                              onClick={(e) => {
                                handleSort(e);
                              }}
                            />
                          </span>
                        </th>
                        <th className="text-nowrap text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allclientData.length > 0 ?
                        bindTable() : (
                          <tr>
                            <td colSpan={7}>
                              <div className="text-center">
                                <div className="pt-4 pb-3 text-center">
                                  <img
                                    src="/images/no-data-found-img.svg"
                                    alt="no-data-found-img"
                                    className="mb-3"

                                  />
                                  <h5 className="font-12 font-bold color-no-rec">No Data</h5>
                                  <p className="font-10 font-regular color-no-rec">
                                    There is no data to show you right now
                                  </p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
                {allclientData?.length >= 10 ? (
                  <div className="d-flex justify-content-center mt-5">

                    <button
                  
                      className="btn primary-outline-btn font-14 font-semibold"
                      onClick={() => {
                        setOffset(offset + 10);
                      }}
                    >
                      Load More
                    </button>
                  </div>
                ) : null}
                {/* Manage Clients tab content ends here */}
              </div>
            </div>
            {loader && <Loader />}
            {mailPopup && (<div
              className="modal fade show"
              id="MailPopup"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-labelledby="MailPopupLabel"
              style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content p-4">
                  <div className="modal-header border-0 p-0">
                    <h1
                      className="font-20 font-bold custom-header-color m-0"
                      id="MailPopupLabel"
                    >
                      Mail
                    </h1>
                    <button
                      type="button"
                      className="btn-close shadow-none"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setMailType("Announcement");
                        setMailPopup(false); setSubject('');
                        setComments('');
                      }}
                    />
                  </div>

                  <div className="modal-body border-0 p-0 pt-4 pb-5">
                  {loader && <Loader />}
                    <div className="row">
                      <div className="col-md-12">
                        <label className="font-14 font-semibold label-color w-100 mb-2 pb-1">
                          Mail Type
                        </label>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="Announcement"
                            defaultValue="option1"
                            checked={MailType == "Announcement"}
                            onClick={
                              () => {
                                setMailType("Announcement")
                                setSubject('');
                                setComments('');
                              }


                            }
                            
                          />
                          <label
                            className="form-check-label font-14 font-regular"
                            htmlFor="Announcement"
                          >
                            Announcement
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-radio-input"
                            type="radio"
                            name="inlineRadioOptions"
                            id="ServerDown"
                            defaultValue="option2"
                            checked={MailType == "ServerDown"}
                            onClick={() => {
                              //need to update the server down
                              setMailType("ServerDown");
                              setSubject('');
                              setComments("");
                            }}
                          />
                          <label
                            className="form-check-label font-14 font-regular"
                            htmlFor="ServerDown"
                          >
                            Server Down
                          </label>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label
                          className="font-14 font-semibold label-color w-100 my-2 pt-3"
                          htmlFor="MailTo"
                        >
                          Bcc
                        </label>
                        <input
                          type="email"
                          className="form-control custom-form font-14 font-regular"
                          id="MailTo"
                          placeholder="Enter Subject"
                          value={maillist} // Populate the Mail To field
                        />
                      </div>
                      <div className="col-md-12">
                        <label
                          className="font-14 font-semibold label-color w-100 my-2 pt-3"
                          htmlFor="MailSubject"
                        >
                          Subject
                        </label>
                        <input
                          type="text"
                          className="form-control custom-form font-14 font-regular"
                          id="MailSubject"
                          placeholder="Enter Subject"
                          value={MailType === 'ServerDown' ? 'AvaMigratron Server Maintenance Notification' : subject}
                          onChange={(e) => {
                            setSubject(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="MailComments"
                          className="font-14 font-semibold label-color w-100 my-2 pt-3"
                        >
                          Comments
                        </label>
                        <textarea
                          className="form-control resize-none custom-textarea custom-form"
                          id="MailComments"
                          rows={3}
                          placeholder="Enter Comments"
                          defaultValue={""}
                          value={MailType === 'ServerDown' ? (comments || serverDownContent) : comments}
                          onChange={(e) => {
                            setComments(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer border-0 p-0">
                    <button
                      type="button"
                      className="btn secondary-btn"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setMailPopup(false);
                        setMailType("ServerDown");
                        setSubject('');
                        setComments('');
                      }



                      }
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn primary-btn"
                      disabled={subject == '' || comments == ''}
                    onClick={() => {
                      sendMail(MailType);
                    }}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
            )}
          </div>
          {alertPopup === true ? (
            <div
              className="modal fade show"
              id="Alert-popup"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-labelledby="Alert-popupLabel"
              aria-hidden="true"
              style={{
                display: "block",
                backgroundColor: "rgba(0,0,0,0.6)",
              }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content popup-brd-radius">
                  <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                    <span className="position-absolute">
                      <img
                        src="/images/failure-popup-icon.svg"
                        alt="Alert-popup-icon"
                      />
                    </span>
                  </div>
                  <div className="modal-body border-0 text-center">
                    <h5
                      className="modal-title mb-3 primary-header"
                      id="Alert-popupLabel"
                    >
                      Alert
                    </h5>
                    <p className="primary-data font-16">Failed </p>
                  </div>
                  <div className="modal-footer border-0 pb-4 justify-content-center">
                    <button
                      type="button"
                      className="btn primary-btn font-14 px-4 font-semibold"
                      data-bs-dismiss="modal"
                      onClick={() => setAlertPopup(false)}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          
          {otpPopUp &&
            <div
            
              className="modal fade show"
              id="otp-popup"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              aria-labelledby="Success-popupLabel"
              aria-hidden="true"
              style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
            >
              {loader && <Loader />}
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content rounded-3">
                  <div className="modal-header border-0 justify-content-center py-2 mb-5 position-relative">
                    <span className="position-absolute">
                      <img
                        src="/images/verification-icon.svg"
                        alt="verification-icon"
                      />
                    </span>
                  </div>
                  <div className="modal-body border-0 text-center pt-0">
                    <div className="row justify-content-center">
                      <h5
                        className="modal-title mb-2 primary-header"
                        id="Success-popupLabel"
                      >
                        Enter OTP
                      </h5>
                      <p className="primary-data">
                        Please enter the 6-digit OTP that was sent to your email.
                      </p>
                      <div className="col-md-9">
                        <div className="d-flex justify-content-between gap-2 mb-2">
                          {otpValues.map((value: any, index: any) => (
                            <div className="d-block me-3" key={index}>
                              <input
                                id={`otp-input-${index}`}
                                className="form-control custom-form"
                                type="text"
                                value={value}
                                onChange={(e) => handleChange(index, e.target.value)}
                                onPaste={(e) => handlePaste(e, index)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                                maxLength={1}
                                onKeyDownCapture={(e) => {
                                  if (e.key === 'Enter') {
                                    handleVerifyOtp()
                                  }
                                }}
                              />
                            </div>
                          ))}

                        </div>
                        <div className="d-flex justify-content-end font-12 font-semibold">
                          {timer > 0 ? (
                            <span>
                              Resend OTP in {timer} seconds
                            </span>
                          ) : (
                            <a className="font-semibold link-color cursor-pointer" onClick={() => { sendMail("SERVER-DOWN");setOtpError(false) }}
                            >
                              Resend OTP
                            </a>
                          )}
                        </div>

                        <div>
                          <span className="mandatory-color">
                            {otpError == true ? "Please enter valid OTP" : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer border-0 justify-content-center">
                    <button
                      type="button"
                      className="btn primary-btn font-14 px-4 font-semibold mb-2"
                      data-bs-dismiss="modal"
                      onClick={handleVerifyOtp}
                    >
                      Verify
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }

          {successPOPup && <div
            className="modal fade show "
            id="Success-popup"
            aria-labelledby="Success-popupLabel"
            aria-modal="true"
            role="dialog"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content popup-brd-radius">
                <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
                  <span className="position-absolute">
                    <img
                      src="/images/success-popup-icon.svg"
                      alt="success-popup-icon"
                    />
                  </span>
                </div>
                <div className="modal-body border-0 text-center">
                  <h5
                    className="modal-title mb-3 primary-header"
                    id="Success-popupLabel"
                  >
                    Success !
                  </h5>
                  <p className="primary-data">{successmessage}</p>
                </div>
                <div className="modal-footer border-0 justify-content-center pb-4">
                  <button
                    type="button"
                    className="btn primary-btn font-14 px-4 font-semibold"
                    data-bs-dismiss="modal"
                    onClick={() => setSuccessPopup(false)}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>}

        </div>
        {loader && <Loader />}
      </div>
    </>
  );
};

export default ManageClientProfile;
