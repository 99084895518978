import { BE_URL } from '../../Common/Constant';

import React, { useState, useEffect } from 'react';

import { useLocation } from "react-router-dom";

import { fetchApplicationNames, getAppsData, userAppDetailsInfo } from '../../Services/assessmentGridService';

import AppSpecificGrid from "./ViewAssessmentGrid";

import moment from "moment";

import { useHistory } from 'react-router-dom';

import Loader from "../Common/Loader";

import { decodeSessionDetails } from "../../Services/CommonServices";

import { sessionResponse } from '../../Common/Interface'

import objCryptoJS from "crypto-js";

import { w3cwebsocket as WebSocket } from 'websocket';

import * as XLSX from "xlsx";
import jwt from 'jwt-decode'
import { getSession } from '../../Services/manageClientAPI';

const ExcelJS = require("exceljs");



//import { getSessionToken } from './sessionUtils'; //Assuming sessionUtils has a method to get the token


const ViewAssessmentMaster: React.FC = () => {
  // State initialization according to the pseudocode

  //PS_01, PS_02, PS_03, PS_04, PS_05, PS_06, PS_07, PS_08, PS_09, PS_10,
  //PS_11, PS_12, PS_13, PS_14, PS_15, PS_16, PS_17, PS_18, PS_19, PS_20
  const history = useHistory();
  // const location: any = useLocation().state
  const location: any = useLocation().state
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [dropdownData, setDropdownData] = useState<any[]>([]);
  const [totalRoomsAndEquipmentsCount, setTotalRoomsAndEquipmentsCount] = useState(0);
  const [totalOutlookCount, setTotalOutlookCount] = useState(0);
  const [totalSharedMailboxCount, setTotalSharedMailboxCount] = useState(0);
  const [totalSharePointSitesCount, setTotalSharePointSitesCount] = useState(0);
  const [totalDriveCount, setTotalDriveCount] = useState(0);
  const [totalTeamChannel, setTotalTeamChannel] = useState(0);
  const [totalGdriveCount, setTotalGdriveCount] = useState(0);
  const [totalGmailCount, setTotalGmailCount] = useState(0);
  const [totalTeamChannelData, setTotalTeamChannelData] = useState<any[]>([]);
  const [totalTeamChatData, setTotalTeamChatData] = useState<any[]>([]);
  const [totalTeamChat, setTotalTeamChat] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchValuePresent, setIsSearchValuePresent] = useState(true);
  const [pageSize, setPageSize] = useState(10)
  
  
  ;
  //const [token, setToken] = useState({"clientToken":"U2FsdGVkX19MvwmIUmulsSQUii83m/ICs7Mg/0qD/0WAi4Qi0PvP2g8nV3zz41Ha+9qx/inRTQ0MuOv3N708dclk86udu0QKmXSPLEDlonrBQMNCUH+gi0358kH4XP2Ap7wb9R0/BPwCXIMJpS5/VmH76XPx3l4Mc8oMCk7DlT+Qhdek8Z17JWTCmHhikhzTtiiQfpuXmrUwaBKegiLYLD81i04h930XrM/4LQZ5u95wOVZLSwf9B51AQB0ABg0JUzT0k4D4H18WwBLHy4kEdTxr63awpVrPEoJzeoWbMMyCYpDnd8p7h9g9EkCCkKeMtdZ6iIfVyE8/hFo9Qi3zwwcfbrSWkMGK3Y5u+dA6iIKZmdUbxemwpmxOAoo1/JWjgoniJDatndOUfLuNcBCy60KvClIGxEC+oFei5qxe2wEt79/t89q+uE3xNyc5Vzf5vvd+0Fu9WJkyf8pysxNVpJE1kJir0jm4tz/HFRhOMa4TtwBy7HE/ELbUgD6AckeQV26GlJtzrEYVuBw2blWJdzeuguMUorYtUtmn9zfG3RIvMvujzEgypcehlHhn+XJ2SpZ4bniylQXLtQfrNSAZRaflhiCBJ+K26Up471sfKPh0nqmhT/ycCqu5wKrZtxi74jWa8hSqkXb6yaa33KyBSDzLNaGp3E0Bfbctip3uLlF5e8+Kk7yJU+ThKSnaXbYF08gqvV6EsziAJHky3W410Kn2xUjzyOCvCs9VBsiEee4GQULZzEgq4jsxeabz6KXNtrsVD0k7IdwZDYTQWugcYwPhsXj3aNlTlu4was1KHFsjXJL3isiC9IxXVLR4v41iEbDJqBa+bguKed8z189EshPvuwbrfCL5x2r1NzySILk="}); // Gets token from session assuming there's some getSessionToken method
  const [taskId, setTaskId] = useState<number>(location.state.taskId);

  const [taskName, setTaskName] = useState<string>(location.state.taskName);
  const [searchInput, setSearchInput] = useState<string>('');
  const [alert, setAlert] = useState<string>('');
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  // //debugger
  let InActiveTimeout: NodeJS.Timeout;

  const [isFetching, setIsFetching] = useState(false)




  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';

  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})

  let sessionDetails = { UserName: "", CompanyName: "", EmailAddress: "", ID: 0, Role: "" }
  let [clientName, setclientName] = useState('');
  let [companyName, setCompanyName] = useState('');
  let [emailAddress, setEmailAddress] = useState('');
  let [isAdmin, setIsAdmin] = useState(false);
  let [adminName, setAdminName] = useState('');
  let [adminEemailAddress, setAdminEmailAddress] = useState('');
  let [adminrole, setAdminRole] = useState('');
  const [role, setRole] = useState<string>('');

  let [isPageLoad, setIsPageLoad] = useState<boolean>(true)

  let client: any = ''

  // Logic to navigate to AssessmentGrid interface would be implemented here
  // ... 


  //PS_22, PS_23, PS_24, PS_25 
  useEffect(() => {
    sessionTokenProperties();
    resetTimer();
    fetchDropdownData(); // Fetch dropdown data when component mounts
    // return () => {
    //   client.close();
    // };
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);

    return () => {
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };

  }, []);

  let resetTimer = async () => {
    try {
      // debugger;
      //console.log("Reset Timer Start");

      let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
      //console.log("sessionResponse value :", sessionResponse);

      // //debugger
      if (sessionResponse == 'expired') {
        debugger
        sessionStorage.removeItem("c_at");
        setisSessionEpired(true)
        setAlert('Your Session has Expired please login again to continue');
      }
      else if (sessionResponse == 'updateToken') {
        if (emailAddress == "" || emailAddress == undefined) {
          await sessionTokenProperties();
        }
        debugger
        let token = await getToken()
        let payload = {
          mailId: emailAddress,
          isAdmin: false,
          token: token
        };
        let getsessionToken = await getSession(payload);
        const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
        const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

        const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
        const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
        let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
        await sessionStorage.removeItem("c_at");
        await sessionStorage.setItem('c_at', updatedToken);
        let updatedobjClient: any = jwt(c_decryptedValue);
        SessionEpiredDateTime = updatedobjClient.CreatedAt;
        setSessionEpiredDateTime(SessionEpiredDateTime);
      }
      //console.log("Reset Timer End");
    }
    catch (error) {
      //console.log("Reset Timer Catch");
      setAlert('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
      setLoading(false);
    }
  }

  const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
    try {
      // debugger
      //console.log("Reset Session Timer Start");
      // Assuming InActiveTimeout is a number representing the ID of the current timeout

      if (typeof window !== 'undefined') {
        //console.log("Reset Session Timer Inside");
        clearTimeout(InActiveTimeout);
        const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

        InActiveTimeout = setTimeout(() => {
          //console.log("Expired");
          sessionStorage.removeItem("c_at");
          //console.log("removed session");
          setisSessionEpired(true)
          setAlert('Your Session has Expired please login again to continue');
          // return 'expired'
        }, tokenExpiryCheckInterval);

        //console.log("Reset Session Timer checking");

        const currentDateTime = new Date().toISOString();
        const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
       // console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);
        debugger
        const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
        //console.log("diffInMilliSeconds values:", diffInMilliseconds);

        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        //console.log("minutes : ", minutes);

        const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
        //console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

        if (minutes > sessionUpdateTimeGap) {

          //console.log("Reset Session Timer need to update");
          return 'updateToken'
        }

        //console.log("Reset Session Timer End");
        return 'NoActionRequired'
      }
      return 'NoActionRequired'
    }
    catch (ex) {
      //console.log("Reset Session Timer Catch");
      throw ex
    }
  };

  async function setLoaderFalse() {
    try {
      setLoading(false)
      setIsPageLoad(false)
    } catch (error) {
     // console.log('error', error)
    }
  }


  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: any = await decodeSessionDetails();
      debugger
      if (tokenResponse.clientDetails) {
        setObjClient(tokenResponse.clientDetails)
        setclientName(tokenResponse.clientDetails.Name);
        setCompanyName(tokenResponse.clientDetails.CompanyName)
        emailAddress = tokenResponse.clientDetails.Email;
        setEmailAddress(emailAddress);
        setRole(tokenResponse.clientDetails.Role);
        setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
      }
      else {
        history.push("/Login")
      }
      //#region only for required Modules
      if (tokenResponse.adminDetails) {
        setObjAdmin(tokenResponse.adminDetails)
        setAdminName(tokenResponse.adminDetails.Name);
        setAdminEmailAddress(tokenResponse.adminDetails.Email);
        setRole(tokenResponse.adminDetails.Role);
      }
      // else {
      //     history.push("/Admin/Login")
      // }
      //#endregion
    } catch (error) {
      console.error('Failed in main method', error);
    }
  };

  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    debugger
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = clientToken

        }


      }
      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

          token['adminToken'] = adminToken

        }
      }
      return token
    } catch (error) {
      throw error
    }
  }

  // PS_24, PS_25, PS_26, PS_27, PS_28, PS_29, PS_30, PS_31, PS_32, PS_33,
  // PS_34, PS_35, PS_73, PS_74, PS_75, PS_76, PS_77, PS_78, PS_79,
  // PS_80, PS_81, PS_82, PS_83, PS_84, PS_85, PS_86, PS_87,
  // PS88 ,PS89 ,PS90 ,PS91 ,PS92 ,PS95 ,PS96 ,PS97 ,
  // PS98 ,PS99 ,PS100 ,PS101 ,PS102 ,PS103 ,
  // PS106 ,PS107 ,PS108 ,
  // PS109 ,PS110 ,
  // PS111 ,

  //PS_47 to PS_132
  const fetchDropdownData = async () => {
    //   if (!validateSessionExpiration(token)) {
    //     console.error('Session expired');
    //     return;
    //   }
   
    let token = await getToken()
    let fetchApplicationsPayload = {
      token : token,
      taskId :taskId
    }
    // setLoading(true);

    try {
      console.log("the taskId for the payload",fetchApplicationsPayload)
      const applicationNames: any = await fetchApplicationNames( fetchApplicationsPayload );
      console.log("the respose for the fetch applications",applicationNames);
      setLoading(false);
      if (applicationNames.data.statusCode !== 200) {
        setAlert(applicationNames.data.statusMessage);
        setLoading(false);
        return;
      }
      setDropdownData(applicationNames.data.data);
      setSelectedOption(applicationNames.data.data[0].applicationName);

      let nameOfTheApplications: { applicationName: string }[] = applicationNames.data.data
      console.log("the nameofthe applications",nameOfTheApplications);
      
      const applicationsToCheck = ['outlook', 'sharepoint', 'gmail', 'teamschannel', 'mydrive'];
      for (const app of applicationsToCheck) {
        if (nameOfTheApplications.some(obj => obj.applicationName.toString().toLowerCase() === app)) {
          let payload = {
           // Action: "count",
            taskId: taskId,
            token: token,
            applicationName: app,
           // page: 'assessmentGrid'
          };
  
          try {
            const applicationData: any = await getAppsData(payload);
          //  console.log("the response from the master gRID",applicationData.data.data.data);
            
        
            if (applicationData.data.statusCode !== 200) {
              setAlert(applicationData.data.statusMessage);
              continue;
            }
  
            // Update state based on the application
            switch (app) {
              case 'outlook':
                setTotalOutlookCount(applicationData.data.data.data.TotalCount);
                break;
              case 'sharepoint':
                setTotalSharePointSitesCount(applicationData.data.data.data.TotalCount);
                break;
              case 'gmail':
                setTotalGmailCount(applicationData.data.data.data.TotalCount);
                break;
              case 'mydrive':
                console.log("the mydrive count",applicationData.data.data.data.TotalCount);
                
                setTotalGdriveCount(applicationData.data.data.data.TotalCount);
                break;
              case 'teamschannel':
                setTotalTeamChannel(applicationData.data.data.data.sourceTeamCount);
                break;
             
            }
          } catch (error) {
            console.error(`Error fetching data for ${app}:`, error);
          }
        }
      }
  
    } catch (error) {
      console.error('Error in fetchData:', error);
    } finally {
      setLoading(false);
    }
  };;


  // PS_172 PS_173 PS_174 PS_175 PS_176 PS_177 PS_178 PS_179 PS_180 PS_181 
  // PS_182 PS_183 PS_184 PS_185 PS_186 PS_187 PS_188 PS_189 PS_190 PS_191 
  // PS_192 PS_193 PS_194 PS_195 PS_196 PS_197 PS_198 PS_199 PS_200
  // PS-201 	PS-202 	PS-203 	PS-204 	PS-205 	PS-206 	PS-207 	PS-208 	PS-209
  // PS-210 	PS-211 	PS-212 	PS-213 	PS-214 	PS-215 	PS-216 	PS-217  	Ps218
  // Ps219  	Ps220  	Ps221  	Ps222  	Ps223  	Ps224  	Ps225  	Ps226    Ps227
  // Ps228    Ps229    Ps230    Ps231    Ps232    Ps233    Ps234    Ps235    Ps236
  // Ps237    Ps238    Ps239    Ps240    Ps241    Ps242    Ps243    Ps244 	  P245 
  // P246     P247     P248     P249     P250     P251 
  
  //0PS_143 to PS_295
  const handleExport = async () => {
    setLoading(true);
  
    try {
      let token = await getToken();
      const applications = ['outlook', 'sharepoint', 'gmail', 'mydrive', 'teamschannel'];
  
      if (applications.includes(selectedOption.toLowerCase().trim())) {
        const appData: any = await getAppsData({
          // Action: "data",
          taskId: taskId,
          token: token,
          // isApi: true,
          applicationName: selectedOption,
        });
  
        if (appData.data.statusCode !== 200) {
          setAlert(appData.data.statusMessage);
         
          
        }
  
        const workbook = XLSX.utils.book_new();
  
        switch (selectedOption.toLowerCase().trim()) {
          case 'outlook':
            const overallInventoryData = appData?.data?.data?.data?.detailedInfoResult && appData?.data?.data?.data?.detailedInfoResult.length > 0
            ? appData?.data?.data?.data?.detailedInfoResult?.map((user: any) => ({
                "User Name": user.UserName,
                "Mails": user.TotalMailCount,
                "Mailbox": Number(user.TotalStorageUsedGB) ? formatBytes(Number(user.TotalStorageUsedGB)) : '0.00B',
                "Contacts": user.TotalContactCount,
                "Todo": user.TotalTaskCount,
                "Calendar": user.TotalCalendarCount,
              }))
            : [{ "User Name": "", "Mails": "", "Mailbox": "", "Contacts": "", "Todo": "", "Calendar": "" }];
      
          const overallInventorySheet = XLSX.utils.json_to_sheet(overallInventoryData);
          XLSX.utils.book_append_sheet(workbook, overallInventorySheet, "User_Mailbox");
      
          // Mail Data
          const mailData = appData?.data?.data?.data?.detailedInfoResult && appData?.data?.data?.data?.detailedInfoResult.length > 0
            ? appData?.data?.data?.data?.detailedInfoResult?.map((details: any) => ({
                "User Name": details.UserName,
                "Folder Name": details.MailFolderName,
                "Mail count": details.MailCount,
                "Mail size": Number(details.FolderSizeGB) ? formatBytes(Number(details.FolderSizeGB)) : '0.00B',
                "Error Log": details.ErrorLog,
              }))
            : [{ "User Name": "", "Folder Name": "", "Mail count": "", "Mail size": "", "Error Log": "" }];
      
          const mailSheet = XLSX.utils.json_to_sheet(mailData);
          XLSX.utils.book_append_sheet(workbook, mailSheet, "Mails");
      
          // Contacts Data
          const contactsData = appData?.data?.data?.data?.detailedInfoResult && appData?.data?.data?.data?.detailedInfoResult.length > 0
            ? appData?.data?.data?.data?.detailedInfoResult?.map((details: any) => ({
                "User Name": details.UserName,
                "Contact Folder Name": details.ContactFolderName,
                "Contacts count": details.ContactCount,
                "Error Log": details.ErrorLog,
              }))
            : [{ "User Name": "", "Contact Folder Name": "", "Contacts count": "", "Error Log": "" }];
      
          const contactsSheet = XLSX.utils.json_to_sheet(contactsData);
          XLSX.utils.book_append_sheet(workbook, contactsSheet, "Contacts");
      
          // Todo Data
          const todoData = appData?.data?.data?.data?.detailedInfoResult && appData?.data?.data?.data?.detailedInfoResult.length > 0
            ? appData?.data?.data?.data?.detailedInfoResult?.map((details: any) => ({
                "User Name": details.UserName,
                "Todo Name": details.TodoFolderName,
                "Todo count": details.TotalTaskCount,
                "Error Log": details.ErrorLog,
              }))
            : [{ "User Name": "", "Todo Name": "", "Todo count": "", "Error Log": "" }];
      
          const todoSheet = XLSX.utils.json_to_sheet(todoData);
          XLSX.utils.book_append_sheet(workbook, todoSheet, "Todo");
      
          // Calendar Data
          const calendarData = appData?.data?.data?.data?.detailedInfoResult && appData?.data?.data?.data?.detailedInfoResult.length > 0
            ? appData?.data?.data?.data?.detailedInfoResult?.map((details: any) => ({
                "User Name": details.UserName,
                "Calendar Name": details.CalendarName,
                "Event count": details.EventCount,
                "Error Log": details.ErrorLog,
              }))
            : [{ "User Name": "", "Calendar Name": "", "Event count": "", "Error Log": "" }];
      
          const calendarSheet = XLSX.utils.json_to_sheet(calendarData);
          XLSX.utils.book_append_sheet(workbook, calendarSheet, "Calendar");
      
          XLSX.writeFile(workbook, "Outlook_Assessment_Report.xlsx");
          break;
           
          case 'sharepoint':
            const mainSharePointData = appData?.data?.data?.data?.detailedInfoResult?.map((site: any) => ({
              "Site Name": site.SiteName,
              "Site URL": site.SiteURL,
              "Users": site.Users,
              "Document Library": site.DocumentLibrary,
              "Lists": site.Lists,
              "Pages": site.Pages,
              "Permission level": site.PermissionLevel,
              "People and Groups": site.PeopleAndGroups,
              "Subsite Count": site.SubsiteCount
            }));
            const mainSharePointSheet = XLSX.utils.json_to_sheet(mainSharePointData);
            XLSX.utils.book_append_sheet(workbook, mainSharePointSheet, "SharePoint Overview");
  
            // Document Library sheet
            const documentLibraryData = appData?.data?.data?.data?.detailedInfoResult?.flatMap((site: any) => 
            ({
                "Site Name": site.SiteName,
                "Document library name": site.DocumentLibraryName,
                "Files": site.Files,
                "Folders": site.Folders
              }))
            ;
            const documentLibrarySheet = XLSX.utils.json_to_sheet(documentLibraryData);
            XLSX.utils.book_append_sheet(workbook, documentLibrarySheet, "Document Libraries");
  
            // Lists sheet
            const listsData = appData?.data?.data?.data?.detailedInfoResult?.flatMap((site: any) => 
              ({
                "Site Name": site.SiteName,
                "List name": site.ListName,
                "List Item": site.Lists
              }))
            ;
            const listsSheet = XLSX.utils.json_to_sheet(listsData);
            XLSX.utils.book_append_sheet(workbook, listsSheet, "Lists");
  
            // Permission level sheet
            const permissionLevelData = appData?.data?.data?.data?.detailedInfoResult?.map((site: any) => ({
              "Site Name": site.SiteName,
              "Permissions": site.permissionLevelCount
            }));
            const permissionLevelSheet = XLSX.utils.json_to_sheet(permissionLevelData);
            XLSX.utils.book_append_sheet(workbook, permissionLevelSheet, "Permission Levels");
  
            // People and Groups sheet
            const peopleAndGroupsData = appData?.data?.data?.data?.detailedInfoResult?.flatMap((site: any) => 
               ({
                "Site Name": site.SiteName,
                "People and groups name": site.PeopleAndGroupsName,
                "Users": site.PeopleAndGroups
              })
            );
            const peopleAndGroupsSheet = XLSX.utils.json_to_sheet(peopleAndGroupsData);
            XLSX.utils.book_append_sheet(workbook, peopleAndGroupsSheet, "People and Groups");
  
            // Taxonomy sheet
            const taxonomyData = appData?.data?.data?.data?.detailedInfoResult?.map((term: any) => ({
              "Term group name": term.TermGroupName,
              "Term set name": term.TermSetName
            }));
            const taxonomySheet = XLSX.utils.json_to_sheet(taxonomyData);
            XLSX.utils.book_append_sheet(workbook, taxonomySheet, "Taxonomy");
  
            XLSX.writeFile(workbook, "SharePoint_Assessment_Report.xlsx");
            break;
           
          case 'gmail':
            const mainGmailData = appData?.data?.data?.data?.detailedInfoResult?.map((user: any) => ({
              "User Name": user.UserName,
              "Mails": user.TotalMailCount,
              "Mailbox": formatBytes(Number(user.TotalStorageUsedGB)),
              "Contacts": user.TotalContactCount,
              "Tasks": user.TotalTaskCount,
              "Calendar": user.TotalCalendarCount,
              "Error Log": user.ErrorLog
            }));
            const mainGmailSheet = XLSX.utils.json_to_sheet(mainGmailData);
            XLSX.utils.book_append_sheet(workbook, mainGmailSheet, "Gmail Overview");
  
            // Mails sheet
            const mailsData = appData?.data?.data?.data?.detailedInfoResult?.flatMap((user: any) => 
                appData?.data?.data?.data?.mailData.map((mail:any) => ({
                "User Name": user.UserName,
                "Label Name": mail.folderName,
                "Mail count": mail.ItemCount,
                "Mail size": formatBytes(Number(mail.FolderSizeGB)),
                "Rules Count": mail.rulesCount,
                "Send As Count": mail.sendAsCount,
                "Read and Manage Count": mail.readAndManageCount,
                "Forward Mail Address Count": mail.forwardingAddressCount,
                "Error Log": user.ErrorLog
                
              }))
            )
            const mailsSheet = XLSX.utils.json_to_sheet(mailsData);
            XLSX.utils.book_append_sheet(workbook, mailsSheet, "Mails");
  
            // Contacts sheet
            const gmailcontactsData = appData?.data?.data?.data?.detailedInfoResult?.flatMap((user: any) => 
              appData?.data?.data?.data?.contactData?.flatMap((contactData: any)=>  ({
                "User Name": user.UserName,
                "Contact Label Name": contactData.ContactFolderName,
                "Contacts count": contactData.ContactCount,
                "Error Log": user.ErrorLog
              }))
            )
            const gmailcontactsSheet = XLSX.utils.json_to_sheet(gmailcontactsData);
            XLSX.utils.book_append_sheet(workbook, gmailcontactsSheet, "Contacts");
  
            // Tasks sheet
            const tasksData = appData?.data?.data?.data?.detailedInfoResult?.flatMap((user: any) => 
              appData?.data?.data?.data?.todoData?.flatMap((todo: any) => ({
                "User Name": user.UserName,
                "Task List Name": todo.TodoFolderName,
                "Task Count": todo.TodoCount,
                "Error Log": user.ErrorLog
              }))
            )
            const tasksSheet = XLSX.utils.json_to_sheet(tasksData);
            XLSX.utils.book_append_sheet(workbook, tasksSheet, "Tasks");
  
            // Calendar sheet
            const gmailcalendarData = appData?.data?.data?.data?.detailedInfoResult?.flatMap((user: any) => 
              appData?.data?.data?.data?.calendarData?.flatMap((calendar: any) => ({
                "User Name": user.UserName,
                "Calendar Name": calendar.CalendarName,
                "Event count": calendar.EventCount,
                "Error Log": user.ErrorLog
              }))
            )
            const gmailcalendarSheet = XLSX.utils.json_to_sheet(gmailcalendarData);
            XLSX.utils.book_append_sheet(workbook, gmailcalendarSheet, "Calendar");
  
            XLSX.writeFile(workbook, "Gmail_Assessment_Report.xlsx");
            break;
          case 'mydrive':
            console.log("the export data for mydrive",appData.data.data.data.detailedInfoResult);
            
            const mainMyDriveData = appData?.data?.data?.data?.detailedInfoResult?.flatMap((user: any) => ({
              "User Name": user.UserName,
              "User Email": user.UserEmail,
              "Total Files": user.TotalFiles,
              "Total Folders": user.TotalFolders,
              "Drive Size": formatBytes(user.DriveSize),
              "Version Count": user.versionCount,
              "Version Size": formatBytes(Number(user.versionSize))
            }));
            const mainMyDriveSheet = XLSX.utils.json_to_sheet(mainMyDriveData);
            XLSX.utils.book_append_sheet(workbook, mainMyDriveSheet, "My Drive Overview");
  
//            // Files sheet
//             const filesData =appData?.data?.data?.data?.detailedInfoResult?.flatMap((user: any) => 
//               user.files.map((file: any) => ({
//                 "User Name": user.UserName,
//                 "File Name": file.UserEmail,
//                 "File Size": formatBytes(file.DriveSize),
//                 "File Type": file.fileType,
//                 "Version Count": file.versionCount,
//                 "Version Size": formatBytes(file.versionSize),
// //"Created Date": new Date(file.createdDate).toLocaleString(),
//                // "Modified Date": new Date(file.modifiedDate).toLocaleString()
//               }))
//             );
//             const filesSheet = XLSX.utils.json_to_sheet(filesData);
//             XLSX.utils.book_append_sheet(workbook, filesSheet, "Files");
  
//             // Folders sheet
//             const foldersData = appData?.data?.data?.data?.detailedInfoResult?.flatMap((user: any) => 
//               user.folders.map((folder: any) => ({
//                 "User Name": user.UserName,
//                 "Folder Name": folder.UserName,
//                 //"Created Date": new Date(folder.createdDate).toLocaleString(),
//                 "Files Count": folder.filesCount,
//                 "Folder Size": formatBytes(folder.DriveSize)
//               }))
//             );
//             const foldersSheet = XLSX.utils.json_to_sheet(foldersData);
//             XLSX.utils.book_append_sheet(workbook, foldersSheet, "Folders");
  
            XLSX.writeFile(workbook, "My_Drive_Assessment_Report.xlsx");
            break;
          
          case 'teamschannel':
            console.log("the team",appData?.data?.data?.data.detailedInfoResult);
            
            const mainTeamsData = appData.data?.data?.data?.detailedInfoResult.flatMap((team: any) => ({
              "Team Name": team.SourceTeamName,
              "Team Email": team.SourceTeamEmailID,
              "Users": team.Users,
              "Channels": team.Channels,
              "Messages": team.TotalMessages,
              "Human messages": team.HumanMessages,
              "Bot messages": team.BotMessages,
              "Team apps": team.Apps,
              "Files": team.Files
            }));
            const mainTeamsSheet = XLSX.utils.json_to_sheet(mainTeamsData);
            XLSX.utils.book_append_sheet(workbook, mainTeamsSheet, "Teams Overview");
  
           // Channels sheet
           console.log("the log for the channelData",appData?.data?.data?.data?.channelData);
           
            const channelsData = appData?.data?.data?.data?.detailedInfoResult?.flatMap((team: any) =>
              appData?.data?.data?.data?.channelData.flatMap((channel: any) => ({ 
                "Team Name": team.SourceTeamName,
                "Team ID": channel.SourceTeamID,
                "Channel Name": channel.SourceChannelName,
                "Channel ID": channel.SourceChannelID,
                "Channel Type": channel.SourceChannelType,
                "Members": channel.ChannelMembers,
                "Channel Tabs": channel.ChannelTabs,
                "Message Count": channel.ChannelMessages,
                "Human Message Count": channel.HumanMessages,
                "Bot Message Count": channel.BotMessages,
                "File count" :channel.ChannelFiles
                
            
             })))
            const channelsSheet = XLSX.utils.json_to_sheet(channelsData);
            XLSX.utils.book_append_sheet(workbook, channelsSheet, "Channels");
  
           // Members sheet
            const membersData = appData?.data?.data?.data?.detailedInfoResult?.flatMap((team: any) =>
              appData?.data?.data?.data?.channelData?.flatMap((channel: any) => 
                appData?.data?.data?.data?.memberData?.flatMap((member: any) =>  ({
               
                  "Team Name": team.SourceTeamName,
                  "Team ID": member.SourceTeamID,
                  "Channel Name": channel.SourceChannelName,
                  "Channel ID": channel.SourceChannelID,
                  "Username": member.Username,
                  "Email-ID": member.EmailID,
                  "Role": member.Role
              
         } ))))
            const membersSheet = XLSX.utils.json_to_sheet(membersData);
            XLSX.utils.book_append_sheet(workbook, membersSheet, "Members");
  
         //   Team apps sheet
            const teamAppsData = appData?.data?.data?.data?.detailedInfoResult?.flatMap((team: any) => 
              appData?.data?.data?.data?.appData.flatMap((appData: any) =>  ({
                "Team Name": team.SourceTeamName,
                "Team ID": team.SourceTeamID,
                "App ID": appData.SourceAppID,
                "App Name": appData.SourceAppName
              }))
            )
            const teamAppsSheet = XLSX.utils.json_to_sheet(teamAppsData);
            XLSX.utils.book_append_sheet(workbook, teamAppsSheet, "Team Apps");
  
            XLSX.writeFile(workbook, "Team_Channel_Assessment_Report.xlsx");
            break;
           
        }
  
        XLSX.writeFile(workbook, `${selectedOption}_Assessment_Report.xlsx`);
      } else {
        setAlert('Invalid application selected');
      }
    } catch (error) {
      console.error('Error in handleExport:', error);
      setAlert('An error occurred while exporting data');
    } finally {
      setLoading(false);
    }
  };
  

  // PS_331 PS_332 PS_333 PS_334 PS_335 PS_336 PS_337 PS_338 PS_339 PS_340 PS_341 PS_342 
  function formatBytes(size: number): string {
    const units: string[] = ['B', 'KB', 'MB', 'GB', 'TB'];
    let unitIndex: number = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return size.toFixed(2) + ' ' + units[unitIndex];
  }

  // PS_161, PS_162, PS_163, PS_164, PS_165, PS_167, PS_168, PS_169,
  // PS_170, PS_171 
  const handleDropdownChange = (event: any) => {
    setSelectedOption(event.target.value);
    setSearchInput('')
    setSearchQuery('')
  };

  // PS_252 PS_253 PS_254 PS_255 PS_256 PS_257 PS_258 PS_259 PS_260
  const handleSearchChange = (event: any) => {
    setSearchInput(event.target.value);
    setSelectedOption(selectedOption);
  };

  const handleSearchClick = () => {
    setPageSize(10);
    setSearchQuery(searchInput); // Update search query state when search button is clicked
    setIsSearchValuePresent(true);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      // If the pressed key is Enter, trigger the search function
      handleSearchClick();
    }
  };

  //console.log(location.state, "loaction")

  return (
    <div className="container-fluid container-height">
      <div className="row">
        <div className="col-md-12">
          <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
            {/* bread crumb starts here */}
            <ul className="breadcrumb-item mb-4 ps-0">
              <li>
                <a onClick={() => { history.push('/task') }} className="pe-0">
                  Task
                </a>
              </li>
              <li className="pe-2">{taskName}</li>
            </ul>
            {/* bread crumb ends here */}
            <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap gap-3">
              <h5 className="primary-header mb-0 d-flex align-items-center">
                <a className="cursor-pointer" onClick={() => { history.push('/task') }}>
                  <img
                    src="/images/head-back-arrow.svg"
                    alt="back-arrow"
                    width={19}
                    className="me-2"
                  />
                </a>
                {taskName}
              </h5>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
              <div className="d-flex align-items-center">
                <label className="font-14 font-semibold label-color text-nowrap me-2">
                  Select App
                </label>
                <select
                  name="groupType"
                  id="groupType"
                  className="form-select custom-form w-100 h-100 m-0 font-14 font-regular cursor-pointer"
                  value={selectedOption}
                  onChange={handleDropdownChange}
                >
                  {/* 42 to 50 */}
                  {dropdownData.map((option, index) => (
                    <option key={index} value={option.applicationName} className="cursor-pointer">
                      {option.applicationName?.toLowerCase() === "roomsandequipments"
                        ? `Rooms & Equipments (${totalRoomsAndEquipmentsCount})`
                        : option.applicationName?.toLowerCase() === "outlook"
                          ? `User Mailbox (${totalOutlookCount})`
                          : option.applicationName?.toLowerCase() === "sharedmailbox"
                            ? `Shared Mailbox (${totalSharedMailboxCount})`
                            : option.applicationName?.toLowerCase() === "sharepoint"
                              ? `SharePoint (${totalSharePointSitesCount})`
                              : option.applicationName?.toLowerCase() === "onedrive"
                                ? `OneDrive (${totalDriveCount})`
                                : option.applicationName?.toLowerCase() === "teamschat"
                                  ? `TeamsChat (${totalTeamChat})`
                                  : option.applicationName?.toLowerCase() === "teamschannel"
                                    ? `Teams Channel (${totalTeamChannel})`
                                    : option.applicationName?.toLowerCase() === "gmail"
                                    ? `GMail (${totalGmailCount})`:option.applicationName?.toLowerCase() === "mydrive"
                                    ? `My Drive (${totalGdriveCount})`: null}
                    </option>
                  ))}
                </select>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
                <div className="d-flex gap-3 flex-wrap align-items-center">
                  <div className="d-flex gap-3 align-items-center">
                    <div className="input-group input-search">
                      <input
                        type="search"
                        className="form-control border-end-0 font-14 font-regular"
                        placeholder="Search"
                        value={searchInput}
                        onChange={handleSearchChange}
                        onKeyDown={handleKeyDown}
                      />
                      <button
                        className="btn border-start-0 d-flex align-items-center cursor-pointer"
                        type="button"
                        onClick={handleSearchClick}
                      >
                        <img className="cursor-pointer" src="/images/search.svg" alt="search" />
                      </button>
                    </div>
                  </div>
                  <button
                    className="btn primary-btn text-nowrap font-semibold font-14 d-flex gap-2 justify-content-center align-items-center cursor-pointer"
                    onClick={handleExport}
                  >
                    <img
                      src="/images/excel-icon-white.svg"
                      alt="excel"
                      width={13}
                    />
                    Export
                  </button>
                </div>
              </div>
            </div>

            {selectedOption?.toLowerCase() != "" &&

              (<AppSpecificGrid
                setLoaderFalse={isPageLoad ? setLoaderFalse : null}
                taskId={taskId}
                
                applicationName={selectedOption?.toLowerCase()}
                searchQuery={isSearchValuePresent === true ? searchQuery : ""}
                setInitialLoading={setLoading} // Add this new prop
              />)
            }
            
          </div>
        </div>
      </div>
      {loading && (
        <div className="container-fluid">
          <div className="overlay">
            <div className="position-absolute top-50 start-50 translate-middle">
              <div
                className="spinner-border Loader loader-color align-center "
                role="status"
              >
                <span>
                  <span className="loader-inner-circle"></span>
                </span>
              </div>
              <p className="loading-text font-16 font-semibold color-white-v2 mt-2">
                Loading..
              </p>
            </div>
          </div>
        </div>
      )}
      {/* {success && rendersuccesstoast()}
     {/*Failure Popup starts here */}
      {alert ? <div
        className="modal fade show"
        tabIndex={-1}
        style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content popup-brd-radius">
            <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
              <span className="position-absolute">
                <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
              </span>
            </div>
            <div className="modal-body border-0 text-center">
              <h5
                className="modal-title mb-3 primary-header"
                id="Failure-popupLabel"
              >
                { }
              </h5>
              <p className="primary-data">
                {alert}
              </p>
            </div>
            <div className="modal-footer border-0 pb-4 justify-content-center">
              <button
                type="button"
                className="btn primary-btn font-14 px-4 font-semibold"
                onClick={() => {
                  if (isSessionEpired) {
                    history.push('/Login')
                  }
                  setAlert('')
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div> : null}
      {/*Failure Popup ends here */}

    </div>
  );
};

export default ViewAssessmentMaster;