import { ResponseInterface, ServiceConfiguration } from "../Common/Interface";
import { BE_URL } from '../Common/Constant';
import { client } from '../Client/Client';

// let BE_URL = "http://localhost:8080"

//PS_72, PS_73, PS_74, PS_75, PS_76
export async function submitRegistration(signupPayload: any): Promise<ResponseInterface> {
  //console.log(signupPayload, "payload in the signUp service");

  const Config: ServiceConfiguration = {
    // url: "http://localhost:8080/registration/signUp",

    url: BE_URL + '/registration/registration/signUp',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data: signupPayload
  };

  const response: any = await client(Config);
  return response;
}


export async function fetchLoginDetails(loginPayLoad: any): Promise<ResponseInterface> {
  let config = {
    method: 'POST',
    // url: "http://localhost:8080/registration/login",

    url: BE_URL + '/registration/registration/login',
    // url: 'http://localhost:8080/registration/login',
    headers: {
      'Content-Type': 'application/json'
    },
    data: loginPayLoad
  }
  const response: any = await client(config);
  //console.log(config, "uyhgfdcv");
  return response;
};

export async function resetPasskey(updatePasswordPayload: any): Promise<ResponseInterface> {
  let config = {
    method: 'POST',
    url: BE_URL + '/registration/registration/updatePasskey',
    headers: {
      'Content-Type': 'application/json'
    },
    data: updatePasswordPayload
  }
  const response: any = await client(config);
  //console.log(config, "uyhgfdcv");
  //console.log("response from Api", updatePasswordPayload);
  return response;
};

export async function accountVerification(accountVerification: any): Promise<ResponseInterface> {
  let config = {
    method: 'POST',
    url: BE_URL + '/registration/registration/accountVerification',
    headers: {
      'Content-Type': 'application/json'
    },
    data: accountVerification
  }
  const response: any = await client(config);
  //console.log(config, "uyhgfdcv");
  //console.log("response from Api", accountVerification);
  return response;
};

export async function sendOtp(resendOtp: object) {
  let config = {
    method: 'POST',
    url: BE_URL + '/registration/registration/resendOtp',
    headers: {
      'Content-Type': 'application/json'
    },
    data: resendOtp
  }
  const response = await client(config);
  //console.log(config, "uyhgfdcv");
  //console.log("response from Api", resendOtp);
  return response;
};

export async function sendResetLink(resendResetLink: object) {
  let config = {
    method: 'POST',
    url: BE_URL + '/registration/registration/sendUrl',
        // url: 'http://localhost:8080/registration/sendUrl',

    headers: {
      'Content-Type': 'application/json'
    },
    data: resendResetLink
  }
  const response = await client(config);
  //console.log(config, "uyhgfdcv");
  //console.log("response from Api", resendResetLink);
  return response;
};


export async function addClientPlanInfo(resendResetLink: object) {
  let config = {
    method: 'POST',
    url: BE_URL + '/registration/registration/addClientPlanInfo',
       //  url: 'http://localhost:8080/registration/addClientPlanInfo',

    headers: {
      'Content-Type': 'application/json'
    },

    data: resendResetLink
  }
  const response = await client(config);
  //console.log(config, "uyhgfdcv");
  //console.log("response from Api", resendResetLink);
  return response;
};
