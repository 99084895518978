import { useEffect, useState } from "react";

export function RedirectPage(): JSX.Element {

  // useEffect(() => {
    // Extract the authorization code from the query parameters
    debugger
    console.log("Logged");
    
    const queryParams = new URLSearchParams(window.location.search);
    const authCode = queryParams.get('code');
    const state = queryParams.get('state'); // Optional: if you use state parameter to differentiate between services

    if (authCode) {
      let authorizationUrl = '';
      let redirectPath = '';

      // Determine the service based on the state or the current path
      if (window.location.pathname.includes('/Connectors/Redirect')) {
        authorizationUrl = `${process.env.REACT_APP_FE_CONNECTORS_URL}/Redirect`;
        redirectPath = '/Connectors';
      }
      // Send the authorization code to the main window
      const objDatatoSend = {
        authorization: authorizationUrl,
        authorizationCode: authCode
      };
      window.opener.postMessage(objDatatoSend, `${process.env.REACT_APP_FE_CONNECTORS_URL}`);
      window.close();
    } 
    // else {
    //   // Redirect to the appropriate settings page if no auth code is found
    //   if (window.location.pathname.includes('/Settings/MSTeams/Redirect')) {
    //     window.location.pathname = "/Settings/MSTeams";
    //     window.close();
    //   } else if (window.location.pathname.includes('/Settings/Slack/Redirect')) {
    //     window.location.pathname = "/Settings/Slack";
    //     window.close();
    //   }
    // }
  // }, []);


return(

  <div>
<h1>Authentication Successful!</h1>
  <p>You have successfully authenticated and obtained the authorization code.</p>
  </div>
 
  
)
 

}