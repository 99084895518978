import { client } from "../Client/Client";
import { ServiceDetails, ResponseInterface } from "../Common/Interface"
import { BE_URL } from "../Common/Constant";
// let BE_URL = "http://localhost:8080"

// PS_80, PS_84, PS_88, PS_89, PS_90, PS_100, PS_101, PS_114, PS_118, PS_119, PS_120, PS_127, PS_128
export async function checkServiceAccount(data: any): Promise<ResponseInterface> {
    //console.log(data, "hgsdhjsgfsfg");

    let Config: any = {//PS_AD-19
        url: BE_URL + '/configuration/generalConfig/checkPsService',

        // url: "http://localhost:8081/generalConfig/checkPsService",
        method: "post",
        data: data,
    };

    let response: any = await client(Config); //PS_AD-20
    return response;
}

// PS_149, PS_153, PS_154, PS_155, PS_162, PS_172, PS_176, PS_177, PS_178, PS_185
export async function deletePsservicedata(data = {}): Promise<ResponseInterface> {
    let Config: ServiceDetails = {
        url: BE_URL + '/configuration/generalConfig/deletepsservice',
        // url: "http://localhost:8081/generalConfig/deletepsservice",
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        data: data,
    }
    let response: any = await client(Config);//PS_AD_17
    return response;//PS_AD_18

}

export async function fetchServiceAppApi(data = {}): Promise<ResponseInterface> {
    try {
        //console.log("start of fetch service layer");
        
        let Config: ServiceDetails = {
            url: BE_URL + '/configuration/generalConfig/getServiceApp',

            // url: "http://localhost:8081/generalConfig/getServiceApp",
            headers: {
                "content-type": "application/json",
            },
            method: "post",
            data: data,
        }
        let response: any = await client(Config);//PS_AD_17
        //console.log("end of fetch service layer");
        
        return response;//PS_AD_18
    }
    catch (error) {
        //console.log("catch of fetch Service Layer");
        throw (error);

    }

}

export async function fetchConnectionDataApi(data = {}): Promise<ResponseInterface> {
    try {
        //console.log("start of fetch service layer");
        
        let Config: ServiceDetails = {
            url: BE_URL + '/configuration/generalConfig/getConnectionData',

            // url: "http://localhost:8081/generalConfig/getConnectionData",
            headers: {
                "content-type": "application/json",
            },
            method: "post",
            data: data,
        }
        let response: any = await client(Config);//PS_AD_17
        //console.log("end of fetch service layer");
        
        return response;//PS_AD_18
    }
    catch (error) {
        //console.log("catch of fetch Service Layer");
        throw (error);

    }

}

export async function fetchPsDataApi(data = {}): Promise<ResponseInterface> {
    let Config: ServiceDetails = {
        url: BE_URL + '/configuration/generalConfig/getServiceAccount',

        // url: "http://localhost:8081/generalConfig/getServiceAccount",
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        data: data,
    }
    let response: any = await client(Config);//PS_AD_17
    return response;//PS_AD_18

}

export async function fetchNotifyDataApi(data = {}): Promise<ResponseInterface> {
    let Config: ServiceDetails = {
        url: BE_URL + '/configuration/generalConfig/getNotifications',

        // url: "http://localhost:8082/generalConfig/getNotifications",
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        data: data,
    }
    let response: any = await client(Config);//PS_AD_17
    return response;//PS_AD_18

}

// PS_139, PS_140, PS_141, PS_142, PS_143, PS_144, PS_145, PS_146, PS_147, PS_148, PS_149, PS_150
export async function insertServiceDataApi(data: any): Promise<ResponseInterface> {
    let Config: ServiceDetails = {
        url: BE_URL + '/configuration/generalConfig/postServiceAccount',

        // url: "http://localhost:8081/generalConfig/postServiceAccount",
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        data: data,
    }
    let response: any = await client(Config);//PS_AD_17
    return response;//PS_AD_18

}

export async function insertServiceAssDataApi(data: any): Promise<ResponseInterface> {
    let Config: ServiceDetails = {
        url: BE_URL + '/configuration/generalConfig/postServiceAssAccount',

        // url: "http://localhost:8081/generalConfig/postServiceAssAccount",
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        data: data,
    }
    let response: any = await client(Config);//PS_AD_17
    return response;//PS_AD_18

}
// PS_161, PS_162, PS_163, PS_164, PS_165, PS_166, PS_167
export async function insertServiceAppApi(data: any): Promise<ResponseInterface> {
    let Config: ServiceDetails = {
        url: BE_URL + '/configuration/generalConfig/postServiceApp',

        // url: "http://localhost:8081/generalConfig/postServiceApp",
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        data: data,
    }
    let response: any = await client(Config);//PS_AD_17
    return response;//PS_AD_18

}

export async function insertAssServiceAppApi(data: any): Promise<ResponseInterface> {
    let Config: ServiceDetails = {
        url: BE_URL + '/configuration/generalConfig/postAssServiceApp',
        // url: "http://localhost:8081/generalConfig/postAssServiceApp",

        // url: "http://localhost:8082/generalConfig/postServiceApp",
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        data: data,
    }
    let response: any = await client(Config);//PS_AD_17
    return response;//PS_AD_18

}

// PS_173, PS_174, PS_175, PS_176, PS_177, PS_178, PS_179, PS_180, PS_181
export async function insertNotificationsApi(data: any): Promise<ResponseInterface> {
    let Config: ServiceDetails = {
        url: BE_URL + '/configuration/generalConfig/postNotification',

        // url: "http://localhost:8082/generalConfig/postNotification",
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        data: data,
    }
    let response: any = await client(Config);//PS_AD_17
    return response;//PS_AD_18

}
// PS_190, PS_191, PS_192, PS_193, PS_194, PS_195, PS_196, PS_197, PS_198, PS_199, PS_200
export async function insertErrApi(data: any): Promise<ResponseInterface> {
    let Config: ServiceDetails = {
        url: BE_URL + '/configuration/generalConfig/postErrorHandling',

        // url: "http://localhost:8082/generalConfig/postErrorHandling",
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        data: data,
    }
    let response: any = await client(Config);//PS_AD_17
    return response;//PS_AD_18

}
// PS_208, PS_209, PS_210, PS_211, PS_212, PS_213, PS_214, PS_215, PS_216, PS_217, PS_218, PS_219
export async function getErrApi(data: any): Promise<ResponseInterface> {
    let Config: ServiceDetails = {
        url: BE_URL + '/configuration/generalConfig/getErrorHandling',

        // url: "http://localhost:8082/generalConfig/getErrorHandling",
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        data: data,
    }
    let response: any = await client(Config);//PS_AD_17
    return response;//PS_AD_18

}
export async function deletenotify(data = {}): Promise<ResponseInterface> {
    debugger
    let Config: ServiceDetails = {
        url: BE_URL + '/configuration/generalConfig/deleteNotification',

        // url: "http://localhost:8082/generalConfig/deletepsservice",
        // url: "http://localhost:8082" + generalConfig.deletenotification,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        data: data,
    };
    let response: any = await client(Config);//PS_AD_17
    return response;//PS_AD_18
}

export async function checkServiceApp(data: any): Promise<ResponseInterface> {
    let Config: ServiceDetails = {
        url: BE_URL + '/configuration/generalConfig/checkServiceApp',

        // url: "http://localhost:8081/generalConfig/checkServiceApp",
        // url: "http://localhost:8082" + generalConfig.deletenotification,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        data: data,
    };
    let response: any = await client(Config);//PS_AD_17
    return response;//PS_AD_18
};

export async function deleteserviceapp(data = {}): Promise<ResponseInterface> {
    let Config: ServiceDetails = {
        url: BE_URL + '/configuration/generalConfig/deleteServiceApp',

        // url: "http://localhost:8081/generalConfig/deleteServiceApp",
        // url: "http://localhost:8082" + generalConfig.deletenotification,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        data: data,
    };
    let response: any = await client(Config);//PS_AD_17
    return response;//PS_AD_18
};


export async function fetchParallelInstance(data: any): Promise<ResponseInterface> {

    let Config: ServiceDetails = {
        url: BE_URL + '/configuration/generalConfig/getParallelInstance',

        // url: "http://localhost:8082/generalConfig/getParallelInstance",
        // url: "http://localhost:8082" + generalConfig.deletenotification,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        data: data,
    };
    let response: any = await client(Config);//PS_AD_17
    return response;//PS_AD_18
};
// PS_228, PS_229, PS_230, PS_231, PS_232, PS_233, PS_234, PS_235, PS_236
export async function parallelInstanceCountApi(data: any): Promise<ResponseInterface> {

    let Config: ServiceDetails = {
        url: BE_URL + '/configuration/generalConfig/insertParallelCount',

        // url: "http://localhost:8082/generalConfig/insertParallelCount",
        // url: "http://localhost:8082" + generalConfig.deletenotification,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        data: data,
    };
    let response: any = await client(Config);//PS_AD_17
    return response;//PS_AD_18
};

export async function updateCustomdate(data: any): Promise<ResponseInterface> {

    let Config: ServiceDetails = {
        url: BE_URL + '/configuration/generalConfig/updateCustomDates',

        // url: "http://localhost:8082/generalConfig/updateCustomDate",
        // url: "http://localhost:8082" + generalConfig.deletenotification,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        data: data,
    };
    let response: any = await client(Config);//PS_AD_17
    return response;//PS_AD_18
};

// export async function insertLastVisitedPageValueApi(data: any): Promise<ResponseInterface> {

//     const response: any = await client({
//       method: 'POST',
//     //   url: BE_URL + lastVisitedEndpoint.postLastVisitedValue,
//       data: data
//     });
//     //console.log(response, "This is the reponse");
//     return response;
//   };

  export async function insertMigrationSourceUser(data: any) {//PS_AD_15
 
    let Config = {
        //PS_AD_16
               // url: "http://localhost:8082/userMapping/insertSourceUsers",

         url: BE_URL+'/configuration/userMapping/insertSourceUsers',

        // url: url + generalConfig.migrationSourceUserList,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        data: data,
    };
 
    let response = await client(Config);//PS_AD_17
    //console.log(response, "DDDDDAAAATTTTTAAAAA");
    return response;//PS_AD_18
}

// //PS_AP_ 45 PS_AP_ 46
// export async function fetchSites(data = {}) {
//     debugger
//     const response = await client({
//       method: 'POST',
//       url: BE_URL + '/configuration/generalConfig/getServiceApp',

//     //   url: powershell_URL + generalConfig.siteslist,
//       data: data
//     });
//     return response;
//   };


export async function getConfigData(data: any) {//PS_AD_15
 
    let Config = {
        //PS_AD_16
            //    url: "http://localhost:8080/generalConfig/getconfigData",

         url: BE_URL+'/configuration/generalConfig/getconfigData',

        // url: url + generalConfig.migrationSourceUserList,
        headers: {
            "content-type": "application/json",
        },
        method: "post",
        data: data,
    };
 
    let response = await client(Config);//PS_AD_17
    //console.log(response, "DDDDDAAAATTTTTAAAAA");
    return response;//PS_AD_18
}