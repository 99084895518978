import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as XLSX from 'xlsx';
import Tippy from '@tippyjs/react';
import { followCursor } from 'tippy.js';
// import Tooltip from 'react-tippy';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/material.css';
import { sessionResponse } from '../../Common/Interface';
import { decodeSessionDetails } from '../../Services/CommonServices';
import Loader from '../Common/Loader';
import { OutlookTypeGrid } from '../../Services/MigrationReportServices';
import MigrationTypeChild from './IndividualMigrationReportGrid';
import objCryptoJS from "crypto-js";
import jwt from 'jwt-decode'
import { getSession } from '../../Services/manageClientAPI';
import { commonErrorMsgs } from '../../Common/Constant';

interface MigrationOption {
  value: string;
  label: string;
}

interface FilterData {
  migrationType: string | null;
  scheduledAtFrom: string | null;
  scheduledAtTo: string | null;
  status: string | null;
  searchText: string | null;
}

const MigrationTypeMain: React.FC = () => {
  /**PS_210 to
PS_231 will be executed*/
  const history = useHistory()
  const location: any = useLocation()?.state;
  console.log(location, "location of indimigreport");
  
  const sourceMailId = location?.state?.sourceMailId;
  const targetMailId = location?.state?.targetMailId;
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const rn_secretKey = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY || '';
  const a_secretKey = process.env.REACT_APP_ADMIN_TOKEN_ENCRYPTION_KEY || '';
  const c_secretKey = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY || '';
  const [alert, setAlert] = useState<boolean>(false)
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [objAdmin, setObjAdmin] = useState({})
  const [objClient, setObjClient] = useState({})
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [taskName, setTaskName] = useState<string>(location?.state?.taskName);
  const [taskId, setTaskId] = useState<string | number>(location?.state?.taskId)
  const [userMappingId, setUserMappingId] = useState<string | number>(location?.state?.userMappingId);
  console.log(userMappingId, "mappppppp");
  
  const [sourceDisplayName, setSourceDisplayName] = useState<string>(location?.state?.sourceDisplayName);
  const [targetDisplayName, setTargetDisplayName] = useState<string>(location?.state?.targetDisplayName);
  const [applicationName, setApplicationName] = useState<string>(location?.state?.applicationName);
  const [totalCount, setTotalCount] = useState(0);
  const [searchClick, setSearchClick] = useState("");
  const [filterClick, setFilterClick] = useState<any>('');
  const [getMigrationTypeGrid, setGetMigrationTypeGrid] = useState<any[]>([]);
  const [migrationType, setMigrationType] = useState<any>([]);
  const [filter, setFilter] = useState<any>({});
  const [filterData, setFilterData] = useState<FilterData>({
    migrationType: null,
    scheduledAtFrom: null,
    scheduledAtTo: null,
    status: null,
    searchText: '',
  });
  const today = new Date().toISOString().slice(0, 10);
  const scheduledAtFromRef: any = useRef(null);
  const scheduledAtToRef: any = useRef(null);
  const migrationStartedAtFromRef: any = useRef(null);
  const migrationStartedAtToRef: any = useRef(null);
  const migrationEndedAtFromRef: any = useRef(null);
  const migrationEndedAtToRef: any = useRef(null);
  let [clientName, setclientName] = useState<string>('');
  let [companyName, setCompanyName] = useState<string>('');
  let [emailAddress, setEmailAddress] = useState<string>('');
  let [role, setRole] = useState<string>('');
  let [isAdmin, setIsAdmin] = useState<boolean>(false);
  let [adminName, setAdminName] = useState<string>('');
  let [adminEmailAddress, setAdminEmailAddress] = useState<string>('');
  let [adminrole, setAdminRole] = useState<string>('');
  let [SessionEpiredDateTime, setSessionEpiredDateTime] = useState(new Date());
  const [isSessionEpired, setisSessionEpired] = useState(false);
  // //debugger
  let InActiveTimeout: NodeJS.Timeout;

  useEffect(() => {
    sessionTokenProperties();
    resetTimer();

    window.addEventListener('keydown', resetTimer);
    window.addEventListener('click', resetTimer);

    if (scheduledAtFromRef.current) {
      scheduledAtFromRef.current.max = filter.scheduledAtTo;
    }

    if (scheduledAtToRef.current) {
      scheduledAtToRef.current.min = filter.scheduledAtFrom;
    }
    if (scheduledAtFromRef.current && scheduledAtToRef.current) {
      scheduledAtFromRef.current.max = filter.scheduledAtTo || today; // Use today's date if filter value is empty
      scheduledAtToRef.current.min = filter.scheduledAtFrom; // Use today's date if filter value is empty
    }
    if (migrationStartedAtFromRef.current) {
      migrationStartedAtFromRef.current.max = filter.migrationStartedAtTo;
    }
    if (migrationStartedAtToRef.current) {
      migrationStartedAtToRef.current.min = filter.migrationStartedAtFrom;
    }
    if (migrationStartedAtFromRef.current && migrationStartedAtToRef.current) {
      migrationStartedAtFromRef.current.max =
        filter.migrationStartedAtTo || today; // Use today's date if filter value is empty
      migrationStartedAtToRef.current.min = filter.migrationStartedAtFrom; // Use today's date if filter value is empty
    }
    if (migrationEndedAtFromRef.current) {
      migrationEndedAtFromRef.current.max = filter.migrationEndedAtTo;
    }
    if (migrationEndedAtToRef.current) {
      migrationEndedAtToRef.current.min = filter.migrationEndedAtFrom;
    }
    if (migrationEndedAtFromRef.current && migrationEndedAtToRef.current) {
      migrationEndedAtFromRef.current.max = filter.migrationEndedAtTo || today; // Use today's date if filter value is empty
      migrationEndedAtToRef.current.min = filter.migrationEndedAtFrom; // Use today's date if filter value is empty
    }
    return () => {
      window.removeEventListener('click', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };
  }, [today]);

  useEffect(() => {
    fetchData();
    //console.log(filterClick, "hjyguhgkuhkhbj");
  }, [searchClick !== '', filterClick]);


  let resetTimer = async () => {
    try {
      // debugger;
      //console.log("Reset Timer Start");

      let sessionResponse: string = await resetSessionTimer(InActiveTimeout, SessionEpiredDateTime);
      //console.log("sessionResponse value :", sessionResponse);

      // //debugger
      if (sessionResponse == 'expired') {
        debugger
        sessionStorage.removeItem("c_at");
        setisSessionEpired(true)
        setAlert(true)
        setAlertMessage('Your Session has Expired please login again to continue');
      }
      else if (sessionResponse == 'updateToken') {
            if(emailAddress == "" || emailAddress == undefined){
                await sessionTokenProperties();
            }
        debugger
        let token = await getToken()
        let payload = {
          mailId: emailAddress,
          isAdmin: false,
          token: token
        };
        let getsessionToken = await getSession(payload);
        const rn_secretKey: any = process.env.REACT_APP_REACT_NODE_ENCRYPTION_KEY
        const cs_secretKey: any = process.env.REACT_APP_CLIENT_TOKEN_ENCRYPTION_KEY;

        const c_byteValue = objCryptoJS.AES.decrypt(getsessionToken?.data?.data.sessionToken, rn_secretKey);
        const c_decryptedValue = c_byteValue.toString(objCryptoJS.enc.Utf8);
        let updatedToken = objCryptoJS.AES.encrypt(c_decryptedValue, cs_secretKey).toString();
        await sessionStorage.removeItem("c_at");
        await sessionStorage.setItem('c_at', updatedToken);
        let updatedobjClient: any = jwt(c_decryptedValue);
        SessionEpiredDateTime = updatedobjClient.CreatedAt;
        setSessionEpiredDateTime(SessionEpiredDateTime);
      }
      //console.log("Reset Timer End");
    }
    catch (error) {
      //console.log("Reset Timer Catch");
      setAlert(true)
      setAlertMessage('Something went wrong! Please try again or reach AVAMIGRATRON Support.');
      setLoading(false);
    }
  }

  const resetSessionTimer = async (InActiveTimeout: any, SessionExpirationDateTime: any) => {
    try {
      // debugger
      //console.log("Reset Session Timer Start");
      // Assuming InActiveTimeout is a number representing the ID of the current timeout

      if (typeof window !== 'undefined') {
        //console.log("Reset Session Timer Inside");
        clearTimeout(InActiveTimeout);
        const tokenExpiryCheckInterval = Number(process.env.REACT_APP_TABIDLESCENARIO);

        InActiveTimeout = setTimeout(() => {
          //console.log("Expired");
          sessionStorage.removeItem("c_at");
          //console.log("removed session");
          setisSessionEpired(true)
          setAlert(true)
          setAlertMessage('Your Session has Expired please login again to continue');
          // return 'expired'
        }, tokenExpiryCheckInterval);

        //console.log("Reset Session Timer checking");

        const currentDateTime = new Date().toISOString();
        const sessionExpiredDateTime = new Date(SessionExpirationDateTime).toISOString();
        //console.log("sessionExpiredDateTime value :", sessionExpiredDateTime);

        const diffInMilliseconds = Math.abs(new Date(sessionExpiredDateTime).getTime() - new Date(currentDateTime).getTime());
        //console.log("diffInMilliSeconds values:", diffInMilliseconds);

        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        //console.log("minutes : ", minutes);

        const sessionUpdateTimeGap = Number(process.env.REACT_APP_SESSIONUPDATETIMEGAP);
        //console.log("Reset Session Timer Differece : ", sessionUpdateTimeGap);

        if (minutes > sessionUpdateTimeGap) {

          //console.log("Reset Session Timer need to update");
          return 'updateToken'
        }

        //console.log("Reset Session Timer End");
        return 'NoActionRequired'
      }
      return 'NoActionRequired'
    }
    catch (ex) {
      //console.log("Reset Session Timer Catch");
      throw ex
    }
  };



  let sessionTokenProperties = async () => {
    try {
      const tokenResponse: any = await decodeSessionDetails();
      debugger
      if (tokenResponse.adminDetails) {
        if (tokenResponse.adminDetails) {
          setObjAdmin(tokenResponse.adminDetails)
          setAdminName(tokenResponse.adminDetails.Name);
          setAdminEmailAddress(tokenResponse.adminDetails.Email);
          setAdminRole(tokenResponse.adminDetails.Role);
        }
        else {
          history.push("/Admin/Login")
        }
      }
      else {
        if (tokenResponse.clientDetails) {
          setObjClient(tokenResponse.clientDetails)
          setclientName(tokenResponse.clientDetails.Name);
          setCompanyName(tokenResponse.clientDetails.CompanyName)
          emailAddress = tokenResponse.clientDetails.Email;
                    setEmailAddress(emailAddress);
          setRole(tokenResponse.clientDetails.Role);
          setSessionEpiredDateTime(tokenResponse.clientDetails.CreatedAt);
        }
        else {
          history.push("/Login")
        }
      }
      //#endregion
    } catch (error) {
      console.error('Failed in main method', error);
    }
  };
  const getToken = async () => {
    let clientToken: string;
    let adminToken: string;
    let token: { clientToken?: string; adminToken?: string } = {};
    debugger
    try {
      if (objClient != undefined) {
        let sessionToken: any = sessionStorage.getItem('c_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, c_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          clientToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();
          token['clientToken'] = clientToken

        }


      }
      if (objAdmin != undefined) {
        let sessionToken: any = sessionStorage.getItem('a_at')
        if (sessionToken) {
          sessionToken = objCryptoJS.AES.decrypt(sessionToken, a_secretKey)
          var dePass = sessionToken.toString(objCryptoJS.enc.Utf8);
          adminToken = objCryptoJS.AES.encrypt(dePass, rn_secretKey).toString();

          token['adminToken'] = adminToken

        }
      }
      return token
    } catch (error) {
      throw error
    }
  }
  /* /*PS_183 
  PS_202 to will be executed*/
  const fetchData = async () => {
    try {
      //console.log("this is the main fetchData");
      setLoading(true);
      let token = await getToken()
      debugger;
      let payload = {
        token: token,
        taskId: taskId,
        userMappingId: userMappingId,
        filter: filterData,
        searchValue: searchClick,
        pageSize: 10,
        sortBy: "migrationStartedAt",
        sortOrder: "desc",
        applicationName: applicationName
      };
      let result = await OutlookTypeGrid(payload);
      if (result?.data?.statusCode == 200) {
        const uniqueMigrationTypeGrid: any = Array.from(new Set(result?.data?.data.dropDown?.map((data: any) => data.migrationType)));
        setMigrationType(uniqueMigrationTypeGrid);

        setGetMigrationTypeGrid(result?.data?.data?.final)
        setTotalCount(result?.data?.data?.totlaCount)
      }
      else {
        setAlert(true)
        setAlertMessage(commonErrorMsgs.generalError)
      }


    } catch (error: any) {
      setAlert(true)
      setAlertMessage(commonErrorMsgs.generalError)
    } finally {
      setLoading(false);
    }
  };
  /**PS_129 to 
  PS_135 will be executed*/
  const exportexcel = async () => {
    try {
      setLoading(true);
      const token = await getToken()
      let payload = {
        token: token,
        taskId: taskId,
        userMappingId: userMappingId,
        applicationName: applicationName,
        filter: filterData,
        searchValue: searchClick,
        pageSize: 0,
        sortBy: "migrationStartedAt",
        sortOrder: "desc"
      };
      let result = await OutlookTypeGrid(payload);
      if (result?.data?.statusCode == 200) {
        const filteredData = result?.data?.data?.final?.map((item: any) => ({
          "Migration Type": item.migrationType,
          "Scheduled At": item.scheduledAt ? item.scheduledAt : "-",
          "Migration Started At": item.migrationStartedAt,
          "Migration Ended At": item.migrationEndedAt
            ? item.migrationEndedAt
            : "-",
          Status: item.statusText,
        }));
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Migration Summary");
        XLSX.writeFile(workbook, `${sourceDisplayName}_Migration_Report.xlsx`);
      }
      else {
        setAlert(true)
        setAlertMessage(commonErrorMsgs.generalError)
      }

    } catch (error: any) {
      setAlert(true)
      setAlertMessage(commonErrorMsgs.generalError)
    } finally {
      setLoading(false);
    }
  };
  /**PS_154
   to 
  PS_160 will be executed */
  async function handleFilter(e: any) {
    console.log(e, "handlefilterrrrrr");
    
    setFilter((Applyfilterdata: any) => ({
      ...Applyfilterdata,
      [e.target.name]: e.target.value,
    }));
  }



  // Rest of the component code...
  return (<> <div className="container-fluid container-height">
    {loading && <Loader />}
    <div className="row">
      <div className="col-md-12">
        <div className="card px-4 py-4 mt-3 mb-3 border-0 rounded-3">
          {/* bread crumb starts here */}
          <ul className="breadcrumb-item mb-4 ps-0">
            <li>
              <a
                className="pe-0 cust-hover-cross-icon cursor-pointer"
                onClick={() => {
                  {
                    history.push('/task');
                  }
                }}
              >
                Task
              </a>
            </li>
            <li>
              <a
                className="pe-0 cust-hover-cross-icon cursor-pointer"
                onClick={() => {
                  {
                    history.push('/viewmigration', {
                      state: {
                        taskId: taskId,
                        taskName: taskName,
                        applicationName: applicationName,
                      },
                    });
                  }
                }}
              >
                {taskName}
              </a>
            </li>
            <li className="pe-2">{sourceDisplayName}</li>
          </ul>
          {/* bread crumb ends here */}
          <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap gap-3 ">
            <h5 className="font-20 font-semibold mb-0">
              <a
                onClick={() => {
                  {
                    history.push('/task', {
                    });
                  }
                }}
              >
                <img
                  src="/images/head-back-arrow.svg"
                  alt="back-arrow"
                  className="me-2 cust-hover-cross-icon cursor-pointer"
                />
              </a>
              {taskName} ({getMigrationTypeGrid?.length ? getMigrationTypeGrid?.length : 0})
            </h5>
            <div className="d-flex gap-3 w-md-100 flex-wrap">
              <div className="d-flex align-items-center me-4">
                {' '}
                <p className="font-14 font-regular m-0">
                  <span className="font-semibold font-14 cust-role-color">
                    Source Name :
                  </span>{' '}
                  {sourceDisplayName}
                </p>
              </div>
              <div className="d-flex align-items-center me-4">
                {' '}
                <p className="font-14 font-regular m-0">
                  <span className="font-semibold font-14 cust-role-color">
                    Target Name :
                  </span>{' '}
                  {targetDisplayName}
                </p>
              </div>
              <div className="d-flex gap-3 flex-row-reverse flex-md-row w-md-100">
                <div className="input-group input-search">
                  <input
                    type="search"
                    className="form-control border-end-0 font-14 font-regular"
                    placeholder="search"
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setSearchClick(searchValue.trim());
                        fetchData();
                      }
                    }}
                  />

                  <button
                    className="btn border-start-0 d-flex align-items-center"
                    type="button"
                    onClick={() => {
                      setSearchClick(searchValue.trim());
                      fetchData();
                    }}
                  >
                    <img src="/images/search.svg" alt="searchValue" />
                  </button>
                </div>
                <button
                  className="btn icon-swap secondary-outline-btn"
                  id="dropdownMenuLink"
                  onClick={() => {
                    setIsFilterOpen(true);
                  }}
                >
                  <img
                    src="/images/filter-active-icon.svg"
                    alt="filter-active-icon"
                  />
                  <img src="/images/filter-icon.svg" alt="filter-icon" />
                </button>
                {/*filter*/}
                <div className="position-relative">
                  {isFilterOpen ? (
                    <div
                      className="dropdown-menu dropdown-menu-end custom-filter-width p-3 end-0 show"
                      aria-labelledby="dropdownMenuLink"
                      style={{
                        position: 'absolute',
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center mb-4 show">
                        <h5 className="font-bold font-20 mb-0 custom-header-color">
                          Advanced Filter
                        </h5>
                        <span>
                          <img
                            className="cursor-pointer"
                            src="/images/close-icon.svg"
                            alt="close"
                            onClick={() => {
                              setIsFilterOpen(false);
                            }}
                          />
                        </span>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label
                            className="form-label font-14 font-semibold label-color "
                            htmlFor="environment"
                          >
                            Migration Type
                          </label>
                          <select
                            className="form-select custom-form"
                            aria-label="Default select example"
                            id="environment"
                            name="migrationType"
                            value={filter.migrationType}
                            onChange={(e) => {
                              handleFilter(e);
                            }}
                          >
                            <option value="" disabled>Select</option>
                            {migrationType?.map((data: any) => {
                              return (
                                <>
                                  <option value={data}>{data}</option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label
                            className="form-label font-14 font-semibold label-color "
                            htmlFor="status"
                          >
                            Status
                          </label>
                          <select
                            className="form-select custom-form"
                            aria-label="Default select example"
                            id="status"
                            name="status"
                            value={filter.status}
                            onChange={(e) => {
                              handleFilter(e);
                            }}
                          >
                            {filter.status === '' && (
                              <option
                                id="Select"
                                value={filter.status}
                                disabled
                              >
                                Select
                              </option>
                            )}
                            <option id="Paused" value="Paused">
                              Paused
                            </option>
                            <option id="Scheduled" value="Scheduled">
                              Scheduled
                            </option>
                            <option id="Queued" value="Queued">
                              Queued
                            </option>
                            <option id="InProgress" value="InProgress">
                              In Progress
                            </option>
                            <option id="Completed" value="Completed">
                              Completed
                            </option>
                            <option id="Failed" value="Failed">
                              Failed
                            </option>
                            <option id="warning" value="warning">
                              Warning
                            </option>
                          </select>
                        </div>
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <label className="font-16 font-semibold custom-header-color mb-2">
                                Scheduled At
                              </label>
                              <div className="row">
                                <div className="col-md-6">
                                  <label
                                    className="form-label font-14 font-semibold label-color"
                                    htmlFor="scheduledFrom"
                                  >
                                    From
                                  </label>
                                  <input
                                    className="form-control custom-form"
                                    type="date"
                                    name="scheduledAtFrom"
                                    id="scheduledFrom"
                                    value={filter.scheduledAtFrom}
                                    onChange={(e) => {
                                      handleFilter(e);
                                    }}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label
                                    className="form-label font-14 font-semibold label-color"
                                    htmlFor="scheduledTo"
                                  >
                                    To
                                  </label>
                                  <input
                                    className="form-control custom-form"
                                    type="date"
                                    name="scheduledAtTo"
                                    id="scheduledTo"
                                    value={filter.scheduledAtTo}
                                    onChange={(e) => {
                                      handleFilter(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="font-16 font-semibold custom-header-color mb-2">
                                Migration Started At
                              </label>
                              <div className="row">
                                <div className="col-md-6">
                                  <label
                                    className="form-label font-14 font-semibold label-color"
                                    htmlFor="scheduledFrom"
                                  >
                                    From
                                  </label>
                                  <input
                                    className="form-control custom-form"
                                    type="date"
                                    name="migrationStartedAtFrom"
                                    id="scheduledFrom"
                                    value={filter.migrationStartedAtFrom}
                                    onChange={(e) => {
                                      handleFilter(e);
                                    }}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label
                                    className="form-label font-14 font-semibold label-color"
                                    htmlFor="scheduledTo"
                                  >
                                    To
                                  </label>
                                  <input
                                    className="form-control custom-form"
                                    type="date"
                                    id="scheduledTo"
                                    name="migrationStartedAtTo"
                                    value={filter.migrationStartedAtTo}
                                    onChange={(e) => {
                                      handleFilter(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="font-16 font-semibold custom-header-color mb-2">
                                Migration Ended At
                              </label>
                              <div className="row">
                                <div className="col-md-6">
                                  <label
                                    className="form-label font-14 font-semibold label-color"
                                    htmlFor="scheduledFrom"
                                  >
                                    From
                                  </label>
                                  <input
                                    className="form-control custom-form"
                                    type="date"
                                    id="scheduledFrom"
                                    name="migrationEndedAtFrom"
                                    value={filter.migrationEndedAtFrom}
                                    onChange={(e) => {
                                      handleFilter(e);
                                    }}
                                  />
                                </div>
                                <div className="col-md-6">
                                  <label
                                    className="form-label font-14 font-semibold label-color"
                                    htmlFor="scheduledTo"
                                  >
                                    To
                                  </label>
                                  <input
                                    className="form-control custom-form"
                                    type="date"
                                    id="scheduledTo"
                                    name="migrationEndedAtTo"
                                    value={filter.migrationEndedAtTo}
                                    onChange={(e) => {
                                      handleFilter(e);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center py-2">
                          <button
                            className="btn secondary-btn text-nowrap w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center py-2 me-3 px-4"
                            onClick={() => {
                              setFilter({
                                migrationType: '',
                                scheduledAtFrom: '',
                                scheduledAtTo: '',
                                migrationStartedAtFrom: '',
                                migrationStartedAtTo: '',
                                migrationEndedAtFrom: '',
                                migrationEndedAtTo: '',
                                status: '',
                              });
                              setFilterClick({
                                migrationType: '',
                                scheduledAtFrom: '',
                                scheduledAtTo: '',
                                migrationStartedAtFrom: '',
                                migrationStartedAtTo: '',
                                migrationEndedAtFrom: '',
                                migrationEndedAtTo: '',
                                status: '',
                              });
                              setIsFilterOpen(false);
                            }}
                          >
                            Clear
                          </button>
                          <button
                            onClick={() => {
                              setFilterClick(filter);
                              setIsFilterOpen(false);
                            }}
                            className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center px-4"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                {/*filter*/}
              </div>
              <button
                className="btn primary-btn text-nowrap  w-md-100 font-semibold font-14 d-flex gap-2 justify-content-center align-items-center"
                onClick={() => {
                  exportexcel();
                }}
              >
                <img src="/images/excel-icon-white.svg" alt="add-plus-icon" />
                Export
              </button>
            </div>
          </div>
          <MigrationTypeChild
            searchClick={searchClick}
            filterClick={filterClick}
            applicationName={applicationName}
            sourceMailId={sourceMailId}
            targetMailId={targetMailId}
            totalCount={totalCount}
          />
        </div>
      </div>
    </div>
    {alert ? <div
      className="modal fade show"
      tabIndex={-1}
      style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content popup-brd-radius">
          <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
            <span className="position-absolute">
              <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
            </span>
          </div>
          <div className="modal-body border-0 text-center">
            <h5
              className="modal-title mb-3 primary-header"
              id="Failure-popupLabel"
            >
              { }
            </h5>
            <p className="primary-data">
              {alert}
            </p>
          </div>
          <div className="modal-footer border-0 pb-4 justify-content-center">
            <button
              type="button"
              className="btn primary-btn font-14 px-4 font-semibold"
              onClick={() => {
                if (isSessionEpired) {
                  history.push('/Login')
                }
                setAlert(false)
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div> : null}
    {/*Failure Popup ends here */}

  </div>
    {alert ? <div
      className="modal fade show"
      tabIndex={-1}
      style={{ display: "block", backgroundColor: "rgba(0,0,0,0.6)" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content popup-brd-radius">
          <div className="modal-header border-0 justify-content-center py-2 mb-4 position-relative">
            <span className="position-absolute">
              <img src="/images/failure-popup-icon.svg" alt="failure-popup-icon" />
            </span>
          </div>
          <div className="modal-body border-0 text-center">
            <h5
              className="modal-title mb-3 primary-header"
              id="Failure-popupLabel"
            >
              {alertMessage}
            </h5>
            <p className="primary-data">
              {alert}
            </p>
          </div>
          <div className="modal-footer border-0 pb-4 justify-content-center">
            <button
              type="button"
              className="btn primary-btn font-14 px-4 font-semibold"
              onClick={() => {
                if (isSessionEpired) {
                  history.push('/Login')
                }
                setAlert(false)
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div> : null}</>)
}

export default MigrationTypeMain
